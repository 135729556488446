.notification-popup {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 450px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    /* padding: 10px; */
    z-index: 1000;
    max-height: 340px; /* Adjust height as needed */
    overflow-y: auto;
  }
  
  .request-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .request-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .request-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .owner-name {
    font-weight: bold;
  }
  
  .request-info {
    margin-bottom: 10px;
  }
  
  .request-actions {
    display: flex;
    gap: 10px;
  }
  
  .approve-button, .reject-button {
    background: #007bff;
    border: none;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .reject-button {
    background: #dc3545;
  }


  