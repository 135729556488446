.verify-icons {
  background-color: rgba(217, 217, 217, 1) !important;
  margin: 50px; /* Use percentage for margin */
  width: 6.5vw; /* Relative to viewport width */
  height: 12vh; /* Allow height to adjust */
  justify-content: space-around;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.button {
  width: 12vw;
  height: 25vh;
  border: 1px solid rgba(28, 28, 28, 0.2) !important;
  border-style: dashed !important;
  font-size: 12px !important;
}
.custom-text-color {
  color: rgba(97, 97, 97, 1);
  font-weight: normal; /* Ensure text is normal weight */
  text-transform: none;
}

#refreshCaptcha {
  border: none;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.captcha-container {
  position: absolute;
  bottom: 30px;
  right: 0;
}

.captchaCanvas {
  border: 2px solid rgba(0, 0, 0, 0.62); /* Add border to the canvas */
  border-radius: 5px;
  margin-top: 0%; /*  mt-0 */
  padding: 0%; /* Optional: adds rounded corners */
}
.verify-container {
  background-color: rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.paragraph {
  color: rgba(97, 98, 107, 1);
}
.selectedImage {
  border: 1px solid rgba(28, 28, 28, 0.2);
  width: 26vw;
  height: 25vh;
  align-items: center;
  justify-content: center;
  display: flex;
  border-style: dashed;
  overflow: hidden;
}
.verify {
  position: absolute;
  left: 60vw;
}
.btncolor {
  background-color: rgba(0, 68, 198, 1) !important;
}
.txtcolor {
  color: rgba(0, 68, 198, 1) !important;
}
.caption-text {
  position: relative;
  justify-items: center;
  align-items: center;
  /* width: 560px; */
  /* margin-left: -10px; */
}
.textbox-container {
  width: 600px;
  justify-items: center;
  align-items: center;
  margin-left: 17vw;
}

.importImg {
  width: 200px;
  height: 300px;
  object-fit: contain;
}
