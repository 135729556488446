.login-in .div {
  background-color: #ffffff !important;
  display: flex;
  flex-direction: column;
  /* Changed to column to stack content vertically */
  justify-content:space-between;
  gap:"30px";
  width: 100%;
  height: 100vh;
 
  /* overflow: hidden; */
  /* Hide any overflowing content */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}


/* Text styling */
.welcome-to-neoin {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 10px;
}

.login-in {
  background-color: #ffffff !important;
  display: flex;
  flex-direction: column;
  
  /* Changed to column to stack content vertically */
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  /* Hide any overflowing content */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}


.login-in .welcome-to-neoin {
  white-space: nowrap;
  font-family: "Roboto", Helvetica;
  font-weight: 600;
  color: #000000de;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 90px;
  
  text-align: center;
  /* margin-left: 35px; */
}

.login-in .text-wrapper-2 {
  font-family: "Roboto", Helvetica;
  font-weight: 600;
  color: #00000099;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin-top: 20px;
  
}

.p-tag {
  font-weight: 400;
  color: #00000099;
  font-size: 16px;
  text-align: center !important;
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: nowrap;
}

.login-in .overlap-group-2 {
  position: relative;
}

/* .login-in .trailing-icon,
.login-in .trailing-icon  {
  outline: none;
  border: none;
  position: absolute;
  width: 32px;
  height: 24px;
  left: 290px;
  top: 16px;
  border: none !important;
  background: none;
} */

.login-in .trailing-icon {
  position: absolute;
  width: 32px; /* Increased clickable area */
  height: 32px;
  left: calc(100% - 55px); /* Aligns icon relative to the parent */
  top: 20%; /* Vertically centers icon */
  transform: translateY(25%); /* Adjust for perfect centering */
  outline: none;
  border: none;
  background: none;
  cursor: pointer; /* Ensures pointer cursor on hover */
  z-index: 2; /* Makes sure it's on top of other elements */
}

.login-in .trailing-icon:focus {
  outline: none;
}


.login-in .text-field-outline {
  position: absolute;
  width: 320px;
  height: 46px;
}

.login-in-new .text-field-outline {
  position: absolute;
  width: 320px;
  height: 46px;
}

.login-in .input-text {
  font-family: var(--subtitle-1-font-family);
  font-weight: var(--subtitle-1-font-weight);
  color: #00000099;
  font-size: var(--subtitle-1-font-size);
  letter-spacing: var(--subtitle-1-letter-spacing);
  line-height: var(--subtitle-1-line-height);
  white-space: nowrap;
  font-style: var(--subtitle-1-font-style);
  height: 54px;
}
.login-in-new .input-text {
  font-family: var(--subtitle-1-font-family);
  font-weight: var(--subtitle-1-font-weight);
  color: #00000099;
  font-size: var(--subtitle-1-font-size);
  letter-spacing: var(--subtitle-1-letter-spacing);
  line-height: var(--subtitle-1-line-height);
  white-space: nowrap;
  font-style: var(--subtitle-1-font-style);
  height: 54px;
  
}
.login-in .input-text input {
  padding: 16.5px 40px 16.5px 14px;
}


.login-in .input-text .MuiInputBase-root {
  height: 54px;
}

.black-color {
  color: #00000099;
  padding: 0;
  position: relative;
  left: -8px;
  top: 0px;
  font-size: 14px;
  white-space: nowrap;
  display: block;
  /* display: inline-block; */
  /* font-family: Roboto;
  font-size: 14px;
  font-weight: 400; */
}
.black-color-2{
  display: inline-block;
  color: #00000099;
  padding: 0;
  position: relative;
  left: -8px;
  top: 0px;
  font-size: 14px;
  white-space: nowrap;
}
.color-blue{
  color: #0044C6;
}

.login-in .input-text-3 {
  color: #ff0000;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  margin-left: 80px;
  z-index: 1;
  margin-top: 10px !important;
}

.login-in .element-contained-a-text-wrapper {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
  border-radius: 4px;
}

.login-in .element-contained-a-text {
  display: inline-flex;
  align-items: flex-start;
  padding: 6px 6px 6px 6px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--color-primary-color);
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.custom-buttom {
  background-color: #c3c832; /* Default background color */
  color: #f0f0f0; /* Default text color */
  border: 1px solid transparent; /* Transparent border by default */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding for better click area */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.custom-buttom:hover {
  background-color: #002f9c; /* Darker blue on hover */
  border-color: #ffffff; /* White border on hover */
}

.custom-buttom:active {
  transform: scale(0.98); /* Slight scale-down effect on click */
}

.custom-buttom .text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-buttom .label {
  font-family: var(--button-font-family, Arial, sans-serif);
  font-weight: var(--button-font-weight, bold);
  font-size: var(--button-font-size, 16px);
  letter-spacing: var(--button-letter-spacing, 0.5px);
  line-height: var(--button-line-height, 1.2);
  white-space: nowrap;
}




.login-in button{
  background-color: #0044C6;
}

.login-in .overlap-wrapper {
  width: 362px;
  height: 26px;
  margin-top: 20px;

}

.login-in .overlap-3 {
  position: relative;
  width: 360px;
  height: 26px;
  margin-top: -10px;
}

.login-in .line-2 {
  position: absolute;
  width: 330px;
  height: 1px;
  top: 13px;
  left: 25px;
  object-fit: cover;
}

.dialogImage{
  width: 52px;
  height: 52px;
}

.dialogContainer1{
  padding: 20px !important;
  min-width: 40%;

}


@media (max-width: 992px) {
  .login-in .line-2 {
    width: unset;
  }

}

.login-in .rectangle-2 {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 0;
  left: 167px;
  background-color: #ffffff;
}

.login-in .text-wrapper-5 {
  position: absolute;
  top: 4px;
  left: 171px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.login-in .overlap-6 {
  position: relative;
  width: 320px;
  height: 132px;
  left: 3px;
  top: 20px;
  /* background-image: url(../css/img/login/rectangle-12.svg); */
  box-shadow: 0px 5px 5px #00000033, 0px 3px 14px #0000001f, 0px 8px 10px #00000024;
  background-size: 100% 100%;
  border-radius: 5px;
  margin-top: -20px;
}

@media (max-width: 992px) {
  .login-in .overlap-6 {
    left: unset;
  }
}

.login-in .group-2 {
  position: absolute;
  width: 110px;
  height: 110px;
  top: 10px;
  left: 10px;
}

.login-in .scan-this-with-neoin {
  position: absolute;
  top: 60px;
  left: 130px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.login-in .text-wrapper-6 {
  position: absolute;
  top: 35px;
  left: 130px;
  font-family: "Roboto", Helvetica;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
  letter-spacing: 1.25px;
  line-height: 24px;
  white-space: nowrap;
}

/* Group 1 Finishes*/

.login-in .sep-line {
  position: absolute;
  width: 28px;
  height: 500px;
  top: 116px;
  left: -10px;
  z-index: 1;
}

.login-in .overlap-2 {
  position: relative;
  width: 26px;
  height: 500px;
}

.login-in .line {
  position: absolute;
  width: 1px;
  height: 460px;
  top: 0;
  left: 13px;
  object-fit: cover;
  background: none !important;
}

.login-in .rectangle {
  width: 26px;
  height: 26px;
  top: 237px;
  position: absolute;
  left: 0;
  background-color: #ffffff;
}

.login-in .text-wrapper-4 {
  position: absolute;
  top: 241px;
  left: 4px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.login-in .login-with-facebook {
  width: 264px;
  height: 48px;
}



.login-in .login-with-google div {
  width: 110px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  /* height: 48px; */
  border-radius: 5px;

  /* background-color: rgb(255, 255, 255); */
    display: inline-flex;
    align-items: center;
    /* color: rgba(0, 0, 0, 0.54); */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px; */
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    box-shadow: none;
}

.login-with-google{
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  border: 1px solid gray;
 border-radius: 5px;
  padding: 5px;
}

/* .login-in .login-with-google div{
  width: 120px;
  height: 48px;
  
} */

.login-in .overlap-5 {
  position: relative;
  width: 262px;
  height: 48px;
  background-image: url(../img/Profile/google-rectangle-1.svg);
  background-size: 100% 100%;
}

.login-in .overlap-4 {
  position: relative;
  width: 262px;
  height: 48px;
  background-image: url(../img/Profile/facebook-rectangle.svg);
  background-size: 100% 100%;
}

.login-in .login-with-google-2 {
  position: absolute;
  top: 15px;
  left: 67px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.login-in .login-with-facebook-2 {
  position: absolute;
  top: 15px;
  left: 67px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.login-in .google-logo {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 11px;
  left: 8px;
  object-fit: cover;
}

.login-with-account {
  margin-top: 180px;
  
}

.login-in .create-neoin {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  margin: 10px;
  text-align: center;
}

.login-in .span {
  font-weight: 600;
  text-decoration: underline;
}

.login-in .text-wrapper-3 {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
}

.login-in .element-by-neoin-design {
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000061;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
  /* white-space: nowrap; */
  /* position: absolute;
  bottom: 10%; */
}

.login-in .rectangle-3 {
  width: 340px;
  height: 100vh;
  top: 0;
  transform: rotate(180deg);
  box-shadow: 0px 5px 5px #00000033, 0px 3px 14px #0000001f, 0px 8px 10px #00000024;
  position: absolute;
  left: 0;
  background-color: #ffffff;
}

@media (min-width: 993px) and (max-width: 1299px) {
    .login-in .rectangle-3 {
      width: 315px;
    }
    }

.login-in .group-4 {
  position: absolute;
  width: 312px;
  height: 90px;
  top: 32px;
  left: 16px;
}

.login-in .group-3 {
  width: 340px;
  height: 100vh;
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  right: 0;
}

@media (min-width: 993px) and (max-width: 1299px) {

  .group1,
  .group2 {
    margin-top: -40px;
  }

  /* .login-in .group-3{
      right: -12px;
    } */
}

.login-in .rectangle-3 {
  padding: 0;
  margin: 0;
  width: 340px;
  height: 100vh;
  top: 0;
  transform: rotate(180deg);
  box-shadow: 0px 5px 5px #00000033, 0px 3px 14px #0000001f, 0px 8px 10px #00000024;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

@media (min-width: 993px) and (max-width: 1299px) {

  .login-in .rectangle-3,
  .login-in .group-3 {
    width: 315px;
  }

  #login-box{
    width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 2%;
    padding-bottom: 5%;
   }
  
}

.login-in .group-4 {
  position: absolute;
  width: 312px;
  height: 90px;
  top: 32px;
  left: 16px;
}

.login-in .overlap-group-3 {
  position: relative;
  width: 316px;
  height: 98px;
  top: -20px;
  left: -4px;
  /* background-image: url(../css/img/login/rectangle-14.svg); */
  background-size: 100% 100%;
}

.login-in .img-2 {
  position: absolute;
  width: 62px;
  height: 62px;
  top: 16px;
  left: 16px;
}

.login-in .swiftsync-integratio {
  position: absolute;
  width: 217px;
  top: 47px;
  left: 84px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.login-in .text-wrapper-7 {
  position: absolute;
  top: 27px;
  left: 84px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #000000de;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}

.login-in .group-5 {
  position: absolute;
  width: 312px;
  height: 90px;
  top: 138px;
  left: 16px;
}

.login-in .overlap-8 {
  position: relative;
  width: 316px;
  height: 98px;
  top: -30px;
  left: -4px;
  /* background-image: url(../css/img/login/rectangle-14-1.svg); */
  background-size: 100% 100%;
}

.login-in .group-6 {
  position: absolute;
  width: 312px;
  height: 90px;
  top: 244px;
  left: 16px;
}

.login-in .overlap-9 {
  position: relative;
  width: 316px;
  height: 98px;
  top: -2px;
  left: -4px;
  /* background-image: url(../css/img/login/rectangle-14-2.svg); */
  background-size: 100% 100%;
}

.login-in .swiftsync-integratio-2 {
  position: absolute;
  width: 217px;
  top: 42px;
  left: 84px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #00000099;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.login-in .text-wrapper-8 {
  position: absolute;
  top: 16px;
  left: 84px;
  font-family: "Roboto", Helvetica;
  font-weight: 400;
  color: #000000de;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}

.login-in .group-7 {
  position: absolute;
  width: 312px;
  height: 90px;
  top: 246px;
  left: 16px;
}

.login-in .overlap-10 {
  position: relative;
  width: 316px;
  height: 98px;
  top: -40px;
  left: -4px;
  /* background-image: url(../css/img/login/rectangle-14-3.svg); */
  background-size: 100% 100%;
}

.login-in .card {
  position: absolute;
  width: 308px;
  height: 244px;
  top: 310px;
  left: 16px;
  background-color: var(--color-neutral-background-1-rest);
  border-radius: var(--corner-radius-medium-duplicate);
  border: 1px solid;
  border-color: var(--color-neutral-stroke-transparent-rest);
  box-shadow: var(--elevation-light-shadow-04);
}

.login-in .IMG {
  /* position: absolute; */
  width: 284px;
  height: 170px;
  /* top: 12px;
    left: 12px; */
  margin-top: 12px;
  margin-left: 12px;
  object-fit: cover;
}

.login-in .footer {
  display: flex;
  width: 175px;
  align-items: center;
  gap: 12px;
  /* position: absolute;
    top: 180px;
    left: 12px; */
  margin-left: 12px;
  padding-top: 10px;
  background-color: transparent;
}

.login-in .video-tutorials {
  position: relative;
  width: 24px;
  height: 24px;
}

.login-in .labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.login-in .title {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--web-body-1-strong-font-family);
  font-weight: var(--web-body-1-strong-font-weight);
  color: var(--color-neutral-foreground-1-rest);
  font-size: var(--web-body-1-strong-font-size);
  letter-spacing: var(--web-body-1-strong-letter-spacing);
  line-height: var(--web-body-1-strong-line-height);
  white-space: nowrap;
  font-style: var(--web-body-1-strong-font-style);
}

.login-in .subtitle {
  position: relative;
  align-self: stretch;
  height: 18px;
  font-family: var(--web-caption-2-font-family);
  font-weight: var(--web-caption-2-font-weight);
  color: var(--color-neutral-foreground-3-rest);
  font-size: var(--web-caption-2-font-size);
  letter-spacing: var(--web-caption-2-letter-spacing);
  line-height: var(--web-caption-2-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-style: var(--web-caption-2-font-style);
}


@media (min-width: 993px) and (max-width: 1299px) {
  .login-in .text-field-outline {
    width: 320px !important;
    height: 46px;
  }

  .login-in .overlap-group-3,
  .login-in .overlap-8,
  .login-in .overlap-10,
  .login-in .card {
    width: 290px;
  }

  .login-in .IMG {
    width: 260px;
  }

  .login-in .welcome-to-neoin {
    font-size: 26px;
  }

  .login-in .text-wrapper-2 {
    font-size: 20px;
  }

  .login-in .sep-line {
    left: 0px;
  }

  .login-in .overlap-6 {
    width: 320px;
  }

  .login-in .group-2 {
    left: 20px;
  }

  .login-in .text-wrapper-6,
  .login-in .scan-this-with-neoin {
    left: 140px;
  }

  .login-in .element-by-neoin-design {
    white-space: wrap;
    margin-left: 5px;
  }
}

@media (max-width: 992px) {
  .login-in .input-text-3{
    margin-left: 75px;
  }
  .login-in .welcome-to-neoin {
    font-size: 28px;
    line-height: 16px;
    margin-top: 50px;
  }

  .login-in .text-wrapper-2 {
    font-size: 24px;
  }

  .black-color {
    top: 0;
  }

  .login-in .input-text-3 {
    margin-top: -35px !important;
  }

  .login-with-account {
    margin-top: 50px;
  }

  .login-in .element-by-neoin-design {
    position: static;
    margin: 30px;
    padding-bottom: 20px;
  }

  login-in .line-2 {
    width: 345px;
  }
}

.login-in .overlap-group-wrapper {
  height: 72px;
}


 #login-box{
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2%;
  padding-bottom: 5%;
 }




/* layout */

.login-container{
  text-align: center;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
}


.logo {
  width: 50px; /* Set desired width */
  height: 50px; /* Ensure height matches width */
  object-fit: contain; /* Ensure proper scaling */
  margin-bottom: 5px;
}

.clickable-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.login-text {
  font-size: 14px;
  color: inherit;
  margin-top: 5px;
}

.cursorshow {
  cursor: pointer;
}


@media (max-width: 1024px) {
  .login-in .rectangle-3 {
    width: 280px;

   
  }

  .login-in .welcome-to-neoin {
    font-size: 24px;
    margin-top: 10px;
    
  }

  .login-in .text-wrapper-2 {
    font-size: 18px;
   
    
  }

  .login-in .overlap-6 {
    width: 280px;
    
  }

  .login-in .group-2 {
    width: 90px;
    height: 90px;
    top: 5px;
    left: 5px;
    
  }

  .login-in .text-wrapper-6,
  .login-in .scan-this-with-neoin {
    left: 120px;
    font-size: 12px;
    
  }

  .login-in .login-with-account {
    margin-top: 40px;
  }

  .login-in .element-by-neoin-design {
    font-size: 12px;
    margin: 10px;
  }

  .login-in .IMG {
    width: 220px;
    height: 130px;
    
  }

  .login-in .footer {
    width: 150px;
  }
}
