div.digi-card {
    height: 270px !important;
    width: 225px !important;
    background-color: white;
    text-align: center;
    box-shadow: none;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    box-shadow: 0px -2px 4px 0px #38373799;
    transition: border 0.3s ease, box-shadow 0.3s ease;
}

div.digi-card:hover,
div.digi-card:active {
    /* border: 2px solid #0044C6; */
    cursor: pointer;
    box-shadow: 0px 4px 18px 0px #0044C633, 0 0 0 2px #0044C6 !important;
}

.digi-card div.digi-card-upper {
    border-radius: 8px;
    height: 70%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

/*.digi-card*/
.wave-separator {
    position: absolute;
    /* bottom: -0.5rem; */
    /* bottom: -0.2rem; */
    bottom: -0.1rem;
    left: 0;
    width: 100%;
}

.digi-card div.digi-card-lower {
    padding-bottom: 10px;
}

.digi-card div.digi-card-lower h2.digi-card-Name {
    font-size: 18px;
    font-weight: 700;
    margin-block-start: 0.83em;
    margin-block-end: 5px;
}

.digi-card-label-container {
    text-align: left;
    color: #ffffff;
    padding: 8px 16px 8px 12px;
    background-color: #0044C6;
    opacity: 72% !important;
    position: relative;
    top: 8px;
    max-width: 80%;
    border-radius: 0 20px 20px 0;
    width: fit-content !important;
}
/* position: relative;
display: inline-block;
top: 8px;
border-radius: 0 20px 20px 0;
width: auto;
height: 39px;
color: #ffffff;
padding: 8px 16px 8px 12px;
text-align: left;
background-color: #0044C6B8;
z-index: 2;
max-width: 80%; */

.digi-card .company-logo-container {
    position: absolute;
    bottom: -1rem;
    right: 1rem;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: white;
}

.digi-card .company-logo-container>.prof-company-logo {
    /* margin: 14px 5px; */
    /* width: 90%; */
    width: 90%;
    height: 90%;
    object-fit: contain;
    border-radius: 50%;
}

.digi-card.card-professional div.digi-card-lower {
    text-align: left;
    padding-left: 10px;
}

/* @media (min-width: 992px) and (max-width: 1400px) {
    .neoin-wave-shape {
        top: 50% !important;
    }

    .digi-card.card-professional .company-logo-container {
        top: 5%;
    }
} */
/* Card Edit */
.card-edit-page {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    background-color: #F4F7FE;
}

.card-edit-page .card-edit-column-1 {
    width: 18%;
    transition: width 0.3s ease;
    flex: 0 0 auto;
}

.card-edit-page .card-edit-column-1.collapsed {
    width: 16%;
}

.card-edit-page .card-edit-column-2 {
    flex: 1 1 auto;
}

.card-edit-page .card-edit-column-3 {
    width: 32%;
    transition: width 0.3s ease;
    flex: 0 0 auto;
}

.card-edit-page .card-edit-column-3.collapsed {
    width: 29%
}

.card-edit-list-container ul {
    padding: 6px;
    margin-bottom: 0;
}

.card-edit-list-container li {
    list-style: none;
    font-weight: 600;
    padding: 10px 12px;
    margin: 2px 0;
}

.card-edit-list-container li:hover,
.card-edit-list-container li.active {
    color: #0044C6;
    border-radius: 8px;
    background-color: #0044C60F;
    cursor: pointer;
}

.card-info-box,
.card-side-nav {
    box-shadow: 0px 12px 24px 0px #919EAB1F, 0px 0px 2px 0px #919EAB33;
}

.card-info-box h5 {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
}

.card-edit-info-heading h5 {
    color: rgba(0, 0, 0, 0.89);
    font-weight: 400;
}

.card-edit-page .profile-and-logo {
    display: flex;
    justify-content: space-between;
}

.profile-pic-avatar {
    object-fit: cover;
    height: 100%
        /*150px*/
    ;
    width: 100%
        /*150px*/
    ;
}

.file-upload-container {
    border: 2px dashed rgba(28, 28, 28, 0.2);
    border-radius: 8px;
    padding: 20px;
    height: 135px;
    width: 100%;
    cursor: pointer;
    /* min-width: 230px;
    max-width: 230px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-edit-page .choose-category-container {
    display: inline-block;
}

.card-edit-page .choose-category-container .choose-category-item {
    font-size: 16px;
    padding: 12px;
    display: inline-flex;
    margin-right: 12px;
    margin-bottom: 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    text-transform: unset;
    text-decoration: unset;
    color: rgba(0, 0, 0, 0.6);
    ;
}

.preview-card .company-logo-container {
    position: absolute;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: white;
    right: 1rem;
    bottom: -2rem;
    box-shadow: 0px 12px 24px 0px #919EAB1F, 0px 0px 2px 0px #919EAB33;
    overflow: hidden;
}
.company-logo-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.company-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media (max-width: 1200px) {
    .preview-card .company-logo-container {
        height: 80px;
        width: 80px;
        bottom: -1rem;
    }
}

.preview-card .preview-card-names {
    margin: 2em 0;
    text-align: left;
    padding: 0 16px;
}

.preview-card .icon-outside {
    width: 42px;
    height: 42px;
}

.preview-card .icon-color3 {
    color: #fff !important;
    margin: 11px auto;
}

.view-card-container {
    text-align: right;
    padding-right: 11px;
    position: relative;
}

.preview-card-container>h5 {
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.preview-card-names h3 {
    color: #000000DE;
    font-size: 22px;
    font-weight: 700;
}

.preview-card-names h5 {
    font-size: 18px;
}

p.group-card-desc {
    display: block;
    -webkit-line-clamp: unset;
    line-clamp: unset;
    color: var(--primary-text);
    margin: 2px 0;
}

.preview-card-upper {
    background-size: cover;
    border-radius: 6px 6px 0 0;
    /* max-height: 200px; */
    position: relative;
    height: 22rem;
    /* max-height: 45vh; */
}

.preview-card-upper-groupCard{
    height: 296px;
    background-size: cover;
    border-radius: 6px 6px 0 0;
    /* max-height: 200px; */
    position: relative;
    border-bottom: 5px solid #0044c6;
}

.preview-card-upper-groupCard.Smaller-screen{
    height: 160px;
}

.preview-card .summary-container {
    font-size: 16px;
    text-align: left;
    line-height: 24px;
    padding: 0 16px;
    margin-top: 24px;
}

.preview-card .quick-link-container {
    display: flex;
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 5%;
    /* Adjust as needed */
    justify-content: space-between;
    align-items: center;
    /* Ensure items are centered vertically */
    /* width: 100%; Ensure container takes full width */
    /*padding: 0 10px;  Optional: Add some padding to the container */
}

.preview-card .quick-link-icon a {
    color: unset;
}

.social-icons-rows {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    /* Ensure 5 columns layout */
    justify-content: start;
    /* Align items to the start of the container */
    gap: 20px;
    /* Adjust gap as needed for better spacing */
}

.social-icons-rows .square-box {
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    /* Use flex to center the icon inside the box */
    align-items: center;
    /* Vertical alignment */
    justify-content: center;
    /* Horizontal alignment */
}

.social-icons-rows .square-box .social-icon {
    height: 40px;
    width: 40px;
}

.social-icons-wrapper h5 {
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.89);
    text-align: left;
}

.preview-media-display {
    height: 90% !important;
    width: 90% !important;
}

/* Card Designer Styles in card.css */

.color-picker-field.card-designer .MuiInputBase-root {
    height: 3.5rem;
    padding-right: unset;
}

.icon-color-picker.card-designer {
    border: 1px solid rgba(224, 224, 224, 1);
    background: rgba(255, 255, 255, 0.12);
    width: 5.5rem;
    height: 3.5rem;
    border-radius: 4px;
}

.icon-color-picker.card-designer .color-picker-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.color-picker-caption.card-designer {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 16px;
    margin: 0;
    padding-left: 2rem;
}

.pop-content.card-designer.MuiTypography-root {
    min-height: unset;
}
.pop-content {
    overflow: visible !important;  /* Allow content to overflow */
    text-overflow: clip !important; /* Disable ellipsis */
    white-space: nowrap !important; /* Allow text to wrap */
}

/* Card Designer - Paid Styles in card.css */
.layout-grid.MuiGrid-container .MuiGrid-item {
    display: flex;
    justify-content: center;
}

/* Card Contact Details in card.css*/
.slideOpen-items-container .slideOpen-item-bar {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
}

.contact-item-icon {
    height: 20px;
    width: 20px;
    margin-right: 12px;
}

.contact-item-icon path {
    fill: rgba(0, 0, 0, 0.54);
}

.contact-icon-shadow {
    box-shadow: 0px 4px 4px 0px #0000001F;
}

.contact-info.icon-color-picker {
    height: 56px;
    width: 56px;
    border-radius: 4px;
}

.contact-location-container .search-bar {
    width: 100%
}

.contact-location-container .professional-map-container {
    width: 100%;
    border-radius: 8px;
    border: 2px solid rgba(151, 151, 151, 0.47);
    height: 250px;
}

.contact-location-container .professional-map-container>div {
    border-radius: 6px;
}

/* Social Links Styles in card.css*/
.slideOpen-items-container .social-icon {
    height: 32px;
    width: 32px;
}

.custom-accordion-root.MuiPaper-root {
    box-shadow: none;
}

.custom-accordion-root.MuiPaper-root.Mui-expanded {
    border: 1px solid #0044C6;
}

.custom-accordion-root.MuiAccordion-root::before {
    display: none;
}

.custom-accordion-root .MuiAccordionSummary-content,
.custom-accordion-root .MuiAccordionSummary-content.Mui-expanded {
    margin: unset;
}

.custom-accordion-root .MuiAccordionSummary-content {
    justify-content: space-between;
}

.custom-accordion-root .MuiAccordionSummary-root.Mui-expanded,
.custom-accordion-root .MuiAccordionSummary-root {
    padding: 8px 12px;
}

.custom-accordion-root .MuiInputBase-root .MuiInputBase-input {
    padding: 14.5px 14px;
}

.modal-heading {
    position: relative;
}

.modal-heading h5 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.89);
    font-weight: 500;
    margin-bottom: 0;
    max-width: 90%;
}

.modal-heading span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.MuiIconButton-root.Mui-disabled.disabled-check-icon {
    color: #03c813;
}

.modal-nav-item.active {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #000000;
}

/* Attach Docs Styles */
.file-attach-icon-container {
    position: absolute;
    right: 0;
    top: -0.5rem;
    color: #0044C6;
    cursor: pointer;
}

.upload-file-checkbox.MuiFormControlLabel-root {
    margin-right: 5px;
}

.upload-file-checkbox .MuiFormControlLabel-label {
    font-size: 14px;
    font-family: "Lato", sans-serif;
}

.upload-file-checkbox .MuiCheckbox-root {
    padding-right: 5px;
}

.selected-card {
    border: 2px solid #0044C6;
}

/* GenZ Profile Details */
.genz-prof-fields {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0044C60A;
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 4px;
    font-weight: 600;
}

.genz-prof-fields.opened {
    background-color: #0044C6;
    border-color: #0044C6;
    color: white;
}

/* Styles for AllCards.css */
.item-card-display .preview-card .icon-outside {
    width: 32px;
    height: 32px;
}

.item-card-display .preview-card .summary-container {
    margin-top: unset;
    line-height: 20px;
    font-size: 12px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines you want to show */
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border-bottom: 1px solid #0000001F; */
}

.item-card-display .preview-card .summary-container.small-summary {
    height: 3.3em;
}

.item-card-display .preview-card h5.no-title {
    height: 1.2rem;
}

/* #Personal-card .item-card-display .preview-card .preview-card-names {
    margin: 12px 0;
} */

/* Styles for LayoutOptions - The card layout selection component in CardDesigner*/
.layout-upper-full-img {
    position: relative;
    height: 65%;
}

.layout-upper-circle-img {
    position: relative;
    height: 90%;
}

.label-placeholder {
    height: 14px;
    width: 25px;
    opacity: 72%;
    position: absolute;
    top: 10px;
    left: 0px;
    border-radius: 0 8px 8px 0;
    background-color: #0044C6;
}

.layout-upper-full-img .full-picture-container {
    height: 100%;
    width: 100%;
}

.layout-upper-full-img .full-picture-container img {
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.layout-option-logo {
    position: absolute;
    border-radius: 50%;
    background-color: #D1D1D1;
    width: 24px;
    height: 24px;
}

.layout-option-logo.opt-1 {
    bottom: -0.5rem;
    right: 0.5rem;
}

.layout-option-logo.opt-3 {
    bottom: -0.6rem;
    left: 0.5rem;
}

.layout-option-logo.opt-7 {
    bottom: 0.6rem;
    right: 0;
}

.layout-option-logo.opt-5 {
    top: 32px;
    left: 32px;
    z-index: 9;
}

.layout-option-badge.opt-2 {
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    z-index: 2;
}

.layout-option-name-box.opt-4 {
    width: 52px;
    height: 24px;
    position: relative;
    bottom: 10px;
    border-radius: 2px;
    background-color: #D1D1D1;
}

.circle-img-wrapper {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: relative;
}

.circle-img-container.opt-5 {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.circle-img-container.opt-5 img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.circle-img-wrapper.opt-6 img,
.circle-img-wrapper.opt-8 img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    z-index: 9;
}

.circle-img-wrapper.opt-6 .profile-decor-line.opt-6 {
    width: 100%;
    height: 4px;
    position: absolute;
    top: 50%;
    background-color: #0044C6;
}

.circle-img-wrapper.opt-8 .circle-img-container.opt-8 {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.layout-option-name-box.opt-8 {
    width: 74px;
    height: 48px;
    position: absolute;
    top: 30px;
    border-radius: 2px;
    background-color: #D1D1D1;
}


@media (max-width: 1200px) {
    .layout-option-name-box.opt-4 {
        width: 50%;
    }

    .circle-img-wrapper.opt-6 img,
    .circle-img-wrapper.opt-8 img {
        height: 40px;
        width: 40px;
    }

    .layout-option-name-box.opt-8 {
        top: 40px;
        width: 80%;
        height: 60%;
    }
}

/* Specific Styles for Layouts */
@media (max-width: 1300px) {
    .layouts-modal-box .genz-prof-fields {
        width: 97%;
    }
}

.layout-4-extra-margin {
    margin-top: 72px;
}

.preview-card-upper.full-bg-cover {
    background-size: cover;
    background-position: center;
}

.default-logo-min-size {
    min-height: 32px;
    min-width: 32px;
}

.company-logo-container.layout-1,
.company-logo-container.layout-3 {
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
}

.company-logo-container.layout-3 {
    right: unset;
    left: 1rem;
}

.preview-card-upper.layout-5 .layout-5-img-container,
.preview-card-upper.layout-6 .layout-6-img-container,
.preview-card-upper.layout-8 .layout-8-img-container {
    height: 80%;
    width: 100%;
    position: relative;
}

.layout-5-img-container .layout-images-wrapper,
.layout-6-img-container .layout-images-wrapper,
.layout-8-img-container .layout-images-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.layout-5-img-container .layout-images-wrapper .layout-profile-picture,
.layout-6-img-container .layout-images-wrapper .layout-profile-picture,
.layout-8-img-container .layout-images-wrapper .layout-profile-picture {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.layout-8-img-container .layout-images-wrapper .layout-profile-pictureNew {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.layout-6-img-container .layout-images-wrapper .layout-profile-picture,
.layout-8-img-container .layout-images-wrapper .layout-profile-picture {
    z-index: 9;
}

.layout-6-img-container .layout-images-wrapper .layout-6-decor-line {
    width: 100%;
    height: 8px;
    position: absolute;
    top: 50%;
    background-color: #0044C6;
}

.layout-6-img-container .layout-images-wrapper .layout-6-decor-line.Smaller-screen {
    height: 4px;
}

.layout-5-img-container .layout-images-wrapper .layout-logo-container {
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 9;
    transform: translate(70px, 0);
    overflow: hidden;
}

.layout-5-img-container .layout-images-wrapper .layout-logo-container.Smaller-screen {
    width: 45px;
    height: 45px;
    margin-left: -20px;
}

.layout-5-img-container .layout-logo-container img {
    width: 100%;
    height: 75%;
    object-fit: contain;
}

.company-logo-container.layout-7 img {
    height: 100%;
    width: 75%;
    /* border-radius: 50%; */
    background-color: #ffffff;
    object-fit: contain;
}

.preview-card-upper.layout-7 .company-logo-container.layout-7 {
    right: 0.5rem;
    bottom: 2rem;
}

.preview-card-upper.layout-5,
.preview-card-upper.layout-6,
.preview-card-upper.layout-8 {
    height: unset;
}

.preview-card-upper.layout-5 .layout-images-wrapper,
.preview-card-upper.layout-7 .layout-images-wrapper,
.preview-card-upper.layout-8 .layout-images-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.preview-card-upper.layout-5 .layout-profile-picture,
.preview-card-upper.layout-7 .layout-profile-picture {
    margin-top: 2rem;
}

.preview-card-upper.layout-5 .layout-profile-picture,
.preview-card-upper.layout-7 .layout-profile-picture,
.preview-card-upper.layout-8 .layout-profile-picture {
    height: 230px;
    width: 230px;
}

.preview-card-upper.layout-5New .layout-profile-picture {
    height: 180px;
    width: 180px;
}

.preview-card-upper.layout-5New .layout-profile-picture.Smaller-screen {
    height: 100px;
    width: 100px;
}

.preview-card-upper.layout-8 .layout-profile-pictureNew {
    height: 180px;
    width: 180px;
}

.preview-card-upper.layout-8 .layout-profile-pictureNew.Smaller-screen {
    height: 120px;
    width: 120px;
}

.preview-card-upper.layout-4 .layout-name-box {
    max-width: 90%;
    position: absolute;
    border-radius: 8px;
    background-color: #F5F8FD;
    bottom: -50px;
}
.preview-card-upper.layout-4 .layout-name-box.layout2page{
    bottom: -25px;
}
.preview-card-upper.layout-8 .layout-name-box {
    min-width: 75%;
    min-height: 48px;
    border-radius: 8px;
    background-color: #F5F8FD;
    transform: translate(0px, -20px);
}

@media (max-width: 1300px) {

    .preview-card-upper.layout-5 .layout-profile-picture,
    .preview-card-upper.layout-7 .layout-profile-picture,
    .preview-card-upper.layout-8 .layout-profile-picture {
        height: 210px;
        width: 210px;
    }

    .preview-card-upper.layout-8 .layout-profile-pictureNew {
        height: 150px;
        width: 150px;
    }

    .preview-card-upper.layout-5 .layout-logo {
        height: 60px;
        width: 60px;
    }
}

@media (max-width: 1100px) {

    .preview-card-upper.layout-5 .layout-profile-picture,
    .preview-card-upper.layout-7 .layout-profile-picture,
    .preview-card-upper.layout-8 .layout-profile-picture {
        height: 180px;
        width: 180px;
    }

    .preview-card-upper.layout-8 .layout-profile-pictureNew {
        height: 130px;
        width: 130px;
    }

    .preview-card-upper.layout-5 .layout-logo {
        height: 55px;
        width: 55px;
    }
}

.layout-prof {
    /* max-width: 380px; */
    margin-bottom: 24px;
    width: 100%;
    position: relative;
    box-shadow: 0px 12px 24px 0px #919EAB1F, 0px 0px 2px 0px #919EAB33;
}

.layout-prof .ca-layout-upper,
.layout-prof .bank-layout-upper {
    border-bottom: 1px solid #D0D5DD;
    display: flex;
    justify-content: space-between;
}

.layout-prof.ca-layout-1 .preview-card-names {
    text-align: right;
    margin: unset;
}

.layout-prof.ca-layout-2 .preview-card-names {
    text-align: left;
    margin: unset;
}

.layout-prof.ca-layout-1 .preview-card-names h3,
.layout-prof.ca-layout-2 .preview-card-names h3 {
    font-size: 16px;
    margin-bottom: 4px;
}

.layout-prof.ca-layout-1 .preview-card-names h5,
.layout-prof.ca-layout-2 .preview-card-names h5 {
    font-size: 14px;
    margin-bottom: 0;
}

.ca-layout-body .ca-layout-left {
    flex: 0 0 1;
    width: 30%;
}

.ca-layout-body .ca-layout-right {
    flex: 0 0 1;
    width: 70%;
}

.layout-prof .ca-contact-icon {
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.layout-prof .ca-contact-icon .ca-contact-svg {
    height: 100%;
    width: 100%;
    color: white;
    padding: 2px 4px;
    min-height: 20px;
    min-width: 20px;
}

.layout-prof .ca-layout-footer {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.layout-prof.ca-layout-2 .ca-layout-footer {
    justify-content: unset;
}

.layout-prof .ca-layout-footer .footer-shape {
    background-color: #0044c6;
    color: white;
    padding: 2px 30px;
    clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
    width: fit-content;
    height: 28px;
}

.layout-prof.ca-layout-2 .ca-layout-footer .footer-shape {
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 0% 100%);
}

.layout-prof .preview-card-names {
    margin: unset;
    padding: unset;
}

.layout-prof .preview-card-names h3 {
    font-size: 16px;
    margin-bottom: 4px;
}

.layout-prof .preview-card-names h5 {
    font-size: 12px;
    margin-bottom: 0;
}

.hr-layout-1 .hr-image-container {
    padding: 6px;
    border-radius: 50%;
    border: 1px solid black;
}

.hr-layout-1 .hr-image-container .hr-image-div {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.hr-layout-1 .hr-image-div img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.hr-layout-right .hr-info-wrapper .hr-info-property {
    font-weight: 600;
    margin-bottom: -6px;
}

.hr-layout-right .hr-info-wrapper .hr-info-value {
    font-size: 12px;
}

.hr-layout-1 .hr-info-box {
    margin: 1rem 0;
    padding: 1rem 0.5rem 1rem 1.6rem;
    position: relative;
    text-align: left;
    background-color: #E6E6E6;
}

.hr-layout-1 .hr-info-icons-bar {
    position: absolute;
    height: 100%;
    left: -16px;
    top: 0;
    border-radius: 20px;
}

.hr-layout-1 .hr-info-icons-bar .hr-info-icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 3.5px 0 13.5px;
}

.hr-info-icon .hr-info-svg {
    height: 100%;
    width: 100%;
    padding: 2px;
}

.layout-prof.hr-layout-2 {
    min-height: 220px;
}

.hr-layout-2 .hr-info-box {
    text-align: left;
    position: relative;
    background-color: #ffffff;
}

.hr-layout-2 .hr-decor-line {
    border-radius: 4px;
    height: 2px;
    width: 12%;
    margin: 4px auto;
}

.hr-layout-2 .hr-image-div {
    width: 100px;
    height: 100px;
}

.hr-layout-2 .hr-image-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.hr-layout-2 .hr-info-box .hr-info-wrapper {
    padding: 8px 0;
}

.hr-layout-2 .shape-outer-1,
.shape-outer-2 {
    position: absolute;
    width: 20px;
    height: calc(50% + 14px);
    z-index: 1;
    left: -37px;
    background-repeat: no-repeat;
}

.hr-layout-2 .shape-outer-1 {
    top: -25px;
}

.hr-layout-2 .shape-outer-2 {
    bottom: -25px;
}

.shape-inner {
    position: absolute;
    width: 42px;
    height: calc(100% + 32px);
    z-index: 9;
    left: -37px;
    top: -12.5px;
    background-repeat: no-repeat;
}

.hr-layout-2 .hr-info-icons-bar {
    padding: 4px 8px;
}

.hr-layout-2 .hr-info-icons-bar .hr-info-icon:first-child {
    margin-top: 18px;
}

.hr-layout-2 .hr-info-icons-bar .hr-info-icon:nth-child(2) {
    margin-top: 16px;
}

.hr-layout-2 .hr-info-icons-bar .hr-info-icon:last-child {
    margin-top: 14px;
}

.hr-layout-2 .hr-info-icons-bar .hr-info-icon {
    margin-left: auto;
    margin-right: auto;
    height: 22px;
    width: 22px;
}

.bank-layout-upper {
    height: 25%;
    max-height: 60px;
}

.bank-layout-upper .upper-left {
    max-width: 30%;
}

.bank-layout-upper .upper-left img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.layout-categories-container {
    max-height: 480px;
    overflow-y: auto;
    scrollbar-width: none;
}

.layout-preview-container {
    border-radius: 4px;
    max-height: 480px;
    min-width: 380px;
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 1rem;
}

@media (max-width: 1600px) {
    .layout-preview-container {
        max-width: 380px;
    }
}

@media (min-width: 1600px) {
    .layout-preview-container {
        width: 70%;
    }
}

.freelancer-layout-1 .freelancer-polygon,
.freelancer-layout-2 .freelancer-polygon {
    position: absolute;
    background-color: white;
    height: 50%;
    top: 30%
}

.freelancer-polygon.left-shape {
    left: 0;
    clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
    width: 20px;
}

.freelancer-layout-1 .freelancer-polygon.right-shape {
    right: 0;
    clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);
    width: 20px;
}

.freelancer-layout-1 .fl-layout-logo-container {
    width: 100%;
    height: 60%;
    border-right: 1px solid white;
}

.freelancer-layout-1 .fl-layout-logo-container .fl-logo-div {
    max-width: 60%;
    max-height: 60%;
}

.freelancer-layout-1 .fl-logo-div img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.freelancer-layout-1 .fl-layout-right {
    padding: 40px 1rem 1rem;
}

.freelancer-layout-2 .freelancer-polygon.right-shape {
    right: 0;
    clip-path: polygon(10% 0%, 100% 0%, 100% 10%, 100% 90%, 100% 100%, 10% 100%, 0% 90%, 0% 10%);
    width: 30%;
}

.freelancer-layout-2 .fl-layout-logo-container {
    max-width: 90%;
    max-height: 90%;
}

.freelancer-layout-2 .fl-layout-logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.freelancer-layout-2 .fl-layout-left {
    padding: 40px 0rem 1rem 2rem;
}

.it-layout-1 {
    padding: 3rem 3rem 8px;
}

.it-layout-shape {
    position: absolute
}

.it-layout-shape.shape-1 {
    top: 0;
    left: 0;
}

.it-layout-shape.shape-3 {
    bottom: 0;
    left: 0;
}

.it-layout-shape.shape-2 {
    top: 60px;
    left: 0;
}

.it-layout-shape.shape-4 {
    bottom: 72px;
    right: 0;
}

.it-layout-shape.shape-5 {
    bottom: 0;
    right: 0;
}

.itech-contact-icon {
    height: 24px;
    width: 24px;
}

.itech-contact-value,
.dr-contact-value {
    display: flex;
    align-items: center;
    text-align: left;
}

.layout-prof .itech-contact-icon .itech-contact-svg {
    height: 100%;
    width: 100%;
    color: white;
    padding: 2px;
    min-height: 24px;
    min-width: 24px;
}

.adv-layout-1 .adv-shapes-cluster {
    width: 44%;
}

.adv-layout-1 .adv-layout-polygon-content .adv-layout-logo-container {
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;
}

.adv-layout-1 .adv-layout-polygon-content .adv-layout-logo-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.adv-layout-polygon {
    position: relative;
    left: 0;
    min-height: 220px;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 100%, 0% 0%);
    z-index: 19;
}

.adv-layout-polygon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 29;
    padding: 0.5rem 1.5rem 0 0;
}

.adv-bottom-light,
.adv-top-light,
.adv-bottom-bold,
.adv-top-bold {
    position: absolute;
}

.adv-bottom-light {
    bottom: 0;
    left: 40px;
    z-index: 9;
}

.adv-bottom-bold {
    bottom: 0;
    left: 53px;
}

.adv-top-light {
    top: 0;
    left: 30px;
    z-index: 9;
}

.adv-top-bold {
    top: 0;
    left: 43px;
}

.adv-layout-1 .adv-layout-body {
    width: 56%;
}

.adv-layout-1 .adv-contact-container {
    font-size: 12px;
}

.adv-layout-2 .adv-layout-left {
    width: 35%;
}

.adv-layout-2 .adv-layout-left .adv-img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.adv-layout-2 .adv-layout-left .adv-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.adv-layout-2 .adv-layout-body {
    width: 65%;
}

.adv-layout-2 .adv-layout-body .preview-card-names h3 {
    width: fit-content;
    margin-bottom: 4px;
    padding-left: 5px;
}

.adv-layout-2 .adv-contact-container {
    font-size: 12px;
}

.adv-contact-icon {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
}

.adv-contact-icon .adv-contact-svg {
    height: 100%;
    width: 100%;
    color: white;
    padding: 2px;
}

.adv-layout-2 .adv-layout-body {
    padding: 44px 24px;
}

.dr-layout-1 .dr-layout-left {
    padding: 36px 4px 36px 24px;
}

.dr-layout-1 .dr-contact-container {
    font-size: 12px;
}

.dr-layout-1 .dr-profile-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dr-layout-2 .dr-layout-left-up {
    padding: 36px 4px 0 16px;
}

.dr-layout-2 .dr-layout-left-down {
    padding: 0 6px 0 16px;
}

.dr-contact-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: black;
}

.dr-contact-icon .dr-contact-svg {
    height: 100%;
    width: 100%;
    color: white;
    padding: 3px;
    min-height: 24px;
    min-width: 24px;
}

.dr-layout-1 .dr-layout-right {
    padding: 36px 24px 36px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dr-layout-1 .dr-profile-shapes-wrapper {
    position: absolute;
    padding: 36px 16px;
}

.parallelogram-clip {
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.rhombus-clip {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.iso-triangle-clip {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%);
}

.dr-layout-1 .dr-profile-container {
    position: relative;
    min-height: 148px;
    height: 148px;
    width: 100%;
    overflow: hidden;
    z-index: 9;
}

.dr-shape-top-left {
    position: absolute;
    background-color: rgb(5, 158, 92);
    top: 16px;
    left: 16px;
    height: 40%;
    width: 60%;
    clip-path: polygon(20% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.dr-shape-bottom-right {
    position: absolute;
    background-color: rgb(2, 101, 117);
    bottom: 16px;
    right: 16px;
    height: 40%;
    width: 50%;
}

.dr-layout-2 .dr-profile-shapes-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60%;
}

.dr-layout-2 .dr-contact-container {
    font-size: 12px;
}

.dr-layout-2 .dr-profile-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.dr-profile-shapes-wrapper .dr-profile-container.custom-polygon {
    position: relative;
    top: 0;
    right: 0;
    clip-path: polygon(100% 0, 100% 100%, 50% 100%, 25% 40%, 15% 40%, 0% 0%);
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 3;
}

.dr-profile-shapes-wrapper .custom-shape {
    position: absolute;
    clip-path: polygon(100% 100%, 75% 56%, 88% 44%, 80% 36%, 61% 55%, 88% 100%);
    background-color: #0044C6;
    bottom: 0;
    right: 55%;
    width: 100px;
    height: 60%;
}

.text-on-border {
    margin-bottom: -8px !important;
    padding: 5px 15px 0 5px;
    width: fit-content;
}

.layout-prof .border-with-text {
    border: 1.5px solid black;
    padding: 16px 16px 0;
}

@media (max-width: 1300px) {
    .adv-layout-2 .adv-layout-body {
        padding: 24px 12px;
    }

    .layout-prof .border-with-text {
        padding: 4px 4px 0;
    }

    .itech-contact-icon {
        height: 20px;
        width: 20px;
    }

    .layout-prof .itech-contact-icon .itech-contact-svg {
        min-height: 20px;
        min-width: 20px;
    }
}

@media (max-width: 1200px) {
    .layout-prof .preview-card-names h3 {
        font-size: 14px;
    }
}

.layout-prof.it-layout-1 center .preview-card-names {
    width: fit-content;
}

.layout-prof.it-layout-1 .preview-card-names .it-names-underline {
    height: 1.5px;
    width: calc(100% - 4px);
    margin: 0 auto;
}

.it-layout-1 .it-contacts-container {
    font-size: 12px;
    padding: 1rem 20px 1rem 60px;
}

.it-layout-2 {
    overflow: hidden;
    min-height: 220px;
}

.it-layout-2 .it-layout-left {
    padding: 32px 24px 0;
}

.it-layout-2 .it-contacts-container {
    padding-top: 2rem;
    font-size: 12px;
}

.it-layout-2 .it-company-info {
    position: relative;
    left: 15px;
    top: 4px;
    z-index: 5;
    font-size: 12px;
}

.it-layout-2 .it-company-info .it-company-logo {
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;
}

.it-layout-2 .it-company-info .it-company-logo img {
    width: 100%;
    ;
    height: 100%;
    object-fit: contain;
}

.it-layout-2 .itech-contact-icon {
    height: 16px;
    width: 16px;
}

.layout-prof.it-layout-2 .itech-contact-icon .itech-contact-svg {
    height: 100%;
    width: 100%;
    color: white;
    padding: 2px;
    min-height: 16px;
    min-width: 16px;
}

.layout-prof.it-layout-2 .itech-contact-value {
    z-index: 3;
}

.it-layout-2 .it-point-up-shape {
    width: 40%;
    height: 30%;
    bottom: 0;
    left: 33%;
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
    position: absolute;
    z-index: 1;
    background: linear-gradient(135deg, #00ffcc, #acd8e3);
}

.it-layout-2 .it-layout-right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.it-layout-2 .it-point-left-shape {
    width: 100%;
    height: 140%;
    position: absolute;
    right: 8%;
    top: -40px;
    clip-path: polygon(100% 0, 0% 50%, 100% 100%);
    z-index: 1;
    background: linear-gradient(135deg, #00ffcc, #acd8e3);
}

.it-layout-2 .it-point-up-shape>.inner-shape {
    height: 80%;
    width: 80%;
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
    position: absolute;
    bottom: 0;
    left: 10%;
}

.it-layout-2 .it-point-left-shape>.inner-shape {
    height: 80%;
    width: 80%;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    position: absolute;
    right: 0;
    top: 10%;
}

.cs-layout-1 .cs-layout-upper {
    height: 25%;
    max-height: 25%;
}

.cs-layout-1 .cs-layout-upper .cs-layout-logo-container {
    max-width: 30%;
}

.cs-layout-1 .cs-layout-upper .cs-layout-logo-container img {
    width: 100%;
    height: 40px;
    object-fit: cover;
}

.cs-layout-1 .cs-image-container {
    border-radius: 50%;
}

.cs-layout-1 .cs-image-div {
    border-radius: 50%;
    height: 130px;
    width: 130px;
    overflow: hidden;
}

.cs-layout-1 .cs-image-div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.layout-prof.cs-layout-2 {
    overflow: hidden;
}

.cs-layout-2 .cs-image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cs-layout-2 .cs-layout-right {
    padding-left: 12px;
}

.cs-layout-2 .cs-layout-right .preview-card-names {
    text-align: right;
}

.layout-prof.cs-layout-2 .ca-contact-icon {
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-prof.cs-layout-2 .ca-contact-icon .ca-contact-svg {
    padding: 2px;
}

.cs-layout-2 .cs-layout-left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}

.cs-layout-2 .hr-image-wrapper {
    height: 135px;
    width: 135px;
    z-index: 5;
}

.cs-layout-2 .hr-image-container {
    height: 92%;
    width: 92%;
    overflow: hidden;
}

.cs-layout-2 .cs-shape-full {
    height: 80%;
    width: 40%;
    position: absolute;
    opacity: 0.5;
}

.cs-layout-2 .cs-shape-partial {
    height: 40%;
    width: 20%;
    position: absolute;
    opacity: 0.7;
    z-index: 2;
}

.cs-layout-2 .cs-shape-small {
    height: 25%;
    width: 12.5%;
    position: absolute;
    opacity: 0.5;
}

.cs-layout-2 .cs-shape-full.top {
    left: 0%;
    top: -36%
}

.cs-layout-2 .cs-shape-full.bottom {
    left: 0%;
    bottom: -42%
}

.cs-layout-2 .cs-shape-full.top-corner {
    left: -18%;
    top: -38%
}

.cs-layout-2 .cs-shape-full.bottom-corner {
    left: -18%;
    bottom: -44%
}

.cs-layout-2 .cs-shape-partial.top {
    left: -14%;
    top: 14%;
}

.cs-layout-2 .cs-shape-partial.bottom {
    left: -14%;
    bottom: 14%;
}

.cs-layout-2 .cs-shape-small.top {
    left: -3%;
    top: 24%;
}

.cs-layout-2 .cs-shape-small.bottom {
    left: -3%;
    bottom: 24%;
}

/* UserDetails2 styles */
@media (min-width: 1024px) and (max-width: 1200px) {
    .user-details-2.company-logo-container {
        bottom: 0;
    }
}

@media (max-width: 768px) {
    .user-details-box-1 .preview-card-upper {
        position: relative;
        max-height: unset;
        height: 22rem
    }

    .user-details-box-1 .company-logo-container {
        position: absolute;
        right: 1rem;
        margin-right: unset;
        margin-left: unset;
        bottom: 1rem;
    }
}

.user-details-box-1 .wave-separator {
    position: absolute;
    bottom: -0.5rem;
    width: 100%;
}

.all-cards .company-logo-container{
    height: 80px;
    width: 80px;
    bottom: -2rem;
}

.company-logo-container.Smaller-screen {
    height: 50px;
    width: 50px;
    bottom: -1rem;
}

.quick-link-container.user-details-2 svg.icon-color3 {
    margin: unset;
    height: 24px;
    width: 24px;
}

.layout-6-img-container .layoutout6-changes {
    height: 50%;
    width: 50%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
}

.layout-6-img-container .layoutout6-changesNew {
    height: 138px;
    width: 138px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
}

.layout-6-img-container .layoutout6-changesNew.Smaller-screen {
    height: 100px;
    width: 100px;
}

.layout-profile-picturelayout6 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.preview-card-upper.layout-5 .layout-profile-picture.Smaller-screen {
    width: 120px;
    height: 120px;
}
.icon-16px svg{
    width: 16px;
    height: 16px;
}

.status {
    display: flex;
    align-items: center;
  }
  
  .image-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .name-container {
    margin-left: 10px;
  }
  
  .tooltip-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px; /* Adjust as needed */
  }
  