/* App.css */

/* Onboarding Styles */
.scrollable-images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.onboard_header {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
}

.logo {
  margin-top: 2%;
  margin-left: 2%;
}

.onboard_header_align {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin: 22px 0;
}

.onboard-image-container {
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  max-width: 100%;
  position: relative;
  margin-top: 150px;
}

.onboard-image-container-first-image {
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  max-width: 100%;
  position: relative;
}

.scrollable-image {
  max-width: 100%;
  max-height: 300px;
  /* Set maximum height for the image */
}

.primary-button.css-sghohy-MuiButtonBase-root-MuiButton-root {
  position: static;
  margin-top: 16px;
  background-color: #0044c6;
  color: white;
}

.skip-btn {
  color: rgba(0, 0, 0, 0.6);
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 8px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  background-color: white;
}

.skip-btn.MuiButton-root {
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.get-started-btn {
  color: #0044c6;
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 8px 20px;
  margin: 0 auto;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  background-color: #ffffff;
}

.primary-outlined.MuiButton-root.MuiButton-outlined {
  color: var(--primary-color);  
  border-color: rgba(var(--primary-color-rgb), 0.5);

  &:hover {
    border-color: rgba(var(--primary-color-rgb), 0.9);
  }
}

.primary-outlined.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);  
}

.skip-btn:focus,
.next-btn:focus {
  outline: none;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  /* top: 440px;  with position: absolute*/
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0, 0.12);
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: rgb(0, 0, 0, 0.32);
}

.multi-step-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.step-dot-container {
  display: flex;
}

.step-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #0044c6;
  cursor: pointer;
}

.step-dot.active {
  background-color: #007bff;
}

.stepsflow-container text {
  display: none;
}

.stepsflow-container circle {
  border-color: #0044c6;
  padding: 0;
  margin: 0;
  width: 5px;
  height: 5px;
  box-sizing: border-box;
}

.stepsflow-container .MuiSvgIcon-root.MuiStepIcon-root.Mui-active,
.color-for-onboardButton {
  color: #0044c6 !important;
  border: unset !important;
}

.stepsflow-container .MuiStepIcon-root {
  color: white;
  border: 1px solid #0044c6 !important;
  border-radius: 50%;
}

.stepsflow-container .MuiStepConnector-root {
  left: calc(-55% + 20px) !important;
  right: calc(45% + 20px) !important;
}

/* .step-label {
  display: flex;
  align-items: center; 
  position: relative; 
}

.step-line {
  position: absolute;
  top: 19%;
  left: 100%;
  transform: translateX(-50%); 
  width: 89%; 
  border-bottom: 2px solid #C7D6F3;
  z-index: -1; 
} */

.steps-container {
  width: 50%;
  /* Adjust the width as needed */
  margin-bottom: 82px;
  padding-top: 2%;
}

.custom-stepper span > svg.Mui-completed {
  color: #ffffff;
}

.custom-stepper span > svg.MuiStepIcon-root {
  border: 2px solid #0044c6;
}

@media (max-width: 1200px) {
  .steps-container {
    width: 50%;
    /* Adjust the width as needed */
    margin-bottom: 60px;
    padding-top: 4%;
  }
}

@media (max-width: 525px) {
  .steps-container {
    width: 100%;
  }
}

.onboard-slide-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 18px;
}

.onboard-slide-title-2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 18px;
  margin-top: 20px;
}

.onboard-slide-title-3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: -5px;
  margin-top: 40px;
}

.onboard-2fa-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 40px;
}

.max-24px {
  max-height: 24px;
  max-width: 24px;
}

.uploaded-image {
  width: 100%;
  /* Ensure the image covers the entire container */
  height: 100%;
  /* Ensure the image covers the entire container */
  object-fit: cover;
  /* Cover the container while maintaining aspect ratio */
}

.avatar-container {
  width: 100px;
  /* Adjust width as needed */
  height: 100px;
  /* Adjust height as needed */
  border-radius: 50%;
  /* Make the container circular */
  overflow: hidden;
  /* Hide any overflow content */
  margin: 20px auto;
  /* Center horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #0000001f;
}

.avatar-placeholder {
  width: 100px;
  /* Adjust width as needed */
  height: 100px;
  /* Adjust height as needed */
  border-radius: 50%;
  /* Make the container circular */
  overflow: hidden;
  /* Hide any overflow content */
  margin: 20px auto;
  /* Center horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  /* Default background color */
}

.avatar-placeholder:hover {
  cursor: pointer;
}

.mic-icon {
  position: absolute;
  right: 40px;
  /* Adjust the value as needed */
  bottom: -25px;
  /* Adjust the value as needed */
  transform: translateY(-50%);
  /* Center the mic icon vertically */
  z-index: 2;
  padding-left: 40px;
  /* Add padding to the left of the mic icon */
}

.upload-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Adjust the margin as needed */
}

.upload-button {
  margin-top: -5px;
  margin-bottom: 17px;
  margin-left: 17px;
  margin-right: 15px;
}

.content {
  align-self: center;
  text-align: center;
  /* Center align text */
}

.login-fields-container {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.next-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.next-button-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  /* margin-left: 493px;
  flex-direction: row; */
}

.otp-placeholder {
  display: flex;
  justify-content: center;
  margin-right: 10%;
}

.otp-input {
  width: 30px;
  height: 15px;
  text-align: center;
  font-size: 20px;
  margin: 25px 20px 0;
  border: none;
  border-bottom: 2px solid #0000002e;
  background-color: transparent;
  outline: none;
  box-sizing: unset;
}

.icon-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0044c6;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.24);
  border-radius: 30px;
  position: relative;
  margin: 32px auto 0;
  padding: 16px 24px;
}

.warning-icon {
  margin-right: 15px;
}

/* dialogStyles.css */

.dialogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 400px;
}

.dialog-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 272px;
  height: auto;
}

.dialogImage {
  width: 50%;
  height: 50%;
  margin-bottom: 16px;
}

.dialog-Image {
  width: 17%;
  height: 25%;
  margin-top: 30px;
}

.dialog-content {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #000000de;
}

.dialog-content,
.heading-content.home,
p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-top: 10px; */
}

.heading-content.home p {
  -webkit-line-clamp: 3 !important;
  line-clamp: 3 !important;
}

.square-box-32px {
  width: 32px;
  height: 32px;
}

.social-icons-container-homePage {
  height: 90%;
  padding: 0px 16px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dialogText {
  margin-top: 50%;
  text-align: center;
}

.continueButton {
  position: absolute;
  top: 30px;
  background-color: #0044c6;
  color: #fff;
}

.continue-Button {
  position: absolute;
  bottom: 25px;
  background-color: #0044c6;
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.alert-dialog-title {
  text-align: center;
}

.phone-otp-input {
  width: 30px;
  height: 15px;
  /* Adjust height to create lines */
  text-align: center;
  font-size: 20px;
  margin: 0 20px;
  border: none;
  border-bottom: 2px solid #0000002e;
  /* Add bottom border to create lines */
  background-color: transparent;
  /* Transparent background */
  outline: none;
}

.pt-25 {
  padding-top: 25px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-25 {
  padding-bottom: 25px;
}

.phone-input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  text-align: left;
  max-width: 400px;
}

.scrollable-image {
  transition: opacity 5s ease-in-out;
}

.product-content {
  transition: opacity 5s ease-in-out;
}

.paragraph-text {
  text-align: center;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 16px;
}

.text-field-outline {
  position: relative;
  z-index: 1;
  width: 400px;
  height: 50px;
  text-align: center;
}

.text-field-outline input {
  width: calc(100% - 24px);
  /* Adjust width of input, subtracting padding */
  padding-right: 35px;
  /* Add padding to the left of the input */
}

.next-btn {
  padding: 8px 20px;
  font-size: 14px;
  background-color: white;
  color: #0044c6;
  border: 2px solid #0000001f;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
}

.text-field-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center align horizontally */
  margin-bottom: 20px;
  /* Adjust the margin as needed */
}

.para-text {
  margin-top: 5%;
  margin-right: 27%;
}

.error-textfield {
  border-color: red !important;
  text-align: left;
}

.green-textfield {
  border-color: green !important;
  text-align: left;
}

.green-label {
  color: green !important;
  text-align: left;
}

div.page-title-wrapper {
  margin-bottom: 3em;
}

div.page-title-wrapper h2.page-title {
  margin-bottom: 16px;
}

div.page-title-wrapper p.page-subtitle {
  margin-top: 0;
}

img.header-image {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 1200px) {
  img.header-image {
    max-width: 25%;
  }
}

@media (max-width: 767px) {
  img.header-image {
    display: none;
  }
}

.category-selector {
  text-align: left;
}

.title-container-first-image {
  margin-top: 4em;
}

.title-content {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.paragraph-text {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #00000099;
}

.next-btn,
.skip-btn,
.get-started-btn {
  height: 40px;
  min-width: 69px;
  text-transform: uppercase;
}

/* CSS for the disabled button text color */
.next-btn.disabled {
  color: #00000061;
}

.trailing-icon {
  margin-right: 20px;
}

.info-icon {
  font-size: 16px;
  font-family: Lato;
}

.popup {
  text-align: center;
  width: 200px;
  margin: auto;
  padding: auto;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.pop-content.MuiTypography-root {
  margin: 0;
  padding: 10px;
  width: 100%;
  height: 100%;
  font: "Lato";
  font-size: 14px;
  line-height: 16.8px;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
  min-height: 110px;
}

.pop-content .pop-content-item {
  margin-bottom: 6px;
}

.product-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.content-wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scrollable-images-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  /* top: 470px;  with position: absolute*/
}

.ph-no-input button.MuiTelInput-IconButton::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: 12px;
  margin-left: 6px;
}

/* Needs the fontAwesome package */

.ph-no-input .MuiPaper-root {
  max-height: calc(50% - 96px);
}

.sign-up-options {
  position: absolute;
  top: 60px;
  text-align: center;
}

@media (max-width: 1440px) {
  .sign-up-options {
    top: 40px;
  }
}

.sign-up-options-1 {
  position: relative;
  /* top: 60px; */
  text-align: center;
}

.sign-up-divider.MuiDivider-root {
  margin-top: 15px;
}

.sign-up-divider.MuiDivider-root::after,
.sign-up-divider.MuiDivider-root::before {
  width: 100px;
}

div.sign-up-with-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}

div.sign-up-with-container > .sign-up-with {
  margin-right: 1em;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

div.sign-up-with-container > div.sign-up-with img {
  height: 26px;
  width: 26px;
}

.password-buttons {
  justify-content: space-between;
  align-items: center;
}

.random-password-generator {
  color: #0044c6;
  text-decoration: underline;
  cursor: pointer;
}

.random-password-generator:visited {
  color: purple;
}

.back-btn {
  padding: 8px 20px;
  /* Adjust the padding to reduce button size */
  font-size: 14px;
  /* Adjust the font size */
  background-color: white;
  /* Change background color to white */
  color: #00000094;
  border: 2px solid #0000001f;
  /* Set border color to blue */
  border-radius: 4px;
  /* Set border-radius to 0 for sharp edges */
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
}

.twoFa-radio .MuiRadio-radio:not(.Mui-checked) {
  border: 1px solid rgba(0, 0, 0, 0.608);
}

.twoFa-radio .MuiRadio-radio.Mui-checked {
  border: 1px solid #0044c6;
}

.twoFa-radio .MuiRadio-radio.Mui-checked .MuiRadio-icon {
  background-color: #0044c6;
}

@keyframes blink {
  0%,
  100% {
    color: red;
    /* Change to the color you want */
  }

  50% {
    color: transparent;
  }
}

.otp-blinking {
  animation: blink 1s linear infinite;
}

.Link-white-text,
.Link-white-text:hover,
.Link-white-text:active,
.Link-white-text:focus {
  color: #ffffff;
  text-decoration: none;
}

.Link-black-text,
.Link-black-text:hover,
.Link-black-text:active,
.Link-black-text:focus {
  color: #000000;
  text-decoration: none;
}

/* Onboarding Styles end here*/

body,
.sidebar,
.header {
  margin: 0;
  padding: 0;
  font-family: "Lato", Arial, sans-serif;
}

/* *{
  font-family: "Lato", Arial, sans-serif;
} */

img {
  vertical-align: unset !important;
}

.primary-color {
  color: var(--primary-color, #0044c6);
}

.app {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: #F4F7FE; */
}

/* Sidebar.css */
.sidebar {
  /* border: 1px solid floralwhite; */
  background-color: #fff;
  overflow-y: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  width: 235px;
  /* position: relative; */
  z-index: 1000;
}

.collapsed {
  width: 90px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* height: 10%; */
  background: #0d0863;
  height: 4rem;
}

.sidebar-content {
  /* overflow: hidden; */
  padding-top: 10px;
  position: relative;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  padding-left: 20px;
  text-align: justify;
  width: unset;
  height: 52px;
  display: flex;
  align-items: center;
}

.sidebar li .sidebar-link {
  text-decoration: none;
  color: #00000099;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 64.84px;
  height: 31.19px;
}

.sidebar-toggle {
  cursor: pointer;
  color: #0044c6;
}

.custom-icon {
  width: 24px;
  height: 24px;
}

.sidebar-toggle:hover {
  background-color: #ddd;
}

.sidebar ul .sidebar-link.active {
  color: var(--Primary-Color, #0044c6);
  /* Background color for active link */
}

.sidebar ul .sidebar-link.active .sidebar-link {
  color: var(--Primary-Color, #0044c6);
}

.img-active.active path {
  fill: var(--Primary-Color, #0044c6) !important;
  /* Active color */
}

.sidebar-rectangle {
  width: 2%;
  height: 90%;
  border-radius: 0px 10px 10px 0px;
  background: #0044c6;
  position: absolute;
  left: 0;
}

/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: var(--Primary-Color, #0044c6);
  /* height: 10%; */
  padding: 11px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* width: 1366px; */
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 105, 1) 0%, rgba(0, 212, 255, 1) 100%);
  width: unset;
  position: sticky;
  top: 0;
  z-index: 99;
  height: 4rem;
}

.header-left {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
}

.header-right {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 16px;
  /* Adjust margin between icons as needed */
  cursor: pointer;
}

/* Style the icons */
.icon svg {
  width: 20px;
  /* Adjust icon size as needed */
  height: 20px;
  /* Adjust icon size as needed */
  color: black;
  /* Icon color */
}

.icons_share_qr svg {
  width: 18px;
  height: 18px;
}

.header-search-field .MuiOutlinedInput-root,
.social-search-field .MuiOutlinedInput-root {
  border-radius: 50px;
}

.header-search-field .MuiOutlinedInput-input {
  padding-left: 16px;
}

.header-search-field .MuiInputBase-input,
.social-search-field .MuiOutlinedInput-root {
  color: rgba(0, 0, 0, 60%);
}

.header-search-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  background-color: #0044c60a;
}

.social-search-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #e0e0e0 !important;
  border-width: unset;
  border-color: #e0e0e0 !important;
}

.digital-identity {
  background-color: #0044c6;
}

.digital-identity-container {
  background-color: #f4f7fe;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
}

.digital-identity-container-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-images {
  width: auto;
  display: flex;
}

.rectangle {
  margin: 2%;
  /* padding-bottom: 3%; */
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.left-div {
  padding: 10px 10px 0 10px;
  border-bottom: 1px solid #dde0e6;
}

.left-div ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.left-div li {
  position: relative;
  color: var(--Primary-Color, #0044c6);
  cursor: pointer;
  margin-right: 10px;
  padding: 5px 30px 5px 5px;
}

.left-div li.active::after {
  /* Style active link */
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 3px;
  background-color: var(--Primary-Color, #0044c6);
  transition: width 0.9s ease;
  border-radius: 10%;
}

.left-div li:last-child {
  margin-right: 0;
}

.right-div {
  text-align: center;
  width: 78%;
}

.customization-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: auto;
  padding: 20px;
  /* Push customization options to the right */
  /* padding-right: 20px; */
  /* Add some padding to the right */
}

.container-digital-card {
  display: flex;
  flex-direction: row;
}

.column1-digital {
  flex: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column2-digital {
  flex: 20%;
  border-left: 1px solid #dde0e6;
  height: 100%;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 16px;
}

.color-option {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.text-with-padding {
  vertical-align: super;
  margin-left: 10px;
  padding-left: 5px;
  font-size: 16px;
}

/* Home.css */
.home-page-container {
  background-color: #f4f7fe;
  /* border: 1px solid red; */
}

.status {
  /* in breadcrumb used manywhere, dont comment */
  display: flex;
  flex-direction: row;
}

.home-row {
  width: 48px;
  min-width: 48px;
}

.screen {
  width: 376px;
  height: 615px;
  border-radius: 8px 0px 0px 0px;
  opacity: 0px;
  color: #ffffff;
  border: #919eab1f;
}

.heading {
  text-align: center;
  color: #000000de;
  margin-top: 0;
  margin-bottom: 5px;
}

.homePage-section-one {
  box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33;
  width: 45%;
  height: 615px;
  min-width: 40%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  background-color: #ffff;
  scroll-behavior: none;
  /* min-width: none; */
}

.homePage-section-two {
  width: 35%;
    height: 615px;
    min-width: 390px;
    overflow: hidden;
    border-radius: 8px;
    max-width: 446px;
    /* display: flex; */
    /* flex-wrap: wrap; */
}

.homePage-section-three{
       width: 20%;
    min-width: 266px;
    max-width: 266px;
}


@media (min-width: 769px) and (max-width: 1025px) {
  .homePage-section-one {
    width: 50%;
    height: 600px;
    background-color: #ffffff;
  }

  .homePage-section-two {
    width: 40%;
    max-width: 300px;
    height: auto; /* Allow dynamic height */
    min-width: 200px;
  }

  .homePage-section-three {
    width: 30%;
    min-width: 250px;
    max-width: 300px;
  }
  .profile-picture-homeOutline img {
 width: 100px;
    height: 100px;
    border-radius: 50%;
}
}

/* Media Query for Larger Screens (1025px to 1366px) */
@media (min-width: 1025px) and (max-width: 1367px) {
  .homePage-section-one {
    width: 45%;
    height: 615px;
    background-color: #ffffff;
    min-width: 38%;
  }

  .homepage-card-box-1 {
    width: 20%;
    padding: 10px;
  }

  .homePage-section-two {
    width: 35%;
    max-width: 461px;
    height: 615px;
    min-width: 390px;
  }

  .homePage-section-three {
    width: 20%;
    min-width: 250px;
    max-width: 220px;
  }

  .profile-picture-homeOutline img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}
}

/* Media Query for Extra-Large Screens (1367px and up) */
@media (min-width: 1367px) {
  .homePage-section-one {
    width: 50%;
    height: 615px;
    background-color: #ffffff;
  }

  .homepage-card-box-1 {
    width: 15%;
  }

  .homePage-section-two {
    width: 40%;
    max-width: 461px;
  }

  .homePage-section-three {
    width: 30%;
    max-width: 300px;
  }
}

.homepage-card-box-1{
   background: #ffffff;
  /* height: 96%; */
  border-radius: 8px;
  border: 3px solid #919eab1f;
  width: 24%;
}

.homepage-background-image {
  width: 376;
  height: 228;
}

.profile-picture-homeOutline {
  background-color: #ffffffa3;
  height: 182px;
  width: 182px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 75px;
  left: 50%;
  /* Move the element's left edge to the center of the parent */
  transform: translateX(-50%);
}



.profile-picture-homeOutline img {
  width: 162px;
  height: 162px;
  border-radius: 50%;
}

.Qr-codeDesign-home {
  background-color: #ffffffa3;
  width: 102px;
  height: 102px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.subheading {
  text-align: center;
  color: var(--secondary-text);
  margin-top: 0;
  margin-bottom: 10px;
}

.heading-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000ad;
}

.sec-box-heightCommon {
  background: #ffff;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;
  /* height: 168px; */
}

.sec-lastRow-height {
  height: 168px;
}

.sec-lastRow-height .smallBox {
  background-color: #ffff;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;
  height: 168px;
  overflow: hidden;
}

.homepage-tools-sec-middlePart {
  background: #ffff;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;
  
}
.homepage-offets-sec-middlePart {
  background: #ffff;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;

}
.home-sec-middlePart {
  background: #ffff;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;

}

.probar {
  width: 75px;
  height: 75px;
  margin: 0 auto;
}

.card-count {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.tit {
  color: #0043ce;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.tit-cont {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #525252;
  margin-top: 4px;
  margin-bottom: 0;
}

.card-title-middle1 {
  font-size: 16px;
  text-align: left;
  color: #0044c6;
  /* opacity: 60%; */
}

.card-title-middle2 {
  font-size: 16px;
  text-align: left;
 /* color: "#0044C6"; */
 /* color: '#0044c6'; */
  /* opacity: 60%; */
}

.count-value {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.count-title {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #00000099;
}

.count-cont-1 {
  font-family: "Lato", sans-serif;
   font-size: 22px;
  font-weight: 700;
  /* text-align: left; */
  color: #f93c65;
}

.count-cont {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 700;
  /* text-align: left; */
  color: #00b69b;
}

.side-box-1 {
  border-bottom: 1px solid#DEDEDE;
  border-right: 1px solid#DEDEDE;
  text-align: center;
}

.side-box-1 img {
  border-bottom: unset;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0000008a;
}

.social-icons-container {
  margin-left: 16px;
  width: 100%;
  height: 75%;
}

.icons {
  margin: 0px 16px 0px 0px;
}

.date-count {
  font-family: "Lato", sans-serif;
  font-size: 56px;
  font-weight: 500;
  color: #000000de;
  opacity: 80%;
}

.calendar-title {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #000000de;
  opacity: 87%;
  margin-top: 0px;
}

.profile-box-2 {
  width: 202px;
  height: 52px;
  background: #0044c60f;
  border-radius: 50px;
}

.circle-cont-2 {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #0044c61f;
  opacity: 100%;
  margin-left: 5px;
}

.circle-cont-2 img {
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  object-fit: cover;
}

.error-textfield {
  border-color: red !important;
}

.calendar-content {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #00000099;
  opacity: 60%;
}

.circle-cont {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
}

.circle-cont-1 {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  background: #0044c61f;
  opacity: 100%;
}

.no-cards {
  text-align: center;
  margin-top: 20%;
  color: #0044c6;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
}

a {
  text-decoration: none;
  color: #0044c6;
}

.circle-cont-1 a {
  text-decoration: none;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* This ensures the image covers the entire circle */
}

.note-icon {
  width: 20px;
  height: 20px;
  color: #0044c6;
}

.note-subicon {
  color: #0000008a !important;
  gap: 16px;
}

.note-subicon svg {
  width: 16px;
  height: 16px;
}

.dateline {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #00000099;
}

.MuiChip-root.profile-box {
  min-width: 125px !important;
  max-width: 150px !important;
  border: unset;
  height: 35px;
  border-radius: 38px;
  background-color: #0000000a;
}

.image-container {
  width: 28px;
  height: 28px;
  margin-top: 4px;
  margin-left: 3px;
  border-radius: 50%;
}

.name-container {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #000000de;
  margin-top: 4px;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-container:hover {
  overflow: visible;
  width: auto;
}

.chatbot-container {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #919eab33;
  margin-left: 374px;
  margin-top: -64px;
}

.chatimg {
  margin: 13px 0px 0px 15px;
}

.Scanner-image {
  /* background: url("./Assets/img/ScannerOutline.svg"); */
  position: relative;
  background-repeat: no-repeat;
  top: 16px;
  left: 265px;
  overflow: hidden;
}

.qrcodeimg {
  width: 100px;
  height: 100px;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rectangle-new {
  width: auto;
  height: 228px;
  background-image: url("./Assets/img/Mask-group.png");
  background-size: cover;
  border: 0px solid #919eab33;
  border-radius: 8px 8px 0px 0px;
  margin-top: 0px;
  margin-left: 0px;
  position: relative;
  /* Position the circle relative to the rectangle */
}

.circle-new {
  width: 182px;
  height: 182px;
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-new img {
  width: 162px;
  height: 162px;
  border-radius: 50%;
  object-fit: cover;
  padding: 10px;
  /* Maintain the gap between the circle and the image */
}

.profile-box-1 {
  position: absolute;
  width: 108px;
  height: 48px;
  top: 52px;
  /* Position it 128px to the left of the QR code */
  border-radius: 0 38px 38px 0;
  background: #0000004d;
  overflow: hidden;
  z-index: 1;
}

.box-qr {
  position: relative;
  overflow: hidden;
}

.profile-content {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #ffffff;
  margin-left: 20px;
  margin-top: 10px;
}

.sec-first {
  margin-top: 80px;
}

.breadcrumb-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Align items vertically */
  padding: 0px 20px;
  height: 42px;
  /* Match header padding */
  background-color: white;
  /* Match header background */
  color: var(--Primary-Color, #0044c6);
  /* Match header color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Match header box-shadow */
  position: sticky;
  top: 4rem;
  z-index: 98;
}

.sort-icons {
  margin-left: 15px;
}

.map-cont {
  width: 415px;
  height: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-left: 10px;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;
}

.calendar-head {
  /* margin-right: 5px; */
  width: 160px;
}

.count-title-1 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #00000099;
}

.profile-name-content {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* text-align: center; */
  color: #00000099;
  /* margin-left: 10px; */
  margin-top: 5px;
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile-subox {
  /* width: 78px;
  height: 18px; */
  border-radius: 20px;
  background: #0000000a;
  /* margin-left: 10px; */
  background-color: #0044c61a;
}

.profile-content-container {
  /* width: 100px; */
  height: 52px;
  overflow: hidden;
  margin-left: 5px;
}

.probile-subox-cont {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  margin-top: 0px;
  padding: 2px 4px;
}

.profile-dropdown {
  width: 30px;
  /* Increase width */
  height: 30px;
  /* Increase height */
  position: relative;
  /* Positioning for absolute positioning of the icon */
}

.dropdown-icon {
  position: relative;
  /* Position the icon relative to its container */
  bottom: -5px;
  /* Adjust the icon's position downward */
  width: 36px !important;
  /* Increase icon width */
  height: 32px !important;
  /* Increase icon height */
  color: #0000008a !important;
}

.icon-color {
  color: #0000008a !important;
  /* color: white; */
}

.sort-icons-cont {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 5px;
  color: #00000099;
  cursor: pointer;
}

/* Manimegalai css styles */
/* icons8-offer 1 */

.great-offers {
  width: 90%;
  height: 10%;
  text-align: center;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-left: 12px;
  margin-top: 5%;
  /* position: absolute;
  bottom: 10%; */
  background: linear-gradient(
    107.24deg,
    rgba(180, 226, 226, 0.12) 20.14%,
    rgba(109, 208, 207, 0.12) 60.49%,
    rgba(73, 199, 198, 0.12) 102.89%
  );
}

.mt-5px {
  margin-top: 5px;
}

.mt-35px {
  margin-top: 35px;
}

.mt-20px {
  margin-top: 20px;
}

.ml-20px {
  margin-left: 20px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mt-12px {
  margin-top: 12px;
}

.ml-16px {
  margin-left: 16px;
}

.mt-10px {
  margin-top: 10px;
}

.pl-10px {
  padding-left: 10px;
}

.small-gift-container {
  width: 46px;
  height: 46px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: auto;
  display: block;
  background: linear-gradient(
    107.24deg,
    rgba(180, 226, 226, 0.12) 20.14%,
    rgba(109, 208, 207, 0.12) 60.49%,
    rgba(73, 199, 198, 0.12) 102.89%
  );
  /* margin-top: 60px; */
}

.small-gift {
  margin: 10px;
}

.container-gift {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.container-gift img {
  margin: 5px;
  /* width: 100px; */
}

.container-gift-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100px; */
}

.gift-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  margin: 0;
  padding: 0;
}

.gift-smaller-text {
  color: #0000008a;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  margin-left: 5px;
}

.right-arrow {
  height: 16px;
  width: 16px;
  margin-top: -5px;
  color: #6e7079;
}

.digital-card {
  width: 245px;
  border-radius: 8px;
  box-shadow: rgba(145, 158, 171, 0.12);
  margin: auto;
  padding: 30px;
}

.default-card .esign-Name {
  font-size: 18px;
  /* color: var(--Primary-Color, rgba(0, 68, 198, 1)); */
  line-height: 22px;
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.default-card .Profession {
  color: rgba(0, 0, 0, 0.57);
  line-height: 17px;
  font-size: 14px;
  display: block;
  text-align: left;
  font-weight: 400;
}

.circle-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid rgba(229, 229, 229, 1);
  margin-top: 10px;
}

.content6-circle-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid rgba(229, 229, 229, 1);
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container img {
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  object-fit: cover;
}

.small-circle-container {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  border: 1px solid rgba(229, 229, 229, 1);
  margin-top: -35px;
  margin-left: 77px;
  background-color: white;
}

.companylogo {
  /* padding: 5px 0;
  width: 30px;
  height: 18px; */
  width: 95%;
  height: 95%;
  object-fit: contain;
}

.companylogo2 {
  padding: 10px 0;
  width: 30px;
  height: 40px;
}

.circle-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.company-logo {
  width: 90%;
  height: 69%;
  /* background: url('./Assets/img/icons/Company-logo.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15px;
  padding: 0;
}

.outlinecompanylogo {
  background: url("./Assets/img/icons/outline-for-companylogo.svg");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Scanner-bng-image {
  background: url("./Assets/img/icons/ScannerOutline.svg");
  position: relative;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-card {
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: center;
  /* display: block; */
  color: rgba(0, 68, 198, 1);
}

.left {
  text-align: left;
  margin-left: 16px;
}

.text-align-left {
  text-align: left;
}

.Radio-button-design {
  width: 386px;
}

.twofa-choose-method-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.justify-content-flex-end {
  display: flex;
  margin-top: 10px;
  justify-content: end;
  margin-right: 20px;
}

.container-col {
  display: flex;
  flex-direction: column;
  margin-left: 0;
}

.scan-position {
  position: relative;
  left: 5px;
}

.scanner-qrcode {
  position: relative;
  left: -70px;
  margin-top: 40px;
}

.digital-card-profile {
  width: 326px;
  height: 141px;
  border-radius: 8px;
  padding: 10px;
}

.profilelogo {
  position: relative;
  left: 119px;
  top: -100px;
}

/* .save-Contact {
  width: 100px;
  height: 14px;
  size: 12px;
  color: rgba(0, 68, 198, 1);
  border-bottom: 1px solid rgba(0, 68, 198, 1);
  font-weight: 600;
  line-height: 14px;
} */

.save-design {
  margin-top: -20px;
  margin-left: 215px;
}

.digital-card-logo {
  width: 326px;
  height: 141px;
  padding: 10px;
}

.digital-card-text {
  width: 326px;
  height: 182px;
  padding: 15px;
}

.digital-card-line {
  display: block;
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.border-bottom {
  border-bottom: 1px solid #0000001f;
}

.card-icon {
  margin-left: 0;
  margin-right: 5px;
  color: rgba(0, 68, 198, 1);
  margin-bottom: 1px;
  width: 16px;
  height: 16px;
}

.color-grey {
  color: #757575;
}

.text-content {
  color: #00000099;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
}

.save-Contact-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: right;
  display: block;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: -5px;
}

/* .save-contact-content {
  color: rgba(0, 68, 198, 1);
  border-bottom: 1px solid rgba(0, 68, 198, 1);
  width: 78px;
  height: 14px;
  text-align: right;
} */

/* .digital-card-qrcode {
  width: 326px;
  height: 222px;
} */

.card {
  display: flex;
}

.column {
  padding: 10px -20px 10px 10px;
}

.column-1 {
  flex: 0 0 30%;
  justify-content: center;
}

.column-2 {
  flex: 0 0 70%;
}

.unset-text {
  text-align: unset;
}

button {
  appearance: none;
  border: none;
}

.Add-to-Email {
  /* width: 136px; */
  height: 40px;
  /* padding: 8px; */
  border-radius: 4px;
  background: var(--Primary-Color, #0044c6);
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.image-logo {
  position: relative;
}

.digital-card-qrcode {
  width: 390px;
  height: 200px;
}

.esign-card {
  display: flex;
  flex-direction: row;
}

.save-Contact {
  width: 100px;
  height: 14px;
  size: 12px;
  color: rgba(0, 68, 198, 1);
  /* border-bottom: 1px solid rgba(0, 68, 198, 1); */
  font-weight: 600;
  line-height: 14px;
  text-decoration: underline;
}

.save-contact-content {
  width: 100px;
  height: 14px;
  size: 12px;
  color: rgba(0, 68, 198, 1);
  /* border-bottom: 1px solid rgba(0, 68, 198, 1); */
  font-weight: 600;
  line-height: 14px;
  text-decoration: underline;
}

/* for lightbox */
/* .lightbox {
  display: none;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999;
  margin: 20px;
  padding: 20px;
  width: 1077px;
  min-height: 613px;
  border: 1px solid #0000002E;
  border-radius: 5px;
}

.lightbox.open {
  display: block;
}

.lightbox__close {
  width: auto;
  background: none;
  border: none !important;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  font-size: 40px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.lightbox__close:hover {
  color: rgba(0, 0, 0, 0.8);
  border: none !important;
  outline: none !important;
}

.sub-tit-email {
  color: #00000099;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.menu-content {
  display: block;
  padding-left: 20px;
}

.menu-item.active {
  border-bottom: 3px solid #0044C6;
}

.menu {
  display: flex;
  border-bottom: 1px solid #DDE0E6;
  width: 100%;
}

.menu .menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  color: #0044C6;
  
}

.menu .menu-item img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.rs-steps-item-title {
  margin-left: -40px;
}

.rs-steps-item-icon-wrapper {
  display: none !important;
}

.description-content {
  color: #000000DE;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 0 !important;
}

.warning-apple {
  background: #F4F7FE;
  margin-left: 40px;
  padding: 5px;
  border-radius: 5px;
} */

/* 22-04-24 */
.column1-digital-paid {
  flex: 45%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.column2-digital-paid {
  flex: 55%;
  border-left: 1px solid #00000014;
}

.Container-new-paid {
  display: flex;
}

.column1-small-digital-paid {
  flex: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column2-small-digital-paid {
  flex: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-config {
  border-radius: 8px;
  border: 1px solid #dde0e6;
  width: 358px;
  height: 170px;
}

.container-paid {
  display: flex;
}

@media (max-width: 1240px) {
  .container-paid {
    flex-direction: column;
  }

  .container-config {
    width: unset;
    height: unset;
  }
}

.container-config .container-paid .row-paid {
  font-size: 16px;
  flex: 50% !important;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.container-config .container-paid .row-paid div {
  margin-top: 23px;
  margin-left: 15px;
}

.heading-digital-design {
  color: #000000de;
  font-family: Lato;
  font-size: 16px;
  line-height: 19.2px;
  /* text-align: left;   */
  display: flex;
  border-bottom: 1px solid #00000014;
  padding: 20px;
}

.text-align-right {
  text-align: right;
}

.heading-one {
  flex: 30%;
  text-align: center;
}

.heading-two {
  flex: 70%;
  text-align: center;
}

.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch-container span {
  margin-right: 5px;
  /* Adjust as needed */
}

.card-header-paid {
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  display: block;
  padding: 20px;
}

.right-div-paid {
  text-align: center;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.default-card-outline {
  box-shadow: 0px 0px 2px 0px #919eab33;
  min-width: 350px;
  min-height: 160px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.digital-card-start {
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  box-shadow: 0px 12px 24px 0px #919eab1f;
  border-radius: 8px;
  /* padding: 10px; */
  /* background-color: red; */
}

.border-blue {
  border: 2px solid #0044c6;
}

.container-paid-card {
  display: flex;
  flex-direction: column;
}

.row-paid-card {
  display: flex;
}

.col-paid-card {
  flex: 2;
  width: 100%;
  padding-left: 10px;
  margin: 20px;
}

/* .esign-card { */
/* border: 1px solid #ccc;
  padding: 5px;
  background-color: #f9f9f9; */
/* margin-bottom: 10px;
  display: flex;
  flex-direction: row;
} */

.Neoin-card-paid-border {
  width: 121px;
  height: 135px;
  border: 1px solid #dde0e6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Neoin-card-paid {
  z-index: 1;
  color: #0044c6;
  font-size: 12px;
}

.Name-paid-card {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000000de;
}

.helper-text-style {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 0;
}

.button-align {
  top: 140px;
  /* margin: 89px 0px 0px 0px; */
  position: relative;
  left: 252px;
}

.Add-contact-paid {
  color: #0044c6;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #0044c6;
  width: 67px;
  height: 14px;
  position: relative;
  right: -260px;
  margin-bottom: 10px;
  margin-top: -10px;
}

.Add-contact-paid-1 {
  color: #0044c6;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #0044c6;
  width: 67px;
  height: 14px;
  position: relative;
  left: 222px;
  margin-bottom: 10px;
  margin-top: -10px;
}

.qrscan-outline {
  min-width: 314px;
  min-height: 159px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* for lightbox */
.lightbox {
  display: none;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9;
  margin: 20px;
  padding: 20px;
  width: 90%;
  min-height: 613px;
  border: 1px solid #0000002e;
  border-radius: 5px;
}

.lightbox.open {
  display: block;
}

.lightbox__close {
  width: auto;
  background: none;
  border: none !important;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  font-size: 40px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.lightbox__close:hover {
  color: rgba(0, 0, 0, 0.8);
  border: none !important;
  outline: none !important;
}

.sub-tit-email {
  color: #00000099;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.menu-content {
  display: block;
  padding-left: 20px;
}

.menu-item.active {
  border-bottom: 3px solid #0044c6;
  font-weight: bold;
}

.menu {
  display: flex;
  border-bottom: 1px solid #dde0e6;
  width: 100%;
}

.menu .menu-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 20px;
  cursor: pointer;
  color: #0044c6;
}

.menu .menu-item img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.stepper {
  display: flex;
  flex-direction: column;
}

.stepper .step {
  display: flex;
  align-items: left;
  margin-bottom: 20px;
}

.stepper .step-title {
  margin-left: 20px;
  font-size: 16px;
  color: #000000de;
}

.stepper .step:last-child {
  margin-bottom: 0;
}

.warning-apple {
  background: #f4f7fe;
  margin-left: 40px;
  padding: 5px;
  border-radius: 5px;
  margin-top: 45px;
}

.mail-sign-stepper .MuiStepLabel-iconContainer {
  display: none !important;
  text-align: left !important;
}

.MuiStepLabel-label,
.MuiStepLabel-labelContainer {
  /* display: inline !important; */
  text-align: left !important;
}

.description-content-1 {
  /* position: absolute; */
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 30.2px; */
  text-align: left;
  margin-left: 48px;
}

.MuiStepConnector-line {
  min-height: 42px !important;
}

.description-cont {
  margin: 0px 82px 45px 100px;
  bottom: 156px;
}

.right {
  text-align: right;
  margin-right: 200px;
}

.Scanner-image {
  width: 100px;
  position: relative;
  margin-left: 20px;
  height: 100px;
}

.cir-container {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 0.5px solid #e5e5e5;
  background: #ffffff;
  text-align: center;
  /* margin-left: 250px;
margin-top: -150px; */
}

.new {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.digital-card-text-1 {
  width: 124px;
  min-height: 160px;
  top: 492px;
  left: 603px;
  border-radius: 8px 8px 8px 8px;
}

.save-design-1 {
  margin-top: 20px;
  margin-left: 0px;
}

/* .image-logo-container {
  width: 85px !important;
  height: 40px !important;
  margin-top: 25px;
} */

.content6-image-logo-container {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.mb-154 {
  margin-left: -154px;
}

.m-5px {
  margin: 5px;
}

.description-content {
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.design-container {
  background-color: #f0f0f0;
  padding: 20px;
  height: 200px;
  width: 200px;
}

/* for qrcode Scan */
.card-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.card-profile {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 600px;
  /* Adjust the width as needed */
}

.card-image {
  margin-top: 10px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  /* border-radius: 50%; */
}

.container-qrcodeprofile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85%;
}

.column-qrcodeprofile:nth-child(1) {
  flex: 30%;
}

.column-qrcodeprofile:nth-child(2) {
  flex: 40%;
  margin-top: -10px;
}

.column-qrcodeprofile:nth-child(3) {
  flex: 30%;
}

/* 29-04-24 */

.justify-content-space-bw-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.justify-content-space-bw-login .next-button-container,
.justify-content-space-bw-login-margins .next-button-container {
  margin-top: 0;
}

.justify-content-space-bw-login-margins {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 11px;
  margin-right: 13px;
  margin-top: 24px;
}

.resent-otp {
  color: #00000061;
}

.resent-otp-design {
  color: #00000099;
  text-align: left;
  font-size: 16px;
}

.resent-otp-design button {
  font-size: 14px;
  padding: 10px;
  color: #0044c6;
  background-color: white;
  z-index: 1;
}

.resent-otp-design-margins {
  color: #00000099;
  text-align: left;
  font-size: 16px;
  margin-left: 11px;
}

.resent-otp-design button,
.resent-otp-design-margins button {
  font-size: 14px;
  padding: 10px;
  color: #0044c6;
  background-color: white;
  z-index: 1;
}

.resent-otp .time-unit {
  margin: 0 5px;
  /* Adjust the value as needed */
}

.priority {
  z-index: 999;
}

.css-hz1bth-MuiDialog-container {
  background-color: rgba(230, 237, 251, 0.6);
}

@media (max-width: 1024px) {
  .sidebar {
    width: 240px;
    /* height: 685px; */
  }

  .collapsed {
    width: 90px;
  }
}

/* Styles for large screens (1240px) */
@media (min-width: 1025px) and (max-width: 1240px) {
  .sidebar {
    /* width: 240px; */
    width: 18%;
  }

  .collapsed {
    width: 90px;
  }
}

/* Styles for extra-large screens (1440px) */
@media (min-width: 1241px) and (max-width: 1440px) {
  .sidebar {
    /* width: 240px; */
    width: 18%;
  }

  .collapsed {
    width: 90px;
  }
}

img,
svg {
  vertical-align: unset;
}

*.digital-card {
  box-sizing: unset;
}

.digital-card .small-circle-container,
.digital-card .circle-container,
.digital-card .small-circle-container img.companylogo {
  box-sizing: unset;
}

/* Design for Create New Card */
.bell-design {
  font-size: 10px;
  position: relative;
  border-radius: 50%;
  top: -10px;
  left: -8px;
  border: 2px solid white;
}

.icon-container {
  position: relative;
}

.icon {
  cursor: pointer;
}

.popup-container {
  position: absolute;
  top: 60px;
  /* Adjust this value to position the popup relative to the notification icon */
  left: 798px;
  min-width: 440px;
  min-height: 626px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  /* Ensure the popup appears above other content */
}

.popup-header {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid #fff;
  /* Adjust the color to match the popup background */
}

.notification-styles {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-weight: bold;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.87);
}

.menu-item-person {
  width: 100%;
}

.chat-profile {
  width: 40px;
  height: 40px;
  border-radius: 28px;
}

.container-card-display {
  display: flex;
  justify-content: flex-start;
  /* min-height: 100vh; */
  /* background-color: rgba(244, 247, 254, 1); */
}

.Allcards_full_container {
  background-color: rgba(244, 247, 254, 1);
  min-height: calc(100% - 106px);
}

.items-container-card {
  display: flex;
  flex-wrap: wrap;
}

.item-card-display {
  width: 276px;
  height: 410px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  background-color: #fff;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
  position: relative;
}

.item-card-display:hover {
  box-shadow: 0px 4px 18px 0px #0044c633, 0 0 0 2px #0044c6;
}

.smile-share-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -1rem;
  width: 100%;
  /* transform: translate(65%, 0); */
}

.create-new-card-select {
  width: 70%;
  height: 50%;
  cursor: pointer;
}

.item-card-display.Smaller-screen {
  width: 176px;
  height: 218px;
}

.digi-card-upper-allcards {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  /* width: 276px; */
  /* height: 259px; */
  width: unset;
  height: 15rem;
  border-radius: 10px;
}

.digi-card-upper-allcards.Smaller-screen {
  /* width: 176px;
  height: 165.16px; */
  width: unset;
  height: 10rem;
}


/* .photo-professional {
  width: 276px;
  height: 198.21px;
} */

.photo-professional.Smaller-screen {
  /* width: 176px;
  height: 150.58px; */
  width: unset;
  height: 10rem;
}

.digi-card-new .digi-card-label-container {
  position: relative;
  display: inline-block;
  top: 8px;
  border-radius: 0 20px 20px 0;
  width: auto;
  height: 39px;
  color: #ffffff;
  padding: 8px 16px 8px 12px;
  text-align: left;
  background-color: #0044c6b8;
  z-index: 2;
  max-width: 80%;
}

.digi-card-new .preview-card-upper {
  background-size: cover;
  border-radius: 8px 8px 0 0;
  position: relative;
  height: 232px;
  max-height: 232px;
}

#Personal-card .digi-card-new .preview-card-upper {
  background-size: cover;
  border-radius: 8px 8px 0 0;
  position: relative;
  height: 200px;
  /* max-height: 200px; */
}

.digi-card-new .preview-card .preview-card-names {
  margin: 1em 0 0.25em;
}

.digi-card-new .preview-card .preview-card-names h3 {
  font-size: 18px;
}

.digi-card-new .preview-card .preview-card-names h5 {
  font-size: 14px;
}

.personal-card-line {
  /* height: 1px;
  background-color: #0000001F;
  margin: 8px auto;
  width: calc(100% - 32px);
  padding: 0 16px; */
  height: 1px;
  background-color: #0000001f;
  margin: 8px auto;
  width: 89%;
  padding: auto;
  position: absolute;
  bottom: 60px;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
}

.digi-card-new .preview-card .quick-link-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  /* left: 8px;
  right: 8px; */
  padding: 8px 4px 8px;
}

.digi-card-new .contact-label {
  text-align: center;
  font-size: 12px;
}

.preview-card-upper.Smaller-screen {
  width: unset;
  height: 10rem;
}

.previewcardupper.LargerScreen {
  height: 220px;
}

.previewcardupper.Smaller-screen {
  height: 9rem;
}

#Personal-card .preview-card-upper.Smaller-screen {
  width: unset;
  height: 10rem;
}

.company-logo-container-cardSection {
  width: 79px;
  height: 79px;
  box-shadow: 0px 12px 24px 0px rgba(145, 158, 171, 0.12);
  border-radius: 50%;
  position: absolute;
  bottom: -1rem;
  right: 1rem;
  z-index: 2;
  background-color: white;
  overflow: hidden;
}

.company-logo-container-cardSection.Smaller-screen {
  width: 50.38px;
  height: 50.38px;
  /* position: relative;
    top: 95px;
    left: 120px; */
  position: absolute;
  bottom: 0rem;
  right: 0.5rem;
}

.prof-company-logo-cardSection {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

/* .prof-company-logo-cardSection {
  margin-top: 5px;
  padding: 10px;
} */

/* .company-logo-container-cardSection.Smaller-screen img {
  margin-top: 10px;
} */

.line-card {
  width: 100%;
}

.line-blue {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 68, 198, 1);
}

.icon-alignment-card {
  padding: 5px;
  margin-top: -22px;
  z-index: 2;
}

.icon-alignment-card .icon-border {
  border-radius: 50%;
  border: 1px solid rgba(0, 68, 198, 1);
  background-color: #fff;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.icon-alignment-card .icon-border .icon-color {
  margin: 4px;
}

.icon-outside {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(0, 68, 198, 1);
}

.quick-link-container .quick-link-icon {
  width: 55px;
  /* For even spacing between all the quick links */
}

.icon-color3 {
  color: #fff !important;
  /* margin: 5px; */
}

.icon-border-2 {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: white;
  margin-top: 75px;
  z-index: 2;
  cursor: pointer;
}

.icon-border-2 svg {
  margin: 8px;
  width: 22px;
  height: 22px;
}

.batch-profile {
  /* margin-top: 120px; */
  z-index: 2;
  position: relative;
  top: 115px;
  left: -5px;
}

.line-light-grey {
  width: 100%;
  height: 1px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.div-min-max {
  width: 86px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.18);
}

.default-card.Smaller-screen .Name,
.default-card.Smaller-screen .Profession {
  font-size: 14px !important;
  margin-bottom: 0;
}

.default-card .Name {
  font-weight: bold;
}

.digi-card-label-container.Smaller-screen {
  display: inline-block;
  font-size: 12px !important;
  height: 26px;
  padding: 4px;
  padding-right: 10px !important;
}

.item-card-display .preview-card-names.Smaller-screen {
  margin-top: 5px;
}

.preview-card-names.Smaller-screen h3 {
  font-size: 14px !important;
  margin-bottom: 4px;
}

.preview-card-names h5 {
  color: #00000099;
}

.icon,
.iconBreadcrumb {
  cursor: pointer;
}

.iconBreadcrumb svg {
  width: 16px;
  height: 16px;
}

.iconBreadcrumb.disable {
  opacity: 0.3;
  cursor: unset;
}

.popup-container-sort {
  position: absolute;
  top: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  width: 215px;
  height: 140px;
}

.popup-header-sort {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid #fff;
}

.favorite-icon {
  text-align: right;
  /* margin-right: 10px; */
}

.favorite-icon {
  position: absolute;
  top: 9px;
  right: 0px;
  width: 46px;
  height: 40px;
  background-color: #00000099;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  display: none;
  transition: all 0.3s ease;
  opacity: 0;
}

.select_button_topRight {
  position: absolute;
  top: 10px;
  right: 5px;
}

.item-card-display:hover .favorite-icon {
  display: flex;
  opacity: 1;
}

.tertiary-text {
  color: rgba(0, 0, 0, 0.38) !important;
}

span.span-center-align {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Cards Professional Detailed View */

.professional-card-view {
  background-color: #f4f7fe;
  display: flex;
  position: relative;
}

.card-box {
  /* width: 376px; */
  height: 96%;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 3px solid #919eab1f;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE/Edge */
  width: 32%;
}

.card-box-1 {
  /* width: 30%; */
  background: #ffffff;
  /* height: 96%; */
  border-radius: 8px;
  border: 3px solid #919eab1f;
  width: 24%;
}


.card-box-2 {
  /* width: 30%; */
  background: #ffffff;
  height: 96%;
  border-radius: 8px;
  border: 3px solid #919eab1f;
}

.card-box-2 {
  /* width: 30%; */
  background: #ffffff;
  height: 96%;
  border-radius: 8px;
  border: 3px solid #919eab1f;
}

.expandable {
  flex-grow: 1;
  /* Allow the container to grow and fill available space */
}

.card-professional-container {
  text-align: left;
  color: #ffffff;
  padding: 8px;
  background-color: #0044c680;
  position: relative;
  top: 20px;
  max-width: 100px;
  border-radius: 0 20px 20px 0;
}

.main-card {
  border-radius: 6px 6px 0px 0px;
  height: 20rem;
  max-height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.companylogo-container {
  position: relative;
  top: 68%;
  margin-left: 65%;
  border-radius: 50%;
  height: 112px;
  width: 112px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.professionalcompany-logo {
  text-align: center;
  margin: 16% 16%;
  width: 70%;
}

.card-view {
  /* font-family: "Lato", sans-serif; */
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.card-view-title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: #00000099;
}

.line-with-specs {
  position: relative;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #0044c6;
  position: relative;
  margin-top: 10%;
  transform: translateY(-50%);
}

.circle-left,
.circle-right {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  border: 3px solid #0044c6;
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circle-left {
  left: 100px;
  text-align: center;
  justify-content: center;
}

.circle-right {
  right: 100px;
  text-align: center;
  justify-content: center;
}

.qrcode-icon {
  width: 30px !important;
  height: 40px !important;
  color: #0000008a;
  margin: 0 auto;
}

.professional-card-content {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #00000099;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: #0044c6;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.single-line-icons {
  text-align: center;
}

.ml-12 {
  margin-left: 12px;
}

.single-line-icons-list {
  color: #ffffff;
  margin-top: 12px;
}

.text-fill {
  /* font-family: "Lato", sans-serif; */
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  color: #00000061;
}

.professional-social-title {
  /* font-family: " Lato", sans-serif; */
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #000000de;
  opacity: 70%;
}

.square-box {
  width: 50px;
  height: 50px;
  /* padding-top: 100%; */
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  /* Adjust shadow as needed */
  margin-left: 2px;
  margin-right: 2px;
}

.video-content-container {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  width: 342px;
  height: 194px;
  background-color: #ffffff;
}

.rectangle-pdf-container {
  width: 342.76px;
  height: 65.12px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid #d9d9d9;
}

.professional-map-container {
  width: 342px;
  height: 207px;
  border-radius: 8%;
  border: 3px solid #97979777;
}

.nav-tab-professionals {
  padding: 0px 0px 0 0px;
  border-bottom: 2px solid #dde0e6;
}

.tab-panel {
  padding: 0px !important;
}

.tab-item {
  flex: 1;
  text-align: center;
  /* Optional: Align the text in the center */
}

.custom-tablist .MuiTabs-indicator {
  width: 90px;
  height: 5px;
  border-radius: 10px 10px 0px 0px;
  background: #0044c6;
}

.qr-outline-container {
  width: 262px;
  /* height: 290px; */
  border-radius: 8px;
  border: 1px solid #919eab1f;
  margin-top: 50px;
}

.qr-cont-container {
  display: flex;
  justify-content: center;
  /* Horizontally centers the child elements */
  align-items: center;
  height: 100%;
  width: 70%;
}

.tab-content {
  display: flex;
  justify-content: center;
  /* Horizontally centers the child elements */
  align-items: center;
}

.qr-title {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #00000061;
}

.qr-center {
  margin-top: 10px;
  width: 200px;
  height: 200px;
  margin-left: 32px;
}

.share-profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #919eab1f;
  border-radius: 8px;
  box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33;
}

.share-profile-box {
  width: calc((262px - 1px) / 2);
  /* Subtracting 1px for the border */
  height: 107px;
  /* border: 1px solid #919eab1f;
  border-radius: 8px; */
  text-align: center;
}

.share-icon {
  margin-top: 20px;
}

.share-text {
  font-family: " Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: #0044c6;
}

.other-cards-title {
  /* position: sticky; */
  top: 0;
  background-color: white;
  z-index: 99;
  opacity: 1;
}

.scrollable-container-cont {
  background: white;
  max-height: 94%;
  /* Adjust max height as needed */
  overflow-y: auto;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE/Edge */
  text-align: center;
}

.scrollable-container-cont::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit browsers */
}

.scrollable-container-cont .card-row:last-child {
  margin-bottom: 16px;
}

.card-row {
  width: 90%;
  /* height: 320px; */
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33 !important;
  display: inline-block;
  margin-top: 16px;
}

.student-card-upper {
  border-radius: 6px 6px 0px 0px;
  height: 10%;
  background-size: cover;
  background-repeat: no-repeat;
}

.student-card {
  width: 100%;
  height: 200px;
  border-radius: 8px 8px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.student-card-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  text-align: left;
  color: #0044c6;
  margin-top: 10rem;
}

.student-card-content {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #00000099;
}

.email-outline-container,
.sms-outline-container {
  margin-top: 50px;
}

.email-title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: center;
  color: #000000de;
}

.email-content {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #00000099;
  opacity: 80%;
}

.custom-textfield {
  width: 382px;
  /* height: 48px; */
  font-size: 16px;
}

@media (max-width: 1240px) {
  .custom-textfield {
    width: 320px;
    /* height: 48px; */
    font-size: 16px;
  }
}

.custom-textfield .MuiInputLabel-root {
  color: #0000008a;
  /* Change label color */
  opacity: 80%;
}

.custom-textfield .MuiInput-underline:before {
  border-bottom-color: #0000008a;
  opacity: 80%;
}

.send-btn {
  padding: 8px 6px 8px 8px;
  background-color: #0044c6;
  padding: 12px 20px;
  border-radius: 4px 4px 4px 4px;
  color: #fff;
  top: 60px;
  position: relative;
  left: 286px;
}

.toast-success {
  background-color: #43a047 !important;
  color: #ffffff !important;
}

.toast-failure {
  background-color: #b00020 !important;
  color: #ffffff !important;
}

.popup-container {
  text-align: left;
  font-size: 16px;
  top: 60px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  display: flex;
  align-items: center;
}

.switch-info-container {
  display: flex;
  align-items: center;
  /* margin-top: 60px; */
}

.info-icon {
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

.popover-content {
  margin: 0;
  padding: 10px;
  width: 100%;
  height: 100%;
  font: "Lato";
  font-size: 14px;
  line-height: 16.8px;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.custom-mui-tel-input {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 10px 12px;
  font-size: 16px;
}

.custom-dropdown {
  right: 223px;
  position: relative;
}

.student-wallet-card {
  width: 228px;
  height: 358px;
  background-color: #1e2d48;
  border-radius: 8px;
  border: 1px solid black;
  margin: 50px 0;
  color: #00e4c6;
  position: relative;
}

.student-wallet-card .student-wallet-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8d7ae;
  padding: 4px 8px;
}

.student-wallet-header .college-logo {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: white;
}

.college-logo img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.student-wallet-card .student-wallet-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.student-wallet-body .student-profile-picture {
  border-radius: 50%;
  height: 82px;
  width: 82px;
  border: 4px solid #2b659b;
  background-color: white;
}

.student-profile-picture img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.student-wallet-body .student-info {
  width: 100%;
  margin-top: 10px;
  color: white;
}

.student-wallet-body .info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.student-wallet-body .info-label {
  font-weight: bold;
}

.student-wallet-body .info-value {
  text-align: right;
}

.student-wallet-card .student-wallet-footer {
  box-shadow: 0px 4px 4px 0px #00000040 inset, 0px 4px 4px 0px #00000040 inset;
  background-color: #49d6df;
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 8px 8px;
  color: black;
  padding: 8px;
  text-align: center;
}

.apple-wallet-outline {
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 228px;
  height: 358px;
  border: 2px solid transparent;
  /* Start with a transparent border */
  border-radius: 24px;
  background: linear-gradient(
      210.55deg,
      #091521 19.14%,
      #0b1624 19.16%,
      #5061d1 106.76%,
      #5562c5 131.23%,
      #3643bb 154.68%
    ),
    linear-gradient(
      244.98deg,
      #16cbf2 100%,
      rgba(25, 242, 242, 0) 0%,
      #8f9fae 100%
    );
  /* Apply linear gradient and separate background color */
  background-clip: padding-box, border-box;
  box-shadow: 16px 4px 64px 0px #123d654d, -16px -16px 40px 0px #ffffffcc,
    -8px -6px 80px 0px #ffffff2e inset;
  margin-top: 50px;
  margin-bottom: 50px;
}

.apple-social-icons {
  width: 24px;
  height: 150px;
  margin-top: 20px;
  margin-left: 5px;
}

.apple-side-left {
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  bottom: 20px;
  position: relative;
}

.apple-border {
  margin-top: 28px;
}

.apple-profile {
  width: 59px;
  height: 66px;
  border-radius: 50px;
  border: 3px solid #2b3873;
  margin: 66px 0px 0px 35px;
}

.profilepicture {
  border-radius: 50%;
  width: 55px;
  height: 61px;
}

.apple-title {
  width: 165px;
  height: 18px;
  font-family: "Share Tech Mono";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background: linear-gradient(
    278.57deg,
    #9ddbdf 100%,
    rgba(141, 217, 250, 0.443733) 44.34%,
    rgba(155, 171, 192, 0.4) 40%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-left: -10px;
  text-shadow: 1px 1px 2px #142c4952, -1px -1px 2px #ffffffe5;
}

.apple-border-right {
  position: relative;
  top: 30px;
  left: 75px;
}

.apple-border-title {
  width: 47px;
  height: 21px;
  font-family: "Share Tech Mono";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  background: linear-gradient(
    278.57deg,
    #202e43 0%,
    rgba(155, 171, 192, 0.4) 225.55%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-left: 25px;
  text-shadow: 1px 1px 2px 0px #142c495e, -1px -1px 2px 0px #ffffff99;
}

.neologo {
  left: 40px;
  width: 54px;
  height: 30px;
  position: relative;
  top: 55px;
}

.apple-main-content {
  display: flex;
  flex-direction: column;
}

.apple-company-title {
  width: 90px;
  height: 21px;
  transform: rotate(270deg);
  font-family: "ABeeZee";
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  background: linear-gradient(
      272.24deg,
      rgba(218, 233, 242, 0.42) 0%,
      rgba(110, 139, 179, 0) 315.87%
    ),
    linear-gradient(
      277.72deg,
      #1bd4ed 0%,
      rgba(70, 108, 139, 0.0932883) 98.86%,
      rgba(75, 98, 129, 0) 109.03%,
      rgba(110, 139, 179, 0) 163.4%
    );
  -webkit-background-clip: text;
  /* Change background-clip to border-box */
  background-clip: text;
  /* Apply text border */
  color: transparent;
  -webkit-text-stroke: 1px #27335c;
  /* Apply text border */
  text-shadow: 0px 4px 4px 0px #00000040 inset, 0px 4px 4px 0px #00000040 inset;
  margin-left: 160px;
  top: 45px;
  position: relative;
}

.icon-color-picker {
  width: 52px;
  height: 52px;
  margin-left: 5px;
  border: 1px solid rgba(224, 224, 224, 1);
  background: rgba(255, 255, 255, 0.12);
}

.icon-color-picker svg {
  width: 24px;
  height: 24px;
}

.dashed-border {
  width: 100%;
  height: 85px;
  border: 2px dashed #aaa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

.custom-upload-button {
  color: rgba(0, 68, 198, 1);
}

.Generate-colors {
  background-color: var(--Primary-Color, rgba(0, 68, 198, 1));
  text-wrap: nowrap;
  color: #fff;
  padding: 6px;
  margin-left: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.Colors-display-border {
  /* width: 180px;
  height: 40px; */
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  padding: 12px 16px;
}

.color-picker-label {
  position: relative;
  display: inline-block;
}

.color-picker-icon {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  /* Make sure the icon doesn't interfere with the input */
}

.color-picker-input {
  opacity: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.default-colors-container {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next line if needed */
}

.default-color-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  /* Adjust spacing between items */
  margin-bottom: 20px;
  /* Adjust spacing between rows */
}

.color-code {
  font-size: 14px;
  margin-right: 10px;
  padding: 10px;
  /* Adjust spacing between color code and color swatch */
}

.Brand-colors {
  width: 40px;
  height: 44px;
  cursor: pointer;
  text-align: right;
  border-radius: 5px;
  padding: 10px;
}

.Brand-colors-outer {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.Brand-colors:hover {
  filter: brightness(1.2);
}

.shape-qrcode {
  width: 50px;
  height: 50px;
  /* box-shadow: 0px 12px 24px 0px rgba(145, 158, 171, 0.12); */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.shape-qrcode .square {
  width: 24px;
  height: 24px;
  background-color: rgba(97, 97, 97, 1);
}

.shape-qrcode .square-rounded {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-color: rgba(97, 97, 97, 1);
}

.shape-qrcode .circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(97, 97, 97, 1);
}

.border-above-qr {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  overflow: hidden;
}

.shape-qrcode.selected,
.shape-qrcode:hover {
  border: 1px solid rgba(0, 68, 198, 1);
  border-radius: 4px;
}

.border-above-qr.selected,
.border-above-qr:hover {
  border: 1px solid rgba(0, 68, 198, 1);
  border-radius: 4px;
}

.div-qr-code.selected,
.div-qr-code:hover {
  border: 1px solid rgba(0, 68, 198, 1);
  border-radius: 4px;
}

.square-border {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(97, 97, 97, 1);
}

.shape-qrcode .square-rounded-border {
  width: 24px;
  height: 24px;
  border-radius: 7px;
  border: 1px solid rgba(97, 97, 97, 1);
}

.shape-qrcode .circle-border {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(97, 97, 97, 1);
}

.shape-qrcode .Square-left-bottom {
  width: 24px;
  height: 24px;
  border-radius: 0px 4px 0px 4px;
  border: 1px solid rgba(97, 97, 97, 1);
}

.shape-qrcode .Square-right {
  width: 24px;
  height: 24px;
  border-radius: 4px 0px 4px 0px;
  border: 1px solid rgba(97, 97, 97, 1);
}

.div-qr-code {
  width: 50px;
  height: 50px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.border-qr-code {
  border: 1px solid rgba(0, 0, 0, 1);
}

#Card-firstPart-createNew {
  position: relative;
}

.dialogue-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(23, 44, 87, 0.5);
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 89;
  border-radius: 6px 6px 0px 0px;
}

.Scanner-outline-preview {
  width: 90%;
  min-height: 250px;
  background-color: white;
  border-radius: 8px;
  border: 1px;
  margin: auto;
  padding: auto;
}

.Displaying-QR {
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.MuiBox-root.dialogue-overlay-box-small {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  color: var(--primary-text);
}

.qrCodeContainer {
  text-align: center;
  display: inline-block;
  padding: 8px;
}

.user-details-social-icons.professional-socialicons-container .square-box {
  padding: 5px;
}

@media (max-width: 768px) {
  .user-details-box-1 {
    margin-right: 1.5rem;
  }

  .user-details-box-3 {
    margin-left: 1.5rem;
  }
}

.text-display-details {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: var(--Red, rgba(235, 78, 61, 1));
}

.share-dialog .MuiDialogContent-root {
  border-top: 0px;
  border-bottom: 0px;
  height: unset;
}

.share-qr-content {
  font-family: " Lato", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: black;
  opacity: 77%;
}

.share-qr-subcontent {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #00000099;
}

.share-icons {
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: row;
  /* Ensure items are laid out horizontally */
  justify-content: space-between;
  gap: 10px;
  /* Adjust the gap between icons as needed */
  align-items: center;
}

.share-button {
  border-radius: 4px;
  padding: 8px 6px 8px 8px;
  position: relative;
  bottom: 0px;
  left: 85%;
  text-transform: uppercase;
}

.downloadShareButtonNew.css-d3wcwz-MuiTypography-root {
  padding-top: 16px;
}

.download-share-button {
  border: 1px solid #0000001f;
  border-radius: 4px;
  padding: 8px 8px 8px 8px;
  color: #0044c6;
  background-color: #ffffff;
  text-transform: uppercase;
}

.dowload-share-icon {
  stroke: #0044c6;
  margin: 0px 5px -5px 0px;
}

.share-profile-box:hover {
  cursor: pointer;
}

.save-bottom-container {
  text-align: right;
  padding: 12px 16px;
  border-radius: 0 0 8px 8px;
  background-color: #0044c614;
}

.primary-btn.MuiButton-root {
  background-color: #0044c6;
}

.secondary-btn.MuiButton-text {
  color: #0044c6;
}

.box-paragraph {
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  padding: 10px;
}

.no-fav-cards {
  width: 420px;
  height: 380px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin: auto;
  padding: auto;
}

.heading-fav {
  font-size: 18px;
}

.heading-fav-content {
  font-size: 14px;
  color: var(--secondary-text);
}

.fav-button {
  background: var(--Primary-Color, rgba(0, 68, 198, 1));
  padding: 8px;
  border-radius: 4px;
  color: white;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #172c5780;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.lightbox-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 600px;
  min-width: 300px;
}

.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #0000008a;
}

.active-component-container {
  margin-top: 20px;
}

.pointer {
  cursor: pointer;
}

.pointer-unset {
  cursor: unset;
}

.svg-icon-32px {
  height: 32px !important;
  width: 32px !important;
}

.svg-icon-3p5rem {
  height: 3.5rem;
  width: 3.5rem;
}

.left-right-icon-new {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 10;
  box-shadow: 0px 4px 4px 0px #00000026;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.sidebar.collapsed .sidebar-link {
  padding-left: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-link.active {
  border-left: var(--Primary-Color, #0044c6);
}

.sidebar.collapsed .sidebar-Nav-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-Nav-content.collapsed .sidebar-icon {
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar.collapsed .sidebar-content .sidebar-link {
  padding: 0px 0px 10px 0px;
}

.sidebar.collapsed .sidebar-content .sidebar-link .MuiListItemText-primary {
  color: #00000099;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: center;
}

.sidebar .sidebar-content .MuiListItemText-primary {
  color: #00000099;
}

.sidebar .sidebar-content .MuiListItemText-primary {
  color: #00000099;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}

.sidebar .sidebar-content .sidebar-link.active .MuiListItemText-primary {
  color: var(--Primary-Color, #0044c6);
}

.sidebar.collapsed
  .sidebar-content
  .sidebar-link.active
  .MuiListItemText-primary {
  color: var(--Primary-Color, #0044c6);
}

.sidebar-link.active {
  width: 100%;
  color: var(--Primary-Color, #0044c6);
  border-left: 4px solid #0044c6;
}

.sidebar.collapsed .sidebar-link .MuiListItemIcon-root {
  justify-content: center;
}

.email-dialog .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: unset;
}

.email-dialog .dialogText {
  padding-top: 6px;
}

.my-profile-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: calc(1rem + 8px);
}

.my-profile-heading {
  text-align: left;
  color: #000000de;
  margin-top: 0;
  margin-bottom: 5px;
  width: 70%;
  max-width: 70%;
}

.my-profile-heading.name {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-text);
  margin-bottom: 0;
  text-align: left;
}

.my-profile-heading .subheading {
  text-align: center;
  color: var(--secondary-text);
  margin-top: 0;
  font-size: 18px;
  text-align: left;
}

.my-profile-heading-container .recent-experience {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 0 0 30%;
  text-align: left;
}

.recent-experience .recent-logo {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.my-profile-details {
  margin-left: 400px;
  display: flex;
  flex-direction: column;
}

.my-profile-logo {
  display: flex;
  flex-direction: row;
}

.my-profile-open {
  text-align: center;
  width: 90px;
  height: 105px;
  box-shadow: 0px 0px 2px 0px #919eab33;
  border: 0.5px solid #00000014;
  background: #ffffff;
  border-radius: 8px;
}

.my-profile-open.button-clicked {
  border: 2px solid #0044c6;
  box-shadow: 0px 4px 18px 0px #0044c633;
}

.open-to-menu-title {
  font-size: 14px;
  color: var(--primary-text);
  display: flex;
  align-items: center;
  gap: 4px;
}

.open-to-menu-subtitle {
  font-size: 12px;
  color: var(--secondary-text);
  line-height: 14.4px;
}

.profile-popup-menu li.MuiMenuItem-root {
  padding: 8px 12px;
}

.profile-popup-menu .MuiMenuItem-root:not(:last-child),
.profile-popup-menu .MuiListItemButton-root,
.add-services-list .MuiListItem-root:not(:last-child) {
  border-bottom: 1px solid #cecece;
}

.profile-popup-menu .MuiList-root.sublist .MuiListItemButton-root {
  background-color: #0044c605;
}

.my-profile-status {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 107px;
  word-wrap: normal;
  box-shadow: 0px 0px 2px 0px #919eab33;
  border: 0.5px solid #00000014;
  background: #ffffff;
  border-radius: 8px;
}

.profile-completion-bar {
  font-family: "Roboto", sans-serif;
}

.my-profile-section {
  text-align: center;
  background-color: #ffff;
  box-shadow: 10px 10px 5px lightgray;
  width: 90px;
  height: 105px;
  box-shadow: 0px 0px 2px 0px #919eab33;
  border: 0.5px solid #00000014;
  background: #ffffff;
  border-radius: 8px;
  font-size: 14px;
}

.my-profile-actions {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.professional-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.add-education {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.education-container-details {
  align-content: center;
  height: 246px;
  width: 628px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.education-container {
  margin-top: 5px;
  border: 2px solid lightgray;
  padding-left: 10px;
  margin-bottom: 10px;
}

.education-title-container {
  padding-top: 10px;
}

.education-title {
  font-weight: "bold";
  font-size: 20;
}

.add-education-icon {
  margin-bottom: 10px;
}

.add-education-title {
  margin: 0;
}

.Myprofile-box {
  width: 800px;
  height: auto;
  margin-top: 10px;
  margin-left: 20px;
  background: white;
  border: 2px solid #919eab33;
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 20px;
}

.My-Profile-Scanner-image {
  position: absolute;
  background-repeat: no-repeat;
  top: 75%;
  right: 24px;
  border-radius: 8px;
  background-color: white;
  padding: 12px;
}

.profile-qr-container {
  padding: 4px;
  background-color: #ffff;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.My-Profile-Scanner-image-container {
  background-color: white;
}

.My-Profile-img-container {
  width: 182px;
  height: 180px;
  position: relative;
  /* Position the circle absolutely within the rectangle */
  top: 30px;
  /* Align the circle to the bottom of the rectangle */
  left: 110px;
  /* Align the circle horizontally */
  transform: translateX(-50%);
  /* Center the circle horizontally */
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: left;
  overflow: hidden;
}

.My-Profile-img-container img {
  width: 162px;
  height: 162px;
  border-radius: 50%;
  object-fit: cover;
  padding: 10px;
  /* Maintain the gap between the circle and the image */
}

.probar {
  width: 75px;
  height: 60px;
  margin: 0 5px;
  padding-left: 10px;
}

.about-me-actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  gap: 12px;
  color: rgb(0, 0, 0, 0.54);
}

.about-me-container {
  margin-top: 10px;
  border: 2px solid lightgray;
  padding-left: 10px;
}

.about-me-title-container {
  display: flex;
  flex-direction: row;
}

.my-profile-heading-content {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000ad;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
}

.my-profile-heading-content .uid-container {
  text-align: center;
  color: #0044c6;
  margin-right: 12px;
  border-radius: 20px;
  background: rgb(0, 70, 199, 0.1);
  padding: 4px 12px;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
}

.my-profile-heading-content .profile-meta-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  font-size: 16px;
  font-weight: 500;
}

.profile-meta-info svg {
  color: #0044c6;
  margin-right: 6px;
}

.profile-meta-info span {
  text-wrap: nowrap;
  color: #0044c6;
}

.my-profile-heading-content .MuiDivider-root {
  height: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  border-color: rgb(0, 68, 198, 0.4);
  opacity: unset;
}

@media (max-width: 1150px) {
  .my-profile-heading-container {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .my-profile-status {
    width: unset;
    height: unset;
  }

  .probar-content {
    margin-right: unset;
    padding: 12px
  }
}

.collapsed .submenu {
  /* overflow: visible; */
  position: absolute;
  left: 90px;
  top: 100px;
  background-color: white;
  box-shadow: 0px 5px 5px 0px #00000033, 0px 3px 14px 0px #0000001f,
    0px 8px 10px 0px #00000024;
  border-radius: 4px;
  z-index: 99;
  /* Ensure submenu appears above the main content */
  /* padding: 10px 0; */
  display: none;
  width: 14%;
  transition: display 1s ease;
}

.collapsed .submenu.visible {
  display: block;
}

.collapsed .submenu .sidebar-link {
  align-items: unset;
  padding-left: 16px;
  border-bottom: 0.4px solid #e2e8f0;
}

.collapsed .submenu .sidebar-link:first-child {
  border-radius: 4px 4px 0 0;
}

.collapsed .submenu .sidebar-link:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: unset;
}

.submenu .sidebar-link.active {
  border-left: unset;
  font-weight: 600;
}

.sidebar-link:hover + .submenu,
.submenu:hover {
  display: block;
}

.sidebar-link .MuiListItemIcon-root {
  min-width: auto;
}

.sidebar .sidebar-link .MuiListItemIcon-root {
  padding-right: 30px;
}

.sidebar.collapsed .sidebar-link .MuiListItemIcon-root {
  padding-right: unset;
}

.sidebar-link .img-active {
  width: 24px;
  height: 24px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapsed .flex-column {
  flex-direction: column;
}

.sidebar-header .logo-container .logo {
  width: 100%;
  height: auto;
}

.height-adjustment {
  width: 100%;
}

.great-offers {
  text-align: center;
  padding: 10px;
}

.container-gift {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-gift img {
  margin-right: 10px;
}

.container-gift p {
  margin: 0;
}

.small-gift-container {
  text-align: center;
  padding: 10px;
}

.small-gift {
  width: 24px;
  height: 24px;
}

.dialogImage-New-cards {
  width: 72px;
  height: 72px;
}

.color-code {
  color: var(--Primary-Color, #0044c6);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  background: none;
}

.newbuttonUpgrade {
  background-color: #0044c6;
  padding: 4px 8px 4px 6px;
  border-radius: 8px;
  color: #fff;
}

.lightbox-close2 {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #0000008a;
}

.CardCategory {
  border: 1px solid #00000014;
  border-radius: 8px;
  width: 128px;
  height: 125px;
}

.CardCategory.selected {
  border: 1px solid #0044c6;
}

.icon-outside-new {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.map-search-contact .css-1kcggdq-MuiInputBase-root .MuiInputBase-input {
  width: 100%;
}

.professional-map-containerNew {
  width: auto;
  height: 207px;
  border-radius: 4px;
  /* border: 3px solid #97979777; */
}

.mail-sign-stepper {
  width: 100%;
}

.second-part {
  border-left: 1px solid #00000014;
  height: 100%;
}

.sorted-item {
  position: relative;
  cursor: pointer;
}

.popup-container-sort-new {
  position: absolute;
  top: 100%;
  left: -40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  /* width: 124px;
  height: 76px; */
  margin-top: 8px;
}

.min-widthHeight-Dropdown {
  width: 124px;
  height: 76px;
}

.color-blue {
  color: #0044c6 !important;
}

svg.color-blue path {
  fill: #0044c6;
  fill-opacity: 1;
}

svg.color-grey path {
  fill: #000000;
  fill-opacity: 0.54;
}

.icon-new {
  cursor: pointer;
  margin-top: 2px;
}

.hobbiesSection-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #000000de;
}

.border-radius-hobbies {
  width: 71px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid var(--Primary-Color, #0044c6);
  padding: 4px 12px 4px 12px;
}

.custom-accordion .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #0044c60a !important;
  color: var(--secondary-text);
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #0000001f;
}

/* MyProfile-Personal Styles */

.my-Profile-part {
  padding: 10px 20px 0 20px;

  width: 100%;
  background-color: #f4f7fe;
}

.myprofile-column-1 {
  padding: unset;
  background-color: #fff;
  width: 64%;
  height: 100%;
  margin: 12px;
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  flex-grow: 1;
}

.myprofile-column-2 {
  padding: unset;
  margin: 12px;
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  min-width: 275px;
}

@media (min-width: 1600px) {
  .myprofile-column-2 {
    max-width: 500px;
  }
}

.my-profile-bg {
  width: auto;
  height: 228px;
  border: 0px solid #919eab33;
  position: relative;
}

.outiline-editicon {
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* 
.My-Profile-imgOutline img {
  width: 162px;
  height: 162px;
  border-radius: 50%;
  object-fit: cover;
} */

.subheading-2 {
  color: #00000091;
  opacity: 57%;
  margin: 0;
  padding: 0;
}

.about-me-personal {
  width: auto;
  min-height: 150px;
  border-radius: 8px;
  border: 1px solid #0000001f;
  padding: 1.5rem;
  margin: 1rem 0 1.5rem;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.1),
    0px 0px 2px 0px rgba(145, 158, 171, 0.1);
  overflow: auto;
}

.headingforContent {
  color: #000000de;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  margin-bottom: 0 !important;
}

.headingforContent.video-resume-heading {
  margin-bottom: 10px !important;
}

.contentabout-me {
  margin: 0;
  padding: 0;
}

.second-part-of-Screen {
  padding: 16px 24px;
}

.second-part-of-Screen.sec-first {
  margin-top: 72px;
}

.text-left {
  text-align: left !important;
  overflow: unset !important;
  text-overflow: unset !important;
}

.skill-item-ProfessionalProfile {
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin: 5px 5px 5px 0px;
}

.skills-list-professional {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 12px;
}

.professional-page-section {
  width: auto;
  min-height: 120px;
  border-radius: 8px;
  border: 1px solid #0000001f;
  padding: 1.5rem;
  margin: 1.5rem 0;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.1),
    0px 0px 2px 0px rgba(145, 158, 171, 0.1);
  /* overflow: auto; */
}

.menu-item .submenu {
  display: none;
}

.menu-item.expanded .submenu {
  display: block;
}

/* /11-06-24 */
.sub-text-lightbox {
  text-align: left;
  color: #00000099;
  font-size: 14px;
}

.institute-logo-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.institute-logo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.university-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: var(--primary-text);
}

.design-lighter-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #00000099;
}

.Certificate-design {
  color: var(--Primary-Color, #0044c6);
  border-block-end: 1px solid #0044c6;
}

.education-list {
  width: 100%;
}

.education-item {
  margin-bottom: 16px;
  width: 100%;
}

.divider {
  border-bottom: 1px solid #ccc;
  margin-top: 16px;
  width: 100%;
}

.education-item:last-child .divider {
  display: none;
}

.about-me-edit-container {
  margin: 0;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #0000001f;
}

.About-me-edit {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-text);
  /* border-radius: top-left top-right bottom-right bottom-left; */
  border-radius: 8px 8px 0 0;
  resize: none;
  box-sizing: border-box;
  margin: 0;
  border-width: unset;
  border-style: unset;
  border-color: unset;
}

textarea.About-me-edit:focus-visible,
.color-picker-field fieldset:focus-visible,
.color-picker-field fieldset:focus {
  border-width: unset;
  border-style: unset;
  border-color: unset;
  outline: unset;
}

.about-me-edit-count {
  text-align: left;
  border-top: 1px solid #0000001f;
  /* border-radius: top-left top-right bottom-right bottom-left; */
  border-radius: 0 0 4px 4px;
  padding: 8px 14px;
  /* Merges the border with the textarea */
  box-sizing: border-box;
}

/* 12-06-2024 */
.textfield-new-styles .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
.textfield-new-styles .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  background-color: transparent;
  padding: 0;
  padding-left: 5px;
}

.color-lightred {
  color: #eb4e3d;
}

.delete-icon-skills .MuiSvgIcon-fontSizeMedium {
  color: #eb4e3d;
}

.delete-icon-skills.MuiSvgIcon-root {
  color: #eb4e3d;
}

.delete-icon-skills.MuiIconButton-root {
  padding: 0px;
}

.institute-details {
  flex: 1;
}

.confidential-warning {
  color: #eb4e3d;
  font-size: 12px;
  line-height: 14px;
}

.selectedOptions .CardCategory {
  cursor: pointer;
  transition: border 0.2s ease;
  border: 1.5px solid #00000014;
  box-shadow: 0px 0px 2px 0px #919eab33;
}

.selectedOptions .CardCategory:hover {
  border-color: #0044c6;
}

.selectedOptions .CardCategory svg {
  color: #0000008a;
}

.selectedOptions .CardCategory:hover,
.selectedOptions .CardCategory:hover svg {
  color: #0044c6 !important;
}

.bordered-study-year2 {
  border: 1px solid #0000001f;
  margin: 8px 0;
  width: fit-content;
  padding: 2px 4px;
}

.margin-bottom-8px {
  margin-bottom: 8px;
}

.color-code-delete {
  font-size: 14px;
  padding: 10px;
  color: var(--Primary-Color, #0044c6);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  background: none;
  border: 1px solid #0000001f;
  border-radius: 4px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Full viewport height to center vertically */
  width: 100vw;  /* Full viewport width to center horizontally */
  background-color: rgba(242, 241, 241, 0.8);  /* Dark overlay background */
  position: fixed;  /* Make sure it covers the whole screen */
  top: 0;
  left: 0;
  z-index: 1000;  /* Ensure it's above other content */
}

.status-new {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
}

.user-text {
  font-size: 16px;
  /* Adjust the font size as needed */
  flex-grow: 1;
  margin-right: 8px;
}

.text-right {
  margin-left: auto;
  /* Align the switch to the right */
}

#simple-menu-for-header .user-text {
  font-size: 14px;
  color: #000000de;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-left: 5px;
}

.sortable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  cursor: pointer;
}

.popup-content-breadcrumb {
  box-shadow: 0px 5px 5px 0px #00000033;
  width: 167px;
  height: 108px;
  padding: 7px;
}

.text {
  margin-right: 10px;
  font-size: 14px;
  /* color: #333; */
  text-wrap: nowrap;
}

.arrows {
  display: flex;
  align-items: center;
}

.email-chip.MuiChip-root {
  margin: 4px;
}

/* 17-06-24 */
.MuiGrid-item .Typography-left-align {
  text-align: left;
}

.dot-locationtype {
  margin: 0 5px;
  padding-bottom: 7px;
}

.role-title-bold {
  color: #000000de;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
}

.documents-logo-container {
  width: 70px;
  height: 62px;
  /* background: #D8D8D8; */
  overflow: hidden;
}

.documents-logo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
}

.resume-item {
  margin-bottom: 16px;
  width: 100%;
}

.resume-image-container {
  width: 180px;
  height: 100px;
  background: #d8d8d8;
  overflow: hidden;
  position: relative;
}

/* new */
/* Add this CSS to your styles */

.resume-image-container {
  position: relative;
}

.resume-image-container .react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
}

.modal-box-1.MuiBox-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: white;
  border-radius: 8px;
  overflow-y: auto;
  max-width: 80%;
  max-height: 80%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs {
  border-bottom: none;
}

.nav-link {
  cursor: pointer;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.lightbox-close-new {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.lightbox-content-new {
  background: white;
  /* padding: 20px; */
  border-radius: 8px;
  position: relative;
  max-width: 600px;
  min-width: 518px;
  min-height: 459px;
  /* width: 100%; */
}

ul.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li.custom-item {
  /* border-bottom: 1px solid #000; */
  border-bottom: 1px solid #d0d5dd4d;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px 24px;
  cursor: pointer;
  color: var(--primary-text);
  transition: color 0.2s ease;
}

.negative-margin {
  margin-top: -10px;
}

.checkbox-section {
  margin-bottom: 20px;
}

.time-inputs {
  display: flex;
  gap: 10px;
}

.time-field {
  width: 100px;
}

.time-field .MuiInputBase-input {
  padding: 10.5px 14px;
  font-size: 14px;
}

.time-label {
  color: var(--secondary-text);
  font-size: 14px;
  font-weight: 500;
}

.contact-groups {
  margin-top: 10px;
  /* margin-left: 30px; */
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.group-name {
  font-weight: bold;
  margin-bottom: 8px;
}

.person-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* gap: 12px; */
}

.person-box {
  background: rgba(0, 68, 198, 0.08);
  height: 160px;
  width: 144px;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
}

.group-checkbox {
  position: sticky;
  top: 0px;
  left: 0;
  margin: 0px 0px -42px 110px;
}

.person-image {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px;
}

.displayflex {
  display: flex;
}

.selectall {
  margin-left: 10%;
}

.create-group-icon {
  margin-top: 50px;
}

.person-box1 {
  /* background: rgba(0, 68, 198, 0.08); */
  min-height: 160px;
  width: 144px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
}

.avatars-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.avatar-box {
  /* justify-content: center; */
  width: 259px;
  height: 260px;
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
}

.avatar-box.compact {
  width: 204px;
}

.avatar-details {
  /* display: flex; */
  align-items: center;
}

.avatar-details-connect {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-image {
  margin-left: 40px;
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
}

.avatar-image-connect {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.member-info {
  text-align: center;
  flex-grow: 1;
  margin-top: -5px;
}

.member-info-connect {
  text-align: center;
  flex-grow: 1;
}

.member-name {
  text-align: center;
  font-weight: bold;
}

.member-name-connect {
  text-align: center;
  font-weight: bold;
}

.member-role {
  color: #666;
  font-size: 0.8em;
}

.member-role-connect {
  color: #666;
  font-size: 0.8em;
}

.member-email{
  width: 195px;
}
.icons-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* top: 10px; */
  right: -2px;
}

.icon-box {
  width: 62px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
  border-radius: 4px;
}

.icon {
  font-size: 1.5em;
  color: #0044c6;
}

.vertical-line {
  width: 1px;
  height: 100%;
  /* Match height of the avatar box */
  background-color: #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 212px;
  /* Adjust as needed based on avatar image size */
  z-index: 0;
  /* Ensure vertical line is behind avatar and member info */
}

.star-icon {
  /* position: absolute;
  top: 20px; */
  right: 60px;
  color: gray;
}

/* border: Mixed solid #00000014 */

/* Add this to your CSS file */
.outer-container {
  padding: 24px;
  border-radius: 4px;
  /* height: 100%; */
  background-color: #fff;
  min-height: 88vh;
  position: relative;
}

.outer-layer-of-contacts {
  padding: 24px;
  background-color: #f4f7fe;
}

.Main-tag-portfolio {
  min-height: 100vh;
  /* width: 100vw; */
  background-image: url("./Assets/img/Profile/Backgroung_Image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 2%;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.header-portfolio {
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

.Nav_Portfolio ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  row-gap: 16px;
}

.Nav_Portfolio ul li.portfolio-skill-item {
  padding: 4px 8px;
  border-radius: 4px;
  border: 0.5px solid white;
}

.Nav_Portfolio.mobile-header {
  background-color: #002244;
}

.Nav_Portfolio.mobile-header ul {
  row-gap: 8px;
  background-color: #002244;
  transition: all 0.4s ease;
  height: auto;
  transform-origin: top;
  flex-wrap: unset;
}

.Nav_Portfolio.mobile-header ul.drawer-closed {
  max-height: 0;
  transform: scaleY(0);
  visibility: hidden;
  pointer-events: none; /* No interaction */
}

.Nav_Portfolio.mobile-header ul.drawer-open {
  max-height: 50vh;
  transform: scaleY(1);
  visibility: visible;
  pointer-events: auto; /* Enable interaction */
  padding-bottom: 8px;
  padding-left: 8px;
}

.margin-leftRight {
  margin-left: 50px;
  margin-right: 50px;
}

.portfolio-content {
  flex: 1
}

.portfolio-content.mobile-portfolio-body {
  padding-top: 90px;
  padding-inline: 8px;
  padding-bottom: 12px;
}

.portfolio-item {
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: white;
  text-wrap: nowrap;
  transition: all 0.3s ease;
}

.portfolio-item.footer-fancy-line {
  max-width: 80%;
  overflow: hidden;
}
/* 
.mobile-footer .portfolio-item.footer-fancy-line {
  max-width: 40%;
} */

.portfolio-item:hover:not(.active) {
  color: #38b6ff;
}

.portfolio-item.active {
  /* color: #38b6ff; */
  background: #08B6FF;
  border-radius: 8px;
  padding: 8px;
  font-weight: 600;
}

.logo-part-portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-part-portfolio img {
  height: 80px;
  /* margin-right: 10px; */
}

button.portfolio-resume-download {
  padding: 8px 12px;
  text-transform: uppercase;
  color: white;
  background-color: transparent;
  transition: all 0.3s ease;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid white;
  display: flex;
  align-items: center;

  &:hover {
    background-color: white;
    color: black;
    box-shadow: 2px 0px 6px 2px;
  }
}

button.portfolio-resume-download:hover .white-svg path {
  fill: black;
}

.content-about {
  padding-left: 7%;
  padding-right: 7%;
  display: flex;
  font-size: 18px;
}

.content-about-left-side {
  width: 55%;
}

.content-about-right-side {
  width: 45%;
}

.sticky-profile-picture {
  position: sticky !important;
  top: 4rem;
}

.headline-profile {
  background-color: #38b6ff;
  color: #161616;
  display: inline;
  padding-right: 8px;
}

.hello-style {
  /* font-family: 'Looking Flowers', sans-serif;123 */
  font-size: 24px;
  color: #38b6ff;
  font-family: "Playwrite NL", cursive;
}

.hello-style {
  font-size: 20px;
}

/* 18 */
.designation-profile {
  margin-top: 30px;
}

.fullName_portfolio {
  font-size: 68px;
  font-family: "Lato", sans-serif;
}

@media (max-width: 526px) {
  .fullName_portfolio {
    font-size: 44px;
  }

  .content-about {
    font-size: 1rem;
  }
}

.subtitle-heading-portfolio {
  width: 174px;
  border-bottom: 2px solid #f4d644;
  padding-bottom: 5px;
  font-weight: bold;
}

.start-logo {
  height: 60px;
  width: auto;
  background-color: #fff;
  position: relative;
}

.dynamic-portfolio.start-logo {
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 6px 6px 0 0;
}

.school-logo-about {
  position: absolute;
  left: 4px;
  height: 60px;
  object-fit: cover;
}

.dynamic-portfolio .school-logo-about {
  mix-blend-mode: multiply;
    position: unset;
    height: 100%;
    max-width: 130px;
    object-fit: contain;
}

.logo-right-portfolio {
  position: absolute;
  right: 4px;
  top: 3px;
  height: 55px;
  object-fit: cover;
}

.dynamic-portfolio .logo-right-portfolio {
  position: unset;
  height: 100%;
  object-fit: cover;
}

.profile-picture {
  height: 410px;
  width: 410px;
  object-fit: cover;
  border-radius: 0 0 8px 8px;
}

@media (max-width: 526px) {
  .profile-picture {
    height: 100%;
    width: 100%;
  }
}

.summary-portfolio {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
  -webkit-box-orient: unset;
  overflow: unset;
  text-overflow: unset;
}

.qrcode-portfolio {
  /* width: 102px;
  height: 102px; */
  z-index: 1;
  position: absolute;
  right: -55px;
  bottom: -40px;
}

.right-side-rectangle {
  width: 410px;
  height: 470px;
  position: relative;
}

@media (max-width: 526px) {
  .right-side-rectangle {
    width: unset;
    height: unset;
  }
}

/* 21-06-24 */
.portfolio-aboutme-left-side {
  width: 45%;
  position: relative;
  padding-left: 2rem;
}

@media (max-width: 526px) {
  .portfolio-aboutme-left-side {
    width: unset;
    padding-right: 2rem;
  }
}

.Portfolio-photo-left {
  width: 358px;
  height: 414px;
}

.dot-style-portfolio {
  position: absolute;
  right: 0;
  top: -60px;
}

.portfolio-aboutme-right-side {
  width: 55%;
}

.big-sub-headingPortfolio {
  font-size: 54px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 5px solid #38b6ff;
  letter-spacing: -1px;
  padding-bottom: 10px;
}

@media (max-width: 526px) {
  .big-sub-headingPortfolio {
    font-size: 30px;
    padding-bottom: 2px;
  }
}

.font-size-16px {
  font-size: 16px;
  margin-top: 50px;
}

.font-size-22px {
  font-size: 22px;
  font-weight: bold;
}

.skill-list {
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
}

.skill-list li {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ffff;
  border-radius: 50px;
  margin-right: 20px;
  padding: 5px 20px 5px 20px;
  margin-top: 15px;
}

.year-design {
  font-size: 64px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.4px;
  -webkit-text-stroke-color: #ffff;
  font-weight: bold;
}

@media (max-width: 526px) {
  footer .Nav_Portfolio ul {
    flex-wrap: unset;
    flex-direction: column;
    justify-content: center;
    row-gap: unset;
  }

  footer .Nav_Portfolio ul li {
    justify-content: center;
  }

  .year-design {
    font-size: 32px;
  }
}

.portfolio-item-box {
  box-shadow: 2px 0px 6px 2px;
  border-radius: 8px;
  border: 1px solid white;
}

.portfolio-item-circle {
  box-shadow: 2px 2px 20px 0px;
  border-radius: 50px;
  border: 1px solid white;
}

.education-list ul li {
  /* display: flex; */
  margin-bottom: 30px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid white;
  list-style: none;
  box-shadow: 2px 0px 6px 2px;
}

.university-Name-portfolio {
  font-size: 22px;
  font-weight: bold;
}

/* .education-logo {
  height: 70px;
  object-fit: contain;
  width: 70px;
} */

.education-logo {
  height: 78px;
  object-fit: cover;
  width: 78px;
  padding: 4px;
}

.margin-left-50px {
  margin-left: 50px;
}

/* experience */
.experience-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: white;
  position: relative;
}

.experience-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.experience-item {
  gap: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-inline: 50px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid white;
  box-shadow: 2px 0px 6px 2px;
}

.experience-left {
  display: flex;
  align-items: center;
  flex: 1;
  width: 40%;
  max-width: 40%;
}

.experience-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.experience-text h3 {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
}

.experience-text h4 {
  margin: 5px 0;
  font-size: 18px;
}

.experience-right {
  flex: 1;
  /* padding-left: 20px; */
}

.experience-right p {
  font-size: 18px;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  margin-top: unset;
}


@media (max-width: 526px) {
  .experience-left {
    align-items: flex-start;
    width: unset;
    max-width: unset;
  }

  .experience-icon {
    margin-top: 10px;
  }

  .experience-item {
    margin-inline: 20px;
  }

  .university-Name-portfolio {
    font-size: calc(1rem + 2px);
  }

  .experience-text h3 {
    font-size: calc(1rem + 2px);
  }
  
  .experience-text h4 {
    font-size: 1rem;
  }

  .experience-right p {
    font-size: 1rem;
  }
}

/* project */

.project-container {
  padding: 20px;
  color: white;
  margin-inline: 50px;
}

.project-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: white;
  position: relative;
}

.project-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background-color: #00aaff;
  margin-top: 10px;
}

.project-period {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: white;
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.project-item {
  padding: 20px;
  border-radius: 10px;
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
  max-width: calc(33.333% - 20px);
}

.project-item-1 {
  padding: 20px;
  border-radius: 10px;
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  max-width: calc(50% - 20px);
  border-radius: 8px;
  border: 1px solid;
  box-shadow: 2px 0px 6px 2px;
}

.project-item img {
  width: 100%;
  border-radius: 10px;
}

.project-item h3 {
  margin: 10px 0;
  font-size: 1.2em;
  color: white;
  text-align: left;
}

.project-item-1 h3 {
  font-size: 1.2rem;
  margin: 8px 0;
  font-weight: bold;
}

.project-item p {
  font-size: 1em;
  color: white;
}

@media (max-width: 526px) {
  .project-container {
    margin: unset;
    padding: 20px 2rem;
  }

  .project-item-1 {
    max-width: unset;
  }

  .project-item-1 h3 {
    font-size: calc(1rem + 2px);
  }
}

/* Services (Portfolio) */
.portfolio-service-left {
  flex: 1 1 55%;
  /* width: 60%; */
}

.portfolio-service-right {
  flex: 0 0 45%;
  /* width: 40%; */
}

/* last min */
.contact-info {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin: 40px 0;
}

.contact-item {
  text-align: center;
  /* width: 150px; */
  max-width: 200px;
}

@media (max-width: 526px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
  }
  
  .contact-item {
    width: 100%;
    max-width: 300px;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #38B6FF
  }
}

.contact-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.contact-item h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.contact-item p {
  font-size: 1em;
  margin: 5px 0;
}

.contact-item a {
  color: unset;
}

#qr-code-ref {
  border-radius: 6px;
  overflow: hidden;
}

.contact-style-portfolio {
  font-size: 54px;
  font-weight: bold;
}

.contact-style-subtitle {
  font-size: 18px;
  font-style: italic;
  color: #38b6ff;
}

.social-links-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px 40px;
  padding: 20px;
  border: 1px solid;
  border-radius: 8px;
  box-shadow: 2px 0px 6px 2px;
  width: fit-content;
}

@media (max-width: 526px) {
  .contact-style-portfolio {
    font-size: 40px;
  }

  .social-links-container {
    justify-content: space-around;
  }
}

.footer-portfolio {
  position: absolute;
  bottom: 10px;
}

.footer-experience {
  position: relative;
  bottom: 0;
}

.subtitle_services_section {
  color: #00000099;
}

.inputBox-72px-width {
  width: 72px;
  height: 32px;
  border: 1px solid #0000001f;
}

li.custom-item:hover {
  color: #0044c6;
}

.design-lighter-textNew {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  color: #00000099;
}

.project-profile {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.Documents-Attachments {
  width: 40%;
}

.list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  height: 100px;
}

.list-avatar {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-right: 6%;
  margin-left: 6%;
}

.list-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  /* object-fit: contain; */
}

.list-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.list-name {
  display: flex;
  font-weight: bold;
}

.list-email {
  color: gray;
  margin-right: 10%;
}

.list-role {
  color: gray;
  /* width: 140px; */
}

.ml-12 {
  margin-left: 12px;
}

.header-row {
  display: flex;
  align-items: center;
  /* Align items vertically */

  padding: 10px;
  /* Optional: Padding for spacing */
}

.name-email-details {
  width: 180px;
}

.list-date {
  width: 140px;
}

.list-actions {
  width: 140px;
}

.avatar-box-connect {
  justify-content: center;
  width: 160px;
  height: 215px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #00000014;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
}

.connect-button {
  width: 72px;
  height: 16px;
  background: none;
  border: none;
  color: var(--Primary-Color, #0044c6) !important;
  cursor: pointer;
  text-decoration: none;
  /* Remove underline */
  padding: 0;
  margin-top: 20px;
  margin-left: 10px;
  font-size: 14px;
  /* Adjust font size if needed */
  outline: none;
  /* Remove outline on focus */
  font-family: Roboto;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
}

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.line-break {
  white-space: pre-line;
}

/* 29-06 */
.video-resume-wrapper {
  width: 97%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9;
  margin: 20px;
  padding: 20px;
  min-height: 613px;
  border: 1px solid #0000002e;
  border-radius: 5px;
}

.custom-stepper-videoResume .MuiStepLabel-vertical {
  padding: 0;
}

.custom-stepper-videoResume .css-1vyamtt-MuiStepLabel-labelContainer {
  margin-left: 60px;
}

.custom-stepper-videoResume .MuiStepIcon-root.Mui-completed {
  color: #0044c6;
  border: unset !important;
}

.Video-Resume-steps-container {
  width: 30%;
  margin-top: 50px;
  margin-left: 20px;
}

.video-reading-part {
  width: 70%;
  margin-top: 50px;
  box-shadow: 0px 0px 2px 0px #919eab33;
  position: relative;
  padding: 5px;
  border-radius: 8px;
}

.recording-font {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
}

.video-recording-left-part {
  width: 50%;
}

.recording-section-LeftPart {
  width: 100%;
  /* height: 302px; */
  border-radius: 8px;
  border: 2px solid #0000006b;
  background-color: rgba(0, 0, 0, 0.7);
}

.video-recording-progress {
  width: 100%;
  /* height: 171px; */
  border-radius: 8px;
  background-color: #f8f8f8;
  padding: 8px 0;
  /* 366px */
}

.resume-progrss-subBox {
  background-color: #fff;
  width: calc(100% - 32px);
  /* height: 139px; */
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px #919eab33;
  font-family: Lato;
  font-size: 14px;
  font-style: italic !important;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}

.min-widthBigScreen {
  width: 289px;
}

.resume-progrss-subBox span {
  border-bottom: 1px solid #dde0e6;
}

.content-for-videoRecording {
  box-shadow: 0px 12px 24px 0px #919eab1f;
  width: 100%;
  height: 433px;
  border-radius: 8px;
  border: 1px;
  text-align: left;
  position: relative;
}

.tick-symbol {
  font-size: 24px;
  color: green;
  font-weight: bold;
  line-height: 1;
}

.content-for-CurrentTitle {
  height: 75%;
  overflow: auto;
  overflow-x: hidden;
}

.icon-alignment-card-video {
  z-index: 2;
}

.icon-alignment-card-video .icon-border {
  border-radius: 50%;
  border: 1px solid rgba(0, 68, 198, 1);
  background-color: #fff;
  width: 32px;
  height: 32px;
}

.icon-alignment-card .icon-border .icon-color {
  margin: 4px;
}

.line-blue-videoResume {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 68, 198, 1);
}

.icon-alignment-card .icon-border-video {
  border-radius: 50%;
  border: 1px solid rgba(0, 68, 198, 1);
  background-color: #fff;
  width: 32px;
  height: 32px;
}

.button-transparent {
  background-color: transparent;
}

.place-button-bottom-video {
  position: absolute;
  bottom: 20px;
  width: 85%;
}

.media-recorder-videoResume {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.controls-video-resume {
  position: absolute;
  bottom: 15px;
  z-index: 999;
  width: 100%;
}

.controls-video-resume button {
  padding: 0;
  background-color: transparent;
}

.controls-video-resume button img {
  background-color: transparent;
}

.left-right-VideoResume {
  position: absolute;
  top: 5px;
  left: -11px;
  z-index: 10;
  box-shadow: 0px 4px 4px 0px #00000026;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.arrow-item-div {
  width: 24px;
  height: 24px;
  background-color: #0044c6;
  border-radius: 4px;
}

.Increase_ActiveStepArrows {
  margin-top: 15px;
}

.bg-videoResume-Screen {
  width: 100%;
  background-color: #172c5780;
  padding: 10px;
}

.video-resume-bigScreen {
  width: 95%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9;
  margin: 20px;
  padding: 20px;
  min-height: 767px;
  border: 1px solid #0000002e;
  border-radius: 5px;
}

.bigScreen-left-part {
  width: 65%;
}

.video-screen {
  width: 100%;
  /* height: 510px; */
  border-radius: 8px;
  border: 2px solid;
  background-color: rgba(0, 0, 0, 0.7);
}

/* 02-07-24 */
.resume-progress-Overalll {
  /* replacement */
  background-color: #0044c61a;
  border-radius: 4px;
  padding: 9px;
}

.bigScreen-right-part {
  width: 35%;
}

.second-box-bigScreen {
  box-shadow: 0px 12px 24px 0px #919eab1f;
  border: 1px solid #0000001f;
  width: 100%;
  height: 91%;
  gap: 0px;
  border-radius: 8px;
  margin-top: 10px;
  text-align: left;
  position: relative;
}

.Continue-Button-BigScreen {
  width: 97%;
  margin-top: 20px;
}

.resume-progress-first {
  border-radius: 4px;
  padding: 9px;
}

.media-recorder-videoResume video {
  width: 100%;
}

.arrow-item-div.disabled {
  background-color: #0044c687;
}

.Video-Resume-Small-Controls {
  width: 21px;
  height: 21px;
}

.Video-Resume-LargeScreen-Controls {
  width: 36px;
  height: 36px;
}

.link-design-professional {
  border-bottom: 1px solid #0044c6;
}

.VoiceResume-Steppers .css-1hv8oq8-MuiStepLabel-label {
  font-size: 16px;
  font-weight: bold;
  line-height: 19.2px;
}

.voice-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* Adjust based on your design */
  margin-top: 10px;
  /* Adjust based on your design */
}

.voice-indicator {
  width: 20px;
  /* Adjust based on your design */
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.voice-indicator-bar {
  width: 100%;
  background-color: green;
  transition: height 0.1s;
  position: absolute;
  bottom: 0;
}

.v-hidden {
  visibility: hidden !important;
}

.figma-icon path {
  fill: #0044c6;
}

/* Contact Styles -t */
.star-icon.t {
  /* position: absolute;
  top: 16px; */
  right: 16px;
  color: gray;
}

.avatar-box.t {
  justify-content: center;
}

.avatar-box.t.compact {
  height: 210px;
}

/* 04-07 */
.Search-add-people {
  width: 50%;
  text-align: left;
}

.searchBox-addPeople {
  width: 100%;
}

.textfield-new-styles .css-igs3ac {
  border-width: 0px;
  border: none;
}

/* replacement */
.lightbox-content-2 {
  position: relative;
  width: 80%;
  max-width: 800px;
  max-height: 90%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  padding: 0px 20px 20px 20px;
}

.probar-content {
  text-align: left;
  padding: 12px;
}

.avatar-details.t {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.star-icon.t {
  position: absolute;
  top: 16px;
  right: 16px;
  color: gray;
}

.avatar-image.t {
  margin-left: unset;
  margin-right: unset;
}

.avatar-box.t {
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: unset;
  padding: unset;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
}

.avatars-container .avatar-box-outer:not(.compact) {
  width: unset;
  height: 260px;
}

.avatar-box.t .avatar-details-wrapper {
  height: 100%;
  padding: 12px;
  position: relative;
  width: 195px;
  /* height: 258px; */
  overflow: hidden;
}

.member-email{
  width: 195px;
  padding: 4px;
}

.avatar-box-outer:not(.compact) .icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #0000002e;
  position: unset;
}

.avatar-box-outer:not(.compact) .icon-box {
  width: unset;
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: unset;
  border-radius: unset;
  padding: 14px 20px;
}

.avatar-box-outer:not(.compact) .icons-container .icon-box:not(:last-child) {
  border-bottom: 1px solid #0000002e;
}

.avatar-box-outer:not(.compact) .icon {
  margin-left: unset;
  height: 24px;
  width: 24px;
}

@media (min-width: 1300px) {
  .avatar-box.t:not(.compact) .avatar-details {
    min-width: 200px;
  }
}

.avatar-box-outer:not(.compact) {
  display: flex;
  align-items: center;
}

.avatar-box-outer:not(.compact) .overflow-menu.icons-container {
  border: 1px solid #00000014;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  margin-left: 6px;
  position: unset;
}

.avatar-box.t.compact {
  width: 144px;
  height: 160px;
}

.avatar-box.t.compact .avatar-details-wrapper {
  position: unset;
  align-items: flex-start;
}

.avatar-box.t.compact .avatar-details.t {
  padding-top: 10px;
}

.avatar-box.t.compact .avatar-details.t .member-name {
  padding-top: 8px;
}

.avatar-box.t.compact .avatar-image.t,
.smaller-screen-avartar {
  height: 56px;
  width: 56px;
}

.contact-groups {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* Adjust the gap between group cards */
  position: relative;
}

.group {
  position: relative;
}

.group-link {
  text-decoration: none;
}

.person-box {
  background: rgba(0, 68, 198, 0.08);
  height: auto;
  width: 144px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  position: relative;
}

.person-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  /* height: 100%; */
  min-height: 84px;
}

.person-grid.select-all {
  margin-top: 20px;
}

.person-image {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.person-box .group-checkbox.MuiCheckbox-root {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px;
}

.person-details {
  margin-top: 8px;
  font-size: 14px;
  color: #00000099;
}

.group-options-bar {
  position: absolute;
  top: 0;
  right: -40px;
  width: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  transition: 0.4s right ease;
  color: #0044c6;
  border-radius: 8px 0 0 8px;
}

.group-options-bar .group-option-box {
  height: 42px;
  width: 42px;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
}

.group-options-bar .group-option-box svg {
  min-height: 24px;
  max-height: 24px;
  min-width: 24px;
  max-width: 24px;
}

.list-item-container {
  height: 100%;
  transition: height 0s ease;
  margin-bottom: 16px;
}

.list-item-container.expanded {
  height: 174px;
}

.list-item.expanded,
.list-item:hover {
  border: 1px solid #0044c6;
  box-shadow: 0px 4px 18px 0px #0044c633;
}

.additional-info {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  background-color: #ffffff;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 0 0 8px 16px;
  flex-direction: row;
  padding: 16px 24px 16px 32px;
}

.w-100pc {
  width: 100% !important;
}

.h-100pc {
  height: 100% !important;
}

/* 15-07-24 */
.person-details-2 {
  font-size: 12px;
  color: #00000099;
}

.floating-dropdown-contacts .css-12w19fr-MuiSpeedDialAction-staticTooltip,
.MuiSpeedDialAction-staticTooltipLabel,
.floating-customised-style {
  text-wrap: nowrap;
  min-width: 140px;
  text-align: center;
  color: #0044c6 !important;
}

.floating-customised-style.selected .MuiSpeedDialAction-staticTooltipLabel {
  color: #ffff !important;
}

.floating-dropdown-contacts
  .MuiButtonBase-root.MuiFab-root.MuiSpeedDialAction-fab,
.floating-dropdown-contacts .MuiButtonBase-root.MuiFab-root {
  width: 48px !important;
  height: 48px !important;
  background-color: #ffff;
}

.floating-dropdown-contacts svg {
  color: #0044c6;
}

.floating-dropdown-contacts .css-40gmyf-MuiButtonBase-root-MuiFab-root:hover {
  box-shadow: 0px 4px 4px 0px #0044c647;
  background-color: #fff;
}

.dot_status_contacts {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 12px;
}

.symbol_of_contacts {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  top: -35px;
  right: -75px;
}

.symbol_of_contacts2_list {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
}

.symbol_of_contacts2_list svg {
  width: 20px;
  height: 20px;
}

.symbol_of_contacts.compact {
  width: 17px;
  height: 17px;
  top: -25px;
  right: -45px;
}

.symbol_of_contacts.compact svg {
  width: 17px;
  height: 17px;
}

.floating-dropdown-contacts.MuiBox-root {
  height: unset;
}

.About_professional_Part {
  min-width: 150px;
  height: 24px;
  background-color: #74b037;
  color: #fff;
  border-radius: 60px;
}

.myProfile-imageLeftSide_New {
  width: 182px;
  height: 206px;
  position: absolute;
  top: 130px;
  left: 90px;
}

.My-Profile-imgOutline {
  width: 182px;
  height: 182px;
  position: absolute;
  top: 130px;
  left: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.My-Profile-imgOutlineView {
  width: 182px;
  height: 182px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

@media (max-width: 1200px) {
  .My-Profile-imgOutline {
    height: 152px;
    width: 152px;
    top: 152px;
    left: 16px;
  }

  .My-Profile-Scanner-image {
    right: 16px;
  }
}

.sidefeed-box {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  padding: 8px;
  color: rgb(0, 0, 0, 0.6);
  /* height: 232px; */
  position: relative;
}

.sidefeed-box.profile-visiting-card,
.sidefeed-box.profile-offers-box {
  padding: unset;
}

.profile-visiting-card .qr-button {
  border-radius: 50%;
  border: 1px solid #0044c6;
  padding: 5px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.profile-visiting-card .dialogue-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.profile-visiting-card .dialogue-overlay .qr-close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  color: white;
}

.profile-visiting-card .Scanner-outline-preview {
  width: fit-content;
  height: fit-content;
  min-height: unset;
  background-color: white;
  margin: unset;
  border-radius: unset;
}

.profile-visiting-card .Displaying-QR {
  padding: 2px;
}

.profile-visiting-card .visiting-card-upper {
  padding: 38px calc(16% + 8px) 12px 1.5rem;
  display: flex;
  gap: 12px;
  align-items: center;
}

.profile-visiting-card .visiting-card-lower {
  padding-bottom: 24px;
}

.profile-visiting-card .visiting-card-upper .MuiAvatar-root {
  height: 5.5rem;
  width: 5.5rem;
}

.visiting-card-upper .company-logo-container {
  position: absolute;
  right: 2rem;
  bottom: -5rem;
}

.visiting-card-upper .company-logo-div {
  position: relative;
  border-radius: 50%;
  background-color: white;
  height: 4.5rem;
  width: 4.5rem;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  overflow: hidden;
}

.visiting-card-upper .company-logo-div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.visiting-card-upper .company-logo-container .visiting-card-type {
  font-size: 12px;
  color: #0044c6;
  line-height: 13.2px;
}

.visiting-card-upper .visiting-card-name {
  font-size: 1.25rem;
  color: rgb(0, 0, 0, 0.87);
  line-height: 24px;
  font-weight: 700;
}

.visiting-card-upper .circle-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: white;
}

.visiting-card-lower .quick-link-container {
  display: flex;
  padding: 2rem 1rem 0;
}

.visiting-card-lower .quick-link-container .icon-outside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.visiting-card-lower .quick-link-container .quick-link-icon {
  width: unset;
  margin: 0 0.5rem;
}

@media (max-width: 1200px) {
  .profile-visiting-card .qr-button {
    top: 0.5rem;
    right: 0.5rem;
  }

  .visiting-card-upper .company-logo-container {
    right: 1rem;
    bottom: -4.5rem;
  }

  .visiting-card-upper .company-logo-div {
    height: 3.5rem;
    width: 3.5rem;
  }

}

.sidefeed-box.job-openings-box,
.sidefeed-box.social-network-box,
.sidefeed-box.recomm-courses-box {
  padding: 1.5rem;
}

.sidefeed-box.upcoming-events-box {
  padding: unset;
  padding-top: 1.5rem;
}

.job-openings-box .job-opening-container {
  border: 1px solid #0000001f;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 12px;
}

.job-opening-container .job-opening-left {
  flex: 0 0 32%;
}

.job-opening-container .job-opening-right div {
  margin-bottom: 4px;
  font-size: 12px;
}

.job-opening-container div.job-title,
.upcoming-events-box .event-details-box .event-title,
.recomm-courses-box .course-type {
  font-weight: 700;
  font-size: 16px;
  color: #0044c6;
}

.sidefeed-box .MuiButton-root.MuiButton-text {
  color: #0044c6;
}

.job-opening-left .MuiButton-root.MuiButton-text {
  color: #0044c6;
  padding-bottom: 0;
}

.sidefeed-box.upcoming-events-box .sidefeed-titles {
  border-bottom: 1px solid #0000001f;
}

.sidefeed-box.upcoming-events-box .upcoming-event {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.upcoming-events-box .upcoming-event .event-date-box-container {
  flex: 0 0 24%;
  border-radius: 8px;
  background-color: #0044c6;
  color: white;
  font-size: 1.5rem;
  padding: 10px;
}

.upcoming-events-box .upcoming-event .event-date-box-container .event-date-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.event-date-box .event-date .event-date-number {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: -0.75rem;
}

.upcoming-events-box .event-details-box {
  font-size: 14px;
}

.recomm-courses-box .course-container {
  border: 1px solid #0000001f;
  border-radius: 4px;
  display: flex;
  gap: 16px;
}

.recomm-courses-box .course-container .course-cover-pic {
  flex: 0 0 30%;
  /* height: 100%; */
  width: 100%;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.recomm-courses-box .course-container .course-cover-pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.recomm-courses-box .course-container .course-details {
  padding: 24px 16px 24px 0;
  text-align: left;
}

@media (max-width: 1200px) {
  .recomm-courses-box .course-container .course-cover-pic {
    display: none;
  }

  .recomm-courses-box .course-container .course-details {
    padding: 24px 16px;
  }
}

.lightbox-content-2.profile-lightbox {
  scrollbar-width: thin;
}

.lightbox-content-2.profile-lightbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.lightbox-content-2.profile-lightbox::-moz-scrollbar-thumb {
  border-radius: 10px;
}

.profile-verify-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.green-verified-tick path {
  fill: #4caf50;
}

.prof-profile-tab-item {
  display: flex;
  gap: 1.5rem;
  font-size: 14px;
}

.prof-profile-tab-item .institute-logo-container {
  margin-top: 4px;
}

.prof-profile-tab-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--secondary-text);
}

.prof-profile-tab-actions svg.icon-color {
  margin-right: 5px;
}

.prof-profile-tab-actions .MuiFormControlLabel-root,
.pers-profile-tab-actions .MuiFormControlLabel-root {
  margin-right: 0;
  margin-left: 4px;
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked,
.MuiSwitch-root {
  color: #0044c6 !important;
}

.prof-profile-tab-actions .icon-new,
.prof-profile-tab-item .icon-new {
  margin-top: unset;
}

.prof-profile-tab-actions .sort-icons-cont,
.prof-profile-tab-item .sort-icons-cont {
  margin: unset;
}

.prof-profile-tab-item .design-lighter-text {
  width: fit-content;
}

.digi-card-new .preview-card .quick-link-containerNew {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 12px 16px 8px;
  width: 100%;
}

.place-at-bottom {
  position: absolute;
  bottom: 65px;
}

.preview-card .previewcardNamePadding {
  padding: 0 8px !important;
}

.prof-profile-tab-item .experience-info > div {
  margin: unset;
  margin-bottom: 6px;
}

.contain-image {
  height: 100%;
  width: 75%;
  object-fit: contain;
}

.cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contain-image-100 {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.custom-profile-tabs .MuiTabs-indicator {
  height: 5px;
  border-radius: 10px 10px 0px 0px;
  background: #0044c6;
}

.custom-profile-tabs .MuiTab-root {
  text-transform: unset;
  font-size: 1rem;
  color: #0044c6;
  font-weight: 500;
}

.custom-profile-tabs .MuiTab-root.Mui-selected {
  color: #0044c6;
  font-weight: 700;
}


.custom-home-tabs .MuiTabs-indicator {
  height: 5px;
  /* width: 100px; */
  border-radius: 10px 10px 0px 0px;
  background: #0044c6;


}

.custom-home-tabs .MuiTab-root {
  text-transform: unset;
  font-size: 1rem;
  color: #0044c6;
  font-weight: 500;
   padding-left: 20px;
   padding-right: 20px;
}

.custom-home-tabs .MuiTab-root.Mui-selected {
  color: #0044c6;
  font-weight: 700;
  padding-left: 30px;
}

.view-options-menu .menu-title {
  color: var(--primary-text);
  font-weight: 600;
}

.view-options-menu .menu-subtitle {
  color: var(--secondary-text);
  font-size: 12px;
}

.profile-popup-menu.view-options-menu .MuiList-root {
  padding-top: unset;
  padding-bottom: unset;
}

.view-options-menu .view-options-checkboxes {
  border-radius: 8px;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33;
  padding: 8px 12px;
}

.view-options-menu .view-options-checkboxes .view-options-checkbox-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-options-checkbox-item .Mui-checked.MuiCheckbox-root {
  color: #0044c6;
}

.myprofile-column-1 .MuiSelect-select.MuiInputBase-input {
  text-align: left;
}

@media (max-width: 1200px) {
  .profile-visiting-card .visiting-card-upper {
    padding-right: 1.5rem;
    gap: 6px;
  }

  .profile-visiting-card .qr-button {
    top: 0.5rem;
    right: 0.5rem;
    padding: 2.5px;
  }

  .profile-visiting-card .qr-button svg {
    height: 26px;
    width: 26px;
  }

  .profile-visiting-card .visiting-card-upper .MuiAvatar-root {
    height: 4rem;
    width: 4rem;
  }

  .visiting-card-upper .visiting-card-name {
    font-size: 1rem;
    line-height: unset;
  }

  .visiting-card-upper .company-logo-container {
    right: 2px;
    bottom: -4.5rem;
  }

  .visiting-card-upper .company-logo-div {
    height: 3.5rem;
    width: 3.5rem;
  }

  .profile-visiting-card .visiting-card-lower {
    padding-bottom: 32px;
  }

  .visiting-card-lower .quick-link-container .icon-outside {
    height: 26px;
    width: 26px;
  }

  .visiting-card-lower .quick-link-container .icon-outside svg {
    height: 18px;
    width: 18px;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lightbox-content-2 .about-me-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-text);
}

.settings-title {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}

.settings-description {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #1c1c1c66;
  margin-bottom: 8px;
}

.delete-button_configuration.MuiButtonBase-root {
  padding: 8px 6px 8px 8px;
  border-radius: 4px;
  background-color: #eb4e3d !important;
  color: #fff;
  letter-spacing: 1.5px;
}

.plus-icon svg {
  width: 35px;
  height: 35px;
}

.preview-card .batch-logo-container {
  position: absolute;
  border-radius: 50%;
  height: 72px;
  width: 72px;
  background-color: white;
  right: 1rem;
  bottom: -1rem;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  overflow: hidden;
  z-index: 1;
}

.preview-card .allcards-batch {
  width: 52px;
  height: 52px;
  bottom: -10px;
}

.preview-card .batch-logo-container img {
  width: 100%;
  height: 100%;
  object-fit: 100%;
}

.profile-modal-heading1 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 19.2px;
  color: var(--primary-text);
}

.fade-modal-content {
  position: relative;
  padding-bottom: 60px;
  /* Extra space for the fixed button */
}

.fade-out::after {
  content: "";
  position: absolute;
  bottom: 60px;
  /* Same height as the fixed button */
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  pointer-events: none;
}

.fixed-save-button {
  position: sticky;
  bottom: 0px;
  width: 100%;
  z-index: 98;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: #0044c6 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0044c6 !important;
}

.MuiSwitch-root .Mui-checked + .MuiSwitch-track {
  background-color: rgba(0, 68, 198, 0.38) !important;
  opacity: 1 !important;
}

.MuiSwitch-root .MuiSwitch-track {
  margin-left: 2px;
  margin-right: 2px;
}

.currency-field .MuiPaper-root {
  max-height: calc(68% - 96px);
}

.currency-field {
  font-size: 12px;
}

svg.size-20px {
  height: 20px;
  width: 20px;
}

.MuiList-padding.add-services-list {
  padding: 4px 0;
}

.add-services-list .MuiListItem-padding {
  padding: 8px 4px 8px 12px;
}

.modal-close-button.MuiIconButton-root {
  position: absolute;
  top: 16px;
  right: 16px;
}

.profile-modal-title-content {
  border-bottom: 1px solid #dde0e6;
}

.modal-link-field.MuiTextField-root {
  min-width: 28rem;
}

.AddSectionAccordians .custom-accordion .MuiAccordionSummary-root.Mui-expanded {
  background-color: #0044c6 !important;
  color: white;
}

.AddSectionAccordians .custom-accordion.MuiPaper-elevation1 {
  box-shadow: none;
}

.recommenders-list.MuiList-root {
  margin-top: 2px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #00000033, 0px 1px 10px 0px #0000001f,
    0px 4px 5px 0px #00000024;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: thin;
}

.disabled-text {
  color: var(--disabled-text);
}

.family-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 in each row */
  gap: 20px; /* Space between cards */
  align-items: stretch;
}

@media(max-width: 1250px) {
  .family-grid-container {
  grid-template-columns: repeat(2, 1fr); /* 2 in each row */
  row-gap: 0px;
  }
}

@media(max-width: 1150px) {
  .family-grid-container {
  grid-template-columns: unset;
}
}

.profile-family-block {
  margin-top: 12px;
  margin-bottom: 20px;
  border: 2px solid #0044c6;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 12px 24px 0px #919EAB1F, 0px 0px 2px 0px #919EAB33;
  /* background: linear-gradient(45deg, #242450 50%, #263777); */
  background:#0044c6;
  color: white !important;
  display: flex;
  flex-direction: column;
}

.profile-family-block .user-details-container {
  flex-grow: 1;
}

.family-block-contents {
  height: 100%;
  background-color: white;
  border-radius: 8px;
  color: var(--primary-text);
  padding: 8px;
}

.family-data-item {
  margin-bottom: 6px;
  font-size: 14px;
}

.family-block-contents .family-title {
  font-weight: 600;
  margin-bottom: 2px;
}

.digital_identity_Carousel {
  position: relative;
  width: 90%;
}

.digital_identity_Carousel .MuiSvgIcon-fontSizeMedium.slick-prev,
.digital_identity_Carousel .MuiSvgIcon-fontSizeMedium.slick-next {
  color: #0000008a;
}

.digital_identity_Carousel .slick-slide.slick-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-modal-close.MuiIconButton-root {
  position: absolute;
  top: 16px;
  right: 16px;
}

.icon-verify-symbol svg {
  width: 20px;
  height: 20px;
}

.thin-lightbox {
  width: 60%;
}

@media (min-width: 1400px) {
  .thin-lightbox {
    width: 40%;
  }
}

.MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
  color: #0044c6;
}

.MuiCheckbox-root.MuiButtonBase-root.Mui-checked.Mui-disabled {
  color: #0044c6bb;
}

.lightbox-content.delete-lightbox {
  text-align: left;
  min-width: 35%;
  padding: unset;
}

.icon-20px svg {
  height: 20px;
  width: 20px;
}

.BusinessCard_Size {
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  width: 388px;
  height: 220px;
  overflow: hidden;
}

.checkbox-container.MuiFormControlLabel-root {
  margin-left: 4px;
  margin-right: 0;
}

.awards-form > div {
  margin-bottom: 1rem;
}

.professional-page-section .form-display-title,
.about-me-personal .form-display-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.profile-form-display {
  position: relative;
}

.profile-form-display .form-display-edit {
  position: absolute;
  top: -8px;
  right: 0;
  margin-bottom: 0;
}

.profile-form-display .form-display-edit:hover {
  color: #0044c6;
}

.profile-form-display > div {
  margin-bottom: 1rem;
}

.nav-tab-professionals .MuiButtonBase-root.MuiTab-root {
  padding: 8px 16px;
  color: #0044c6 !important;
  opacity: 0.8 !important;
}

.nav-tab-professionals .MuiButtonBase-root.MuiTab-root.Mui-selected {
  padding: 8px 16px;
  color: #0044c6 !important;
  font-weight: bold;
  opacity: 1 !important;
}

.delete_Card_Button {
  background-color: #eb4e3d !important;
  color: #ffff;
}

.verify-button.success-green.MuiButton-root.MuiButton-contained.Mui-disabled {
  background-color: #43a047;
  color: white;
}

/* .color-picker-field {
  border: none;
  outline: none;
}

.color-picker-input {
  border: none;
  outline: none;
  box-shadow: none;
} */

.icon-color-picker {
  background-color: transparent;
}

.form-control-custom {
  height: 48px;
}

.text-field-custom {
  height: 48px;
}

.text-field-custom .MuiInputBase-root {
  height: 100%;
  display: flex;
  align-items: center;
}

.text-field-custom .MuiInputBase-input {
  padding: 10px 14px;
  height: 100%;
  box-sizing: border-box;
}

.text-field-custom .MuiSelect-select {
  padding-top: 0;
  padding-bottom: 0;
}

.qr-outline-mobile {
  width: 250px;
  height: 250px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border: 1px solid #00000014;
  border-radius: 8px;
}

.width250px {
  width: 250px;
}

.add-project-modal .Documents-Attachments {
  width: 75%;
}

.profile-project-display .Documents-Attachments {
  width: 50%;
}

@media (max-width: 1200px) {
  .profile-project-display .Documents-Attachments {
    width: unset;
  }
}

.primary-text {
  color: var(--primary-text);
}

.secondary-text {
  color: var(--secondary-text);
}

.resume-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.resume-container .profile-resume {
  margin: 24px auto;
  width: 72%;
  /* height: 500px; */
  border: 1px solid aquamarine;
  display: flex;
  flex-direction: row;
  font-size: 10px;
}

@media (max-width: 1330px) {
  .resume-container .profile-resume {
    width: unset;
  }
}

.profile-resume .resume-side-content {
  flex: 0 0 30%;
  background-color: #f7f9fc;
  padding: 20px;
}

.profile-resume .resume-main-body {
  flex: 0 0 70%;
  background-color: #ffffff;
  padding: 24px 16px 24px 16px;
  position: relative;
}

/* 
.resume-main-body-wrapper {
  border-left: 1px solid #E2E6EE;
  padding-left: 16px;
} */

.resume-side-content .resume-header .resume-profile-container {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  overflow: hidden;
}

.resume-profile-name {
  font-size: 1rem;
  font-weight: 700;
}

.resume-profile-headline {
  font-size: 12px;
  font-weight: 700;
  color: var(--secondary-text);
}

.resume-side-content .resume-quote {
  font-size: 10px;
}

.resume-side-content .resume-quote-speaker {
  font-size: 8px;
  color: var(--secondary-text);
}

.profile-resume .resume-divider {
  background-color: #edf0f5;
  height: 1px;
  width: 100%;
  margin: 1rem 0;
}

.profile-resume .resume-contact-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.resume-contact-list a {
  text-decoration: none;
  color: unset;
}

.resume-contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.resume-contact-icon-container {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: #e2e6ee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-contact-icon {
  color: #79819a;
  height: 16px;
  width: 16px;
}

.resume-contact-label {
  font-size: 8px;
  color: #79819a;
}

.resume-contact-id {
  font-size: 10px;
  color: var(--primary-text);
}

.resume-socials-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4 icons per row */
  gap: 10px;
  /* Adjust gap between icons */
}

.resume-social-icon {
  height: 20px;
  width: 20px;
}

.resume-section-title::before {
  content: "•";
  height: 1rem;
  width: 1rem;
  background-color: white;
  position: absolute;
  left: -24px;
  top: 0;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 0px #0000000a inset, 0px 6px 24px 0px #0000000a,
    0px 1px 4px 0px #0000000d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: purple;
  font-size: 7.5px;
}

.resume-main-body-wrapper .resume-main-section {
  padding-bottom: 20px;
  padding-left: 16px;
}

.resume-main-body-wrapper .drag-item:not(:last-child) .resume-main-section {
  border-left: 1px solid #e2e6ee;
}

.resume-main-section .resume-section-title {
  font-size: 12.5px;
  font-weight: 700;
  color: var(--primary-text);
  margin-bottom: 1rem;
  position: relative;
}

/* .resume-project-section .resume-section-body {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
} */

.resume-project-section .resume-section-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Default to two columns */
  gap: 4px;
}

.resume-section-item.full-width {
  grid-column: 1 / -1;
  /* This makes the item span the full width */
}

.resume-project-section .resume-section-item {
  flex: 0 0 49%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  background-color: #f7f9fc;
  border-radius: 4px;
  overflow: hidden;
  /* max-width: 48%; */
}

.resume-project-title {
  font-size: 10px;
  font-weight: 700;
  color: var(--primary-text);
}

.resume-project-desc {
  font-size: 8.5px;
  color: var(--secondary-text);
}

.resume-url-div {
  font-size: 8.5px;
  line-height: 14px;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.resume-experience-section .resume-section-body {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.resume-experience-section .resume-section-item {
  /* display: flex; */
  justify-content: space-between;
  gap: 6px;
  /* padding: 4px 0 8px; */
  overflow: hidden;
  color: var(--secondary-text);
  /* font-size: 8px; */
  font-size: 9px;
  padding: 8px 0;
}

.resume-experience-section .resume-section-item:not(:last-child) {
  border-bottom: 0.7px solid lightgray;
}

.resume-exp-location {
  display: inline-flex;
  align-items: center;
}

.resume-exp-icon {
  height: 10px;
  width: 10px;
  margin-right: 2px;
}

.resume-exp-content-left .exp-time-location {
  margin-bottom: 0.25rem;
  padding-left: 12px;
  position: relative;
}

/* 
.resume-exp-content-left .exp-time-location::before {
  content: '';
  height: 4px;
  width: 4px;
  background-color: black;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
} */

.resume-exp-right-info > div {
  margin-bottom: 4px;
}

.exp-time-location-1 {
  display: inline-flex;
  align-items: center;
}

.resume-exp-content-left .exp-name-logo {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-left: 12px;
  border-left: 0.7px solid #f7f9fc;
  margin-left: 1px;
}

.exp-name-logo .resume-exp-logo {
  height: 28px;
  width: 28px;
  overflow: hidden;
}

.resume-exp-logo-1 {
  height: 36px;
  width: 36px;
  overflow: hidden;
}

.resume-exp-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.resume-exp-name .resume-exp-designation {
  color: var(--secondary-text);
  font-size: 10px;
}

.resume-exp-name .resume-exp-company {
  color: var(--primary-text);
  font-size: 10px;
}

.resume-exp-content-left {
  flex: 1 1 45%;
}

.resume-exp-content-right {
  flex: 0 0 55%;
  font-size: 8px;
  color: var(--secondary-text);
  text-overflow: ellipsis;
  overflow: hidden;
}

.resume-exp-details-1 {
  padding-left: 44px;
}

.resume-education-section .resume-section-body {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.resume-education-section .resume-section-item {
  flex: 0 0 32%;
  border-radius: 4px;
  padding: 6px 12px;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 8px;
  max-width: 32%;
}

.resume-section-item .edu-name-logo {
  display: flex;
  align-items: center;
  gap: 4px;
}

.resume-section-item .edu-degree-time .resume-edu-degree {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 1px;
}

.resume-section-item .edu-degree-time .resume-edu-time {
  color: var(--secondary-text);
}

.resume-section-item .edu-name-logo img {
  height: 24px;
  width: 24px;
  object-fit: contain;
  overflow: hidden;
}

.resume-skills-section .resume-section-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.resume-section-body .resume-skill-type {
  flex: 0 0 15%;
  display: flex;
  font-weight: 700;
}

.resume-skill-type.soft-skills-color,
.resume-skill-type.soft-skills-color + .resume-skills-row {
  color: #9251f7;
}

.resume-skill-type.prof-skills-color,
.resume-skill-type.prof-skills-color + .resume-skills-row {
  color: #516cf7;
}

.resume-section-body .resume-skills-row {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.resume-skills-row .resume-section-item {
  border-radius: 4px;
  padding: 6px 4px;
  background-color: #f7f9fc;
  max-width: 70%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.connections_sugg {
  color: var(--secondary-text);
  font-size: 18px;
}

.filter-dropdown {
  width: 350px;
  position: absolute;
  right: 0;
  top: 5px;
  background-color: white;
  z-index: 1;
  border-left: 1px solid #ccc;
  box-shadow: 0px 4px 20px 0px #00000040;
  overflow-y: auto;
  max-height: 570px;
  scrollbar-width: thin;
}

.filter-dropdown h6 {
  margin: 15px 0 5px;
  font-size: 18px;
  font-weight: bold;
}

hr {
  margin: 10px 0;
}

.row .col-md-6 {
  padding-right: 8px;
  padding-left: 8px;
}

.inline-checkbox-group.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  -webkit-flex-direction: row !important;
}

.inline-checkbox-group .MuiFormControlLabel-root {
  margin-right: 10px;
  /* Adjust spacing as needed */
}

/* For Card Flipping Thing - Not Fully Done, Need to do */
.card-container {
  perspective: 1000px;
}

.card-container .card {
  width: 300px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card .card-front,
.card .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .card-back {
  transform: rotateY(180deg);
}

/* Connect Page Designs */
.mini-profile-container {
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 8px;
  min-height: 190px;
}

.Image_Container_Contacts {
  width: 146px;
  height: 146px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 24px;
}

.Image_Container_Contacts img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heading-contacts-20px {
  font-size: 20px;
  font-weight: 600;
}

.heading-contacts-16px {
  font-size: 16px;
  font-weight: 400;
  color: #202224;
}

.sub-heading-contact {
  font-size: 14px;
  color: #00000099;
  font-size: 400;
}

.fixed-width-contactView {
  width: calc(100% - 146px);
}

.bottom-full-ContactView {
  height: 50px;
  width: 100%;
  border-top: 1px solid #0000001f;
}

.full-Screen-View {
  display: flex;
  align-items: center;
  justify-content: start;
}

.full-Screen-View .outerLayer_icon {
  background-color: #0044c6;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.full-Screen-View .outerLayer_icon svg {
  width: 28px;
  height: 28px;
}

.OtherCardsView {
  width: 176px;
  height: 218px;
  border-radius: 8px;
}

.customized_formControl span {
  text-wrap: nowrap;
}

.header-column.select-all {
  width: 20%;
}

.header-column.name-email-details-column {
  width: 30%;
}

.header-column.name-email-details-column {
  width: 30%;
}

.header-column.date {
  width: 10%;
  display: flex;
  justify-content: center;
}

.header-column.actions {
  width: 10%;
  display: flex;
  justify-content: center;
}

/* .icon-20px {
  width: 20px;
  height: 20px;
} */

.action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 58px;
  background-color: #0044c6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: transform 1s ease, opacity 1s ease;
  /* Smooth transition */
  transform: translateY(100%);
  /* Initially off-screen */
  opacity: 0;
  /* Hidden */
}

.action-barBg2 {
  background-color: #0044c647;
}

.action-bar.open {
  transform: translateY(0);
  /* Slide up into view */
  opacity: 1;
  /* Fully visible */
}

.action-bar-content {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.action-buttons {
  display: flex;
  gap: 15px;
}

.action-buttons .MuiIconButton-root {
  color: white;
}

.action-bar.cce-actions {
  height: unset;
  padding: 8px 32px;
}

.sub-heading-style {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.group-card-creation {
  box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33;
  border: 1px solid #0000001f;
  border-radius: 8px;
}

.group-create-addMore {
  border: 1px solid #00000014;
  width: 144px;
  height: 138px;
  position: relative;
}

.member-details-group{
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 150px;
}

.Smaller-Heading-groupMem {
  font-size: 14px;
  font-weight: 600;
}

.sub-heading-style-16 {
  font-size: 16px;
  color: var(--secondary-text);
}

.sub-heading-style-14 {
  font-size: 14px;
  color: #00000099;
}

.groupInfo-details-container {
  min-width: 192px;
  max-width: 250px;
  min-height: 533px;
  max-height: 600px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #0000002e;
  background: #0044c614;
}

.group_image_size {
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}

.parent.selected #SpeedDialtooltipexample-action-3-label,
.floating-customised-style.selected,
.floating-customised-style.selected svg {
  background-color: #0044c6 !important;
  color: #ffff !important;
}

.star_fav_cards {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
}

.drag-item-active .resume-main-section:hover {
  cursor: grab;
}

.drag-item {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.drag-item-active {
  cursor: grab;
}

.drag-item-active.dragging {
  cursor: grabbing;
}

.document-viewer-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.document-viewer-content .hysiap {
  flex: unset;
  width: 100%;
  height: 100%;
  overflow-y: unset;
}

.document-viewer-content #react-doc-viewer a#ControlMenu-Small14, .document-viewer-content #react-doc-viewer a#fseaFullScreen-Small14 {
  display: none;
}

.document-viewer-content .react-pdf__Page__textContent.textLayer {
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
}

.document-viewer-content .react-pdf__Page {
  padding: 24px 0;
}

.document-viewer-content .react-pdf__Page__canvas {
  padding: 10px;
  border: 1px solid rgb(255 18 0 / 44%);
}

.shared-profile-view {
  scroll-margin-block-start: 136px;
}

.shared-profile-view .about-me-personal,
.shared-profile-view .professional-page-section {
  min-height: unset;
}

.shared-profile-view .about-me-personal .form-display-title,
.shared-profile-view .professional-page-section .form-display-title {
  margin-bottom: 1rem;
}

.shared-profile-view .personal-details {
  color: var(--secondary-text);
}

.shared-profile-view .personal-details > div {
  margin-bottom: 1rem;
  display: flex;
  /* align-items: center; */
}

.shared-profile-view .personal-details .MuiGrid-item > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.shared-profile-view .personal-details.health-info > div {
  align-items: unset;
  text-align: left;
}

.shared-profile-view .personal-details .custom-icon {
  margin-right: 6px;
}

.shared-profile-view .personal-details svg.custom-icon path {
  fill: black;
  fill-opacity: 0.54;
}

.shared-profile-view .profile-tab-item-details > div {
  margin-bottom: 6px;
}

.connection-row .MuiListItemText-primary {
  color: var(--primary-text);
}

.connection-row .MuiListItemText-secondary {
  color: var(--secondary-text);
}

.heading-Lightbox-group {
  font-size: 16px;
  font-weight: 600;
}

.group_permissions_subtitle {
  color: #000000de;
  font-size: 14px;
  font-weight: 400px;
}

.group_permissiions_options {
  color: #00000099;
  font-size: 14px;
  font-weight: 400px;
}

.owner_group_det {
  min-height: 74px;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 8px;
  background-color: #1c1c1c14;
  margin: 16px 5px;
}

.typeOfNoti-color {
  color: #ffff;
  border-radius: 2px;
  padding: 0 6px;
}

.Button-grey {
  color: #00000099 !important;
  border: 1px solid #dddee1 !important;
}

.header-search-container {
  display: flex;
  align-items: center;
  justify-content: start;
  /* Center horizontally, if needed */
  height: 100%;
  /* Ensure the container takes up the full available height */
}

.icon {
  display: flex;
  align-items: center;
  /* Align the icon vertically */
}

.icon-svg-24 svg {
  width: 24px;
  height: 24px;
}

.Border_globalSearch {
  height: 1px;
  border: 1px solid #dde0e6;
  background-color: #dde0e6;
}

.leftSide_container_global {
  width: 100px;
  height: 112px;
  position: relative;
}

.verifiedcontainer {
  width: 100px;
  height: 24px;
  border-radius: 38px;
  color: #fff;
  background-color: #4caf50e0;
  position: absolute;
  bottom: 0;
}

.verifiedcontainer .icon-verify-symbol {
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: #ffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.global_search_listitem {
  min-height: 120px;
  display: flex !important;
  align-items: center !important;
}

.card_consent_page_left {
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 8px;
}

.OverflowX {
  overflow-x: auto;
}

.family-group-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tree-node {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.parent-nodes,
.couple-nodes {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.profile-card-group {
  width: 232px;
  height: 104px;
  border: 1px solid #00000014;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 8px;
  cursor: pointer;
}

.profile-card-group .img {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-card {
  /* min-width: 195px; */
  width: 16rem;
  background-color: #ffff;
  min-height: 90px;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 8px;
  position: relative;
  border: 1px solid #0044c6;
  z-index: 999;
}

.level-2,
.level-3,
.level-4 {
  /* margin-top: 50px; */
  display: flex;
  justify-content: space-around;
}

.level-2 .profile-card,
.level-3 .profile-card,
.level-4 .profile-card {
  margin: 0 10px;
}

.profile-picture-image-group {
  width: 40px;
  height: 40px;
}

.sticky-profile-tabs {
  position: sticky;
  top: 102px;
  background-color: white;
  z-index: 399;
}

.leftside_image_groupTree .img {
  width: 80px;
  height: 80px;
}

.profile-card .leftside_image_groupTree .img {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ecf3ff;
}

.plus_adding_memb {
  position: absolute;
  top: 10px;
  right: 10px;
}

.group_member_add {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(217, 217, 217, 1);
  position: absolute;
  right: -15px;
}

.Share_button_Design {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  /* width: 213px; */
  height: 42px;
}

.Share_button_Design.Checked {
  background-color: #0044c6;
  color: #fff;
}

.group_card_ShareMethods {
  width: 32px;
  height: 32px;
  background-color: #0000008a;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 12px;
}

.Share_button_Design.Checked .group_card_ShareMethods,
.Share_button_Design.Checked .group_permissiions_options {
  background-color: unset;
  color: #fff;
}

.profile-qr-link-tag {
  display: block;
  width: fit-content;
  text-decoration: none;
}

.hovering_color_change {
  color: #0000008a;
}

.hovering_color_change:hover,
.group_permissiions_options.hover:hover,
.icon-color.hover:hover {
  color: #0044c6 !important;
}

.group_card_adding_memb {
  height: 72px;
  border-top: 1px solid rgba(0, 0, 0, 0.18);
  /* border-left: 0px;
  border-right: 0px; */
}

.profile_name_details {
  height: 40px;
}

.invite-modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-text);
}

.invite-modal-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-text);
}

.email-list-container.MuiBox-root {
  height: fit-content;
  min-height: 56px;
  /* padding: 16.5px 14px; */
  display: inline-flex;
  gap: 6px;
  flex-wrap: wrap;
  /* border: 1px solid #0044C6; */
  padding: 4px;
  border-radius: 4px;
  width: 100%;
}

.profile-card-group:hover,
.profile-card-group.Sidebar {
  border: 1px solid #0044c6;
}

.group-details-sidebar {
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: #fff;
  box-shadow: 0px 12px 24px 0px #919eab1f, 0px 0px 2px 0px #919eab33;
  border-radius: 8px;
  width: 276px;
  z-index: 1000;
}

.capitalize-first-letter {
  text-transform: capitalize;
  /* Capitalizes the first letter of each word */
}

.group_card_details {
  color: #00000099;
  font-size: 18px;
}

.icon-textGap-6px {
  /* margin-left: 6px; */
  gap: 6px;
}

.MuiButton-root.hover-red-bg {
  color: #eb4e3d;
}

.MuiButton-root.hover-red-bg:hover {
  background-color: #eb4e3d20;
}

.flip-card {
  perspective: 1000px;
  width: 200px;
  height: 300px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-card.flipped {
  transform: rotateY(180deg);
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  margin-top: 60px;
}

.flip-card-back {
  transform: rotateY(180deg);
}

/* group-card-Try */
.tree-node {
  position: relative;
}

/* Style the vertical line connecting Group Details to Father */
.tree-node.level-1::after {
  content: "";
  position: absolute;
  top: 100%; /* Start after the group details */
  left: 50%;
  width: 2px;
  height: 50px; /* Adjust the height depending on your layout */
  background-color: #ccc;
  border-left: 2px dashed #ccc;
}

/* Style the horizontal line connecting Father to Mother */
/* .parent-nodes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
} */

/* Style for the horizontal line between father and mother */
.horizontal-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #ccc;
}

/* Style for the vertical line between group details and father */
.tree-node.level-1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 2px;
  height: 50px;
  background-color: #ccc;
  border-left: 2px dashed #ccc;
}

.tree-node.level-1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 2px;
  height: 40px; /* Adjust height to control vertical line length */
  background-color: #ccc;
  border-left: 2px dashed #ccc;
}

.parent-nodes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 300px; /* Adjust as needed to control width between parents */
  /* margin: 20px auto; */
}

.horizontal-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px; /* Adjust width between father and mother */
  height: 2px;
  background-color: #ccc;
  transform: translateX(-50%);
}

.chips-display {
  display: inline-flex;
  gap: 12px;
}

.list-display-title {
  margin-right: 6px;
  min-width: fit-content;
}

.virtual-main-box {
  display: flex;
  justify-content: space-between;
  height: 80vh;
    overflow-y: hidden;
}

.virtual-box {
  display: flex;
  flex-direction: column;
  height: 78vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width:thin;
  /* padding-right: 20px;
  margin-bottom: 20px; */
}

.virtual-box-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;}

/* .virtual-image-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
} */
/* 
.virtual-uploaded-image-wrapper {
  position: relative;
  display: inline-block;
  align-content: left;
  margin-right: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.virtual-uploaded-image {
  width: 500px;
  border-radius: 10px;
  border: 5px solid #3f51b5;
} */

.virtual-text-name {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: x-small;
  font-weight: bold;
  /* padding: 3px; */
  border-radius: 5px;
}

.virtual-text-place {
  position: absolute;
  top: 35px;
  left: 10px;
  color: white;
  font-size: x-small;
  font-weight: bold;
  text-align: left;
  border-radius: 5px;
}

.virtual-text-profession {
  position: absolute;
  top: 25px;
  left: 10px;
  color: white;
  font-size: 8px;
  font-weight: bold;
  /* padding: 3px; */
  border-radius: 5px;
}

.virtual-place-text {
  top: 40px;
}

.virtual-profession-text {
  top: 70px;
}

.virtual-qrcode {
  width: 90px;
  height: 90px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
}

.virtual-download-button {
  margin-top: 2rem;
}

.virtual-description {
  margin-top: 5px;
  color: black;
  font-size: 16px;
}

.virtual-canvas {
  display: none;
}

.virtual-title {
  width: 100%;
  text-align: left;
  margin-bottom: 1px;
  color: black;
}

.virtual-background-grid {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;
}

.virtual-background-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  /* border: 1px solid #3f51b5; */
}

.virtual-select-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.virtual-Customize-Background {
  margin-top: 30px;
  width: "100%";
  text-align: left;
  margin-bottom: 2px;
  margin-top: 10px;
  color: black;
  font-size: 12px;
}

.virtual-select-checkBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  text-align: left;
  margin-top: 10px;

}

.white-svg path {
    fill: white;
    fill-opacity: 1;
    transition: all 0.3s ease;
}

.white-icon-hover.MuiIconButton-root:hover {
  background-color: rgb(255, 255, 255, 0.40);
}

.controlled-width-dialog .MuiPaper-root{
  max-width: unset;
}

.bold-blue-box {
  color: var(--primary-color);
  font-weight: 600;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid var(--primary-color);
  background-color: white;
  transition: all 0.4s ease;
  display: inline-block;

  &:hover {
    color: white;
    background-color: var(--primary-color);
  }
}

.bold-blue-box a {
  color: unset;
}

.ai-rewrite-button.MuiButton-root {
  color: #00E9E7;
  border-color: #00E9E780;

  &:hover {
    border-color: #00E9E7;
  }
}

.ai-rewrite-button .MuiButton-startIcon {
  margin-bottom: 8px;
}

.custom-dialog-font .MuiDialogTitle-root {
  font-family: 'Lato', sans-serif;
}

.error-text {
  color: #f93c65;
  font-size: 12px;
}

.list-txt{
  /* width: 140px; */
    line-height: 1.2em; /* Adjusts spacing between lines */
    word-wrap: break-word; /* Allows words to break to fit the narrow width */
    white-space: normal; /* Allows text wrapping */
    overflow: hidden; /* Hides extra text */
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limits to 3 lines */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.three-rows-text{
  /* width: px; */
   font-size: 14px;
  font-weight: 600;
    line-height: 1.2em; /* Adjusts spacing between lines */
    word-wrap: break-word; /* Allows words to break to fit the narrow width */
    white-space: normal; /* Allows text wrapping */
    overflow: hidden; /* Hides extra text */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits to 3 lines */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.Main-Container-kyc{
  align-items: flex-start;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  
 
  
}

.uploadContainer-card{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;

}

.imagePreviewContainer {
  display: flex;
  flex-direction: column;
   margin-top: -2%; 
  /* padding-right: 8%;  */
  border: 1px dashed gray;
  border-radius: 8px;
 padding: 10px;
 
}

.imagePreviewContainer-pan2 {
  display: flex;
  flex-direction: column;
   margin-top: -2%; 
  /* padding-right: 8%;  */
  border: 1px dashed gray;
  border-radius: 8px;
 padding: 40px;
 height: auto;
 
}


.imagePreviewContainer-pan {
  display: flex;
  flex-direction: column;
   margin-top: -2%; 
  border: 1px dashed gray;
  width: 30%;
  border-radius: 8px;
   padding: 15px;
}

.uploadZone {
  width: 300px;
  padding: 20px;
  border: 2px dashed #aaa;
  text-align: center;
  margin-bottom: 20px;
}

.uploadLabel {
  cursor: pointer;
  display: block;
  font-size: 16px;
}

.text-primary {
  color: #007bff;
}

.btncolor {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
}

.dialogContainer {
  text-align: center;
  padding: 20px;
}

.mytable {
  padding: 5px 10px;
  border: 1px solid #ddd;
}

.private-id{
  display: inline;
  margin: 10px;
  font-weight: 600;
}

.close-btn-id{
  position: absolute;
  right: 20px;
  top: 10px;
}

.private-id-image-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 280px;
    padding-left: 40px;
    height: 200px;
    justify-content: space-evenly;
    border-radius: 8px;
    box-shadow: 4px 4px 10px rgba(217, 217, 217, 1);
}

.private-verify-icons{

   background-color: white !important;
  margin: 90px; /* Use percentage for margin */
  width: 8vw; /* Relative to viewport width */
  height: 15vh; /* Allow height to adjust */
  justify-content: space-around;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* box-shadow */
    box-shadow: 4px 4px 10px rgba(217, 217, 217, 1);
  border:1px solid rgba(217, 217, 217, 1) !important;
}

.profile-consent{
  width: "40vw";
   height: "130px";
    padding: '16px';
    align-items: 'flex-start';
   gap: '8px';
}
.profile-profess-personl-consent{
width: "500px";
height: "50px";
padding: '16px';
align-Items: 'flex-start';
gap: '8px';
                       
}
.vitual-btn{
  background-color: var(--primary-color);
}


.small-card {
  background: white;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #00000014;
  color: #00000099;
 cursor: pointer;
  transition: box-shadow 0.3s, transform 0.2s;
}

.small-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  border-color: #0044c6;
}

.service-card {
  background: white;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #00000014;
  color: #00000099;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.2s;
}

.service-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  border: 1px solid #0044c6;
}

.card-title{
  color: #0044c6;
}

.fs-12px.MuiListItemText-root {
  font-size: 10px;
}
